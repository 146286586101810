import React from "react";
import { Modal, Badge, Select } from "antd";
import { useDispatch, connect } from "react-redux";
import { setBranchAddress } from "../../../../redux/actions/branch";
import "./index.scss";


const Option = Select.Option;
export const BranchModal = ({ 
    open, 
    onCancel, 
    setSelectedBranchId, 
    setIsBranchSelected, 
    branches, 
    businessName,
    setSelectedBranchDetails,
    merchant,
    selectedBranchId 
}) => {
    const dispatch = useDispatch();
    return (
        <>
            <Modal
                open={open}
                onCancel={onCancel}
                title={`${businessName} Outlets`}
                destroyOnClose
                footer={null}
            >
                <h3 style={{color: "#000"}}>Select Preferred Outlet</h3>
                <div className="branch-wrapper">
                    <Select
                        onChange={(value) => {
                            const branch = branches.find(branch => branch.id == value);

                            // console.log("branch", branch);
                            setSelectedBranchDetails(branch);
                            setSelectedBranchId(value);
                            setIsBranchSelected(true);
                            const branchpayload = {
                                isbranchSet: true,
                                address_line1: branch.address_line1,
                                address_line2: branch.address_line2,
                                branch_id: branch.id,
                                branch_name: branch.name,
                                longitude: branch.longitude,
                                latitude: branch.latitude
                            }
                                    
                            localStorage.setItem("branchdetails", JSON.stringify(branchpayload));
                            dispatch(setBranchAddress(branchpayload));
                            onCancel();
                        }}
                        style={{width: "100%"}}
                        placeholder="Select Preferred Outlet"
                        value={selectedBranchId}
                    >
                        {branches && branches.length > 0 && branches.map((branch, index)=> {
                        return (
                            <Option 
                                key={branch.id}
                                value={branch.id}
                            >{branch.name.toLowerCase().includes("home") ? "HQ" : branch.name}-{branch.address_line2 ? branch.address_line2 : branch.address_line1}</Option>
                        )
                        })}
                    </Select>
                    
                </div>
            </Modal>
        </>
    )
}

{/* <Badge
    key={branch.id}
    className="branch"
    onClick={()=>{
        setSelectedBranchDetails(branch);
        setSelectedBranchId(branch.id);
        setIsBranchSelected(true);
        const branchpayload = {
            isbranchSet: true,
            address_line1: branch.address_line1,
            address_line2: branch.address_line2,
            branch_id: branch.id,
            branch_name: branch.name
        }
        
        localStorage.setItem("branchdetails", JSON.stringify(branchpayload));
        dispatch(setBranchAddress(branchpayload));
        onCancel();
    }}
>
    <strong>{branch.name.toLowerCase().includes("home") ? businessName : branch.name}-{branch.address_line2 ? branch.address_line2 : branch.address_line1}</strong>
    
</Badge> */}