import React from "react";
import { Button } from "antd";

function Step3({ bookingDetails, onConfirm }) {
  const { services, date, time } = bookingDetails;

  const handleConfirm = () => {
    onConfirm();
  };

  console.log(services);

  return (
    <div>
      <h2>Step 3: Confirmation and Aftercare</h2>
      <div>
        <h3>Selected Service(s):</h3>
        <div
          className=""
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridGap: "10px",
            width: "100%",
            marginBottom: "10px",
          }}
        >
          {/* {services.map((service, index) => (
            <div
              style={{
                border: "1px solid #fff",
                marginBottom: 6,
                padding: 6,
                borderRadius: 6,
              }}
              key={index}
            >
              <h4
                style={{
                  marginBottom: "0px",
                }}
                key={index}
              >
                {service.name}
              </h4>
              <p
                style={{
                  color: "black",
                  fontSize: "14px",
                  margin: "0px",
                }}
              >
                {service.description}
              </p>
            </div>
          ))} */}
        </div>
      </div>
      <h3>Selected Date and Time:</h3>
      <p>
        Date: {date ? date.format("DD-MM-YYYY") : "Not selected"}
        <br />
        Time: {time ? time.format("HH:mm A") : "Not selected"}
      </p>

      <p>Thank you for booking with us!</p>
      <p>
        Please arrive on time for your appointment and follow any preparation
        instructions provided for your chosen service.
      </p>
      <Button 
      type="primary" 
      // onClick={handleConfirm}
      >
        Confirm Booking
      </Button>
    </div>
  );
}

export default Step3;
