import React, { useEffect, useState } from "react";
import { LogoWhite } from "../../../assets/icons";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { getCountryCode } from "../../../redux/actions/general";
import { LoadingOutlined } from "@ant-design/icons";

function ProgressBar({ setShowProgressBar }) {
	const [progressWidth, setProgressWidth] = useState(0);
	const [redirect, setRedirect] = useState(null);
	const [ loading, setLoading ] = useState(false);

	useEffect(() => {
		const updateProgress = () =>
			progressWidth < 100 && setProgressWidth((prev) => prev + 1);
		const intervalId = setInterval(() => {
			updateProgress();
		}, 30);
		return () => clearInterval(intervalId);
	});

	const isUrlOrigin = window.location.href.includes("thecualashop.com");
	// const isUrlOrigin = window.location.href.includes("http://localhost:3002");

	useEffect(() => {
		progressWidth === 100 && setShowProgressBar && setShowProgressBar(false);
		console.log("redirecting to cuala", isUrlOrigin, window.location.href);
		if(progressWidth === 99 && isUrlOrigin) {
			handleGetCurrentLocation();
		}
		if(progressWidth === 99 && !isUrlOrigin){
			setRedirect("/home");
		}
		// progressWidth === 99 && setRedirect("/home");
	}, [progressWidth, setShowProgressBar]);

	const handleGetCurrentLocation = () => {
		setLoading(true);
		getCountryCode()
		.then(res => {
		  if(res.status === 200){
			setLoading(false);
			const country = res.data;
			// setcurrentArea(country);
			if(isUrlOrigin && country.country_name === "Nigeria"){
				return window.location = "https://thecualashop.com/cuala.ng";
			}
			
			if(isUrlOrigin && country.country_name === "United Kingdom"){
				return window.location = "https://thecualashop.com/cuala.uk";
			}
		  }
		})
		.catch(err => {
			setLoading(false);
		  console.log("err", err);
		})
	  }
	if (redirect !== null) return <Redirect push to={redirect} />;

	return (
		<HomeDiv>
			<div>
				<div className="flex_center">
					<img src={LogoWhite} alt="urewards logo" />
				</div>
				<div className="progress_bar">
					<span style={{ width: `${progressWidth}%` }}></span>
				</div>
				<div style={{display: "flex", justifyContent: "center", marginTop: 10}}>{loading && <LoadingOutlined spin size={60} style={{color:"#fff"}} />}</div>
			</div>
		</HomeDiv>
	);
}

export default ProgressBar;

const HomeDiv = styled.div`
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100vh;
	width: 100vw;
	z-index: 1000;
	background: #000;
	transition: all 1s;
	.progress_bar {
		margin-top: 40px;
		display: flex;
		width: 200px;
		background: #313131;
		span {
			background: #fff;
			height: 5px;
		}
	}
`;
