import * as Types from "../actions/types";

const savedUser = JSON.parse(localStorage.getItem("renda-x-user"));

let initState = {
	loading: false,
	user: savedUser,
};

export const authReducer = (state = initState, action) => {
	switch (action.type) {
		case Types.AUTH: {
			return {
				...state,
				loading: action.loading,
			};
		}
		case Types.UPDATE_USER: {
			return { ...state, user: action.payload };
		}
		default: {
			return {
				...state,
			};
		}
	}
};
