import { Button, Modal, Image } from 'antd'
import { Rectangle } from '../../../assets/icons';
import React from 'react';
import "../index.scss"

const DetailsModal = ({ service, open, onCancel, currency }) => {
    // console.log(service);
  return (
    <Modal
        open={open}
        onCancel={onCancel}
        title={`Service Details - ${service.service_name}`}
        className="detailsModal"
        footer={[
            <Button type='primary' onClick={onCancel}>
                OK
            </Button>
        ]}
    >
        <p><strong>Name: </strong>{service.service_name}</p>
        <p><strong>Amount: </strong>{`${currency}${service.amount_charged}`}</p>
        <p><strong>Description: </strong>{service.description}</p>
        <p><strong>Duration: </strong>{service.duration} mins</p>
        <p><strong>Service Type: </strong>{service.service_type}</p>
        <p><strong>Created at: </strong>{new Date(service.created_at).toLocaleString()}</p>
        <p>
            <strong>Image: </strong><br/>
            {service.image_url && <Image src={service.image_url} height={150} width={150}/>}
        </p>
    </Modal>
  )
}

export default DetailsModal;