import Axios from "axios";
import * as Types from "./types";

export const setBranchAddress = ( address ) => ( dispatch ) => {
    dispatch({ type: Types.SETBRANCH, payload: address})
}


export const getMerchantBranches = async () => {
    const merchant = JSON.parse(localStorage.getItem("merchant_details"));
    const response = await Axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/get_merchant_branches`,{ merchant_email: merchant.email }, {
        headers: {
            "Content-Type": "application/json",
        }
    });

    return Promise.resolve(response);
}