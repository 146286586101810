import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { notification, message as MessageComponent } from "antd";

const httpLink = createHttpLink({
	uri: `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/graphql`,
});
// ${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}
//	https://loystar-mtier-stage.herokuapp.com
// http://localhost:5040
//disable error message
let errorMessage = "Error connecting to the Server";

//translate(errorMessage).then(res => errorMessage = res);

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(({ message, locations, path }) => {
			// console.log(
			//   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
			// );
			// MessageComponent.error(message, 5);
		});
	}

	if (networkError) {
		console.log(`[Network error]: ${networkError}`);
		// notification.error({
		// 	message: errorMessage,
		// });
	}
});

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem("token");
	const access_token = localStorage.getItem("access-token");
	const client = localStorage.getItem("client");
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : "",
			access_token: access_token ? access_token : "",
			client: client ? client : "",
		},
	};
});

const client = new ApolloClient({
	link: errorLink.concat(authLink.concat(httpLink)),
	cache: new InMemoryCache({
		typePolicies: {
			CustomerDetail: {
				keyFields: ["customer_id"],
				fields: {
					tiers: {
						merge: (existing = [], incoming) => {
							return incoming;
						},
					},
				},
			},
			Query: {
				fields: {
					listUnenrolledMembers: {
						merge: (existing = [], incoming) => {
							// console.log({ existing, incoming });
							return incoming;
						},
					},
					listTieredCustomers: {
						merge: (existing = [], incoming) => {
							return incoming;
						},
					},
				},
			},
		},
	}),
	defaultOptions: {
		query: {
			errorPolicy: "all",
		},
		mutate: {
			errorPolicy: "all",
		},
	},
});

export default client;
