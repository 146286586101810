import React, { useEffect, useState } from "react";
import { Button, Link, Spin, message, Modal } from "antd";
import { saveInvoice } from "../../../redux/actions/ussdActions";
import { useDispatch, useSelector , connect} from "react-redux";
import QRCode from "react-qr-code";
import Deliverymodal from "./deliverymodal";





const PaymentCodeModal = ( {bank, invoiceId, open, onCancel, total}) => {

const { merchantDetails } = useSelector( state => state.merchant );
const [ deliveryModal, setDeliveryModal ] = useState(false)
  return (
    <>
    <Deliverymodal 
        open={deliveryModal}
        onCancel={()=>setDeliveryModal(false)}
        merchant={merchantDetails}
    />
    <Modal
        open={open}
        // open={true}
        onCancel={onCancel}
        footer={null}
        className="payment_code"
        title={`Pay ${merchantDetails?.currency + total} With USSD`}
    >
        <h4>Waiting For USSD Transfer - {merchantDetails?.currency + total}</h4>
        <Spin />
        <div>
            <img 
                src={bank.image_url}
                alt={bank.short}
            />
        </div>
        {/* <p>
            <QRCode
                size={150}
                value={`tel:*${bank.code}*000*380${invoiceId}#`}
            />
        </p> */}
        {/* <div className="checkbox_div">
            <Checkbox 
                // onChange={()=>setDeliveryModal(!deliveryModal)}
                onClick={()=>setDeliveryModal(!deliveryModal)}
            />
            <p>Request Delivery</p>
        </div> */}
        <div
            style={{
                textAlign: "center"
            }}
        >
        <Button
            onClick={()=>{
                navigator.clipboard.writeText(`*${bank.code}*000*380${invoiceId}#`);
            }}
            type="primary"
        >
            <a href= {`tel:*${bank.code}*000*380${invoiceId}#`}>
                {`Dial *${bank.code}*000*380${invoiceId}#`}
            </a>
        </Button>
        </div>
        {/* <p style={{ marginTop: "10px" }}>
            Code to Dial: {`*${bank.code}*000*380${invoiceId}#`}
        </p> */}
    </Modal>
    </>
  )
}


const mapStateToProps = (state) => ({
	state: state,
	carts: state.carts,
	merchantDetails: state.merchantDetails,
	orderdetails: state.orderdetails,
    urewardsAuth: state.urewardsAuth
});
export default connect(mapStateToProps)(PaymentCodeModal);