import React from 'react';
import { Modal, Form, Button, Input, DatePicker } from 'antd';
import moment from 'moment';




const Deliverymodal = ({ onCancel, open, merchant }) => {

    const [form] = Form.useForm();

    const handleFormsubmit = () =>{
        form.validateFields().then((value)=>{
            console.log(value);
        })
    }
    


  return (
    <Modal
        open={open}
        // open={true}
        onCancel={onCancel}
        className="delivery_modal"
        title="Request Delivery"
        footer={[
            <div key={Math.random()}>
                <Button>
                    Cancel
                </Button>
                <Button
                    type="primary"
                    onClick={handleFormsubmit}
                >
                    Request Delivery
                </Button>
            </div>
        ]}
    >
        <Form layout="vertical" form={form}>

        {/* <Form.Item
          label="Delivery Method"
          name="deliveryMethod"
          rules={[{ required: true, message: "Please select delivery method" }]}
        ></Form.Item> */}
        <Form.Item
            label="Pickup Address"
            name="pickupAddress"
            rules={[{ required: true, message: "Please select delivery method" }]}
            initialValue={merchant?.address_line1 + "," + merchant?.address_line2}
        >
            <Input 
                size="large"
                // defaultValue={merchant?.address_line1 + "," + merchant?.address_line2}
                
                // disabled={true}
            />
        </Form.Item>
        <Form.Item
            label="Delivery Address"
            name="deliveryAddress"
            rules={[{ required: true, message: "Please enter delivery address" }]}
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="Delivery Date"
            name="deliveryDate"
            initialValue={moment(new Date())}
            rules={[{ required: true, message: "Please select delivery date" }]}
        >
            <DatePicker 
                size="large"
                disabledDate={(date) => date < moment(new Date()).subtract(1)}
            />
        </Form.Item>
        <Form.Item
            label="Delivery Instruction"
            name="deliveryInstruction"
        >
            <Input.TextArea />

        </Form.Item>
        </Form>
    </Modal>
  )
}

export default Deliverymodal;