import React from 'react';
import { Modal, Badge } from "antd";
import { useDispatch, useSelector, connect } from "react-redux";
import { ToastContainer, toast} from "react-toastify";
import "./index.scss";
import { addToCart } from '../../../../redux/actions/orders';
import { useState } from 'react';
import { useEffect } from 'react';

const CustomQuantityModal = ({ 
    open, 
    onCancel, 
    product, 
    merchant 
}) => {

const dispatch = useDispatch();

const calculateLowestAndHighestPrice = (item) => {

    const sortedItems = item.sort((a, b)=> {
        return Number(a.price) - Number(b.price)
    });

    
    const lowestPrice = sortedItems[0];
    const highestPrice = sortedItems[sortedItems.length - 1];

    if(Number(lowestPrice.price) === Number(highestPrice.price)){
        return <span>{Number(highestPrice.price).toFixed(2)}</span> 
    }else{
        return <span>{Number(lowestPrice.price).toFixed(2)} - {Number(highestPrice.price).toFixed(2)}</span>
    }
}

    const addProductToCart = (product, custom_quantities) => {
        const itemC = localStorage.getItem("urewards-carts");
        const carts = itemC ? JSON.parse(itemC) : [];
        const totalProductCount = product.quantity;
        // newProdd.custom_quantities = [custom_quantities];
        // isSelected.custom_quantities = [custom_quantities];

        const qty = Number(custom_quantities.quantity);
    
        if(Number(custom_quantities.quantity) > Number(product.quantity) ){
            return toast.warning(`${product.name} quantity is not enough to purchase bundle`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    
        const basket = document.querySelector("#cartBasket");
        basket.classList.add("cart_animate");
        setTimeout(() => {
            basket.classList.remove("cart_animate")
        }, 700);
        
        const ifProductIsAdded = carts.filter((item)=>{
            return item.id === product.id;
        });
            
        if( ifProductIsAdded.length !== 0 ){
            const isSameItemCustomQtyPresent = ifProductIsAdded.filter((item)=>{
                return item.custom_quantities[0].id === custom_quantities.id;
            });
    
          if(isSameItemCustomQtyPresent.length !== 0){
            const quantity = Number(isSameItemCustomQtyPresent[0].quantity) + Number(custom_quantities.quantity);

            if(product.track_inventory){
              if(quantity > Number(product.quantity)){
                return toast.warning(`${product.name} quantity is not enough to purchase bundle`, {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                  });
              }
            }
            
            dispatch( addToCart( {product, quantity, totalProductCount, custom_quantities} ) );
            toast.success(`Successfully added ${custom_quantities ? custom_quantities.name : "Item"} to Cart`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
    
            onCancel();
        }else{
            const quantity = qty;
            dispatch( addToCart( {product, quantity, totalProductCount, custom_quantities} ) );
    
            toast.success(`Successfully added ${custom_quantities ? custom_quantities.name : "Item"} to Cart`, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
    
            onCancel();
          }
                
        }else{
            const quantity = qty;
            dispatch( addToCart( {product, quantity, totalProductCount, custom_quantities} ) );
            toast.success(`Successfully added ${custom_quantities ? custom_quantities.name : "Item"} to Cart`, {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            onCancel();
        }
    };
  return (
    <Modal
        open={open}
        onCancel={onCancel}
        footer={null}
        destroyOnClose
        title={[<>< span key={1}>{product && product.name}</span> <span key={2}>{product && product.custom_quantities &&  calculateLowestAndHighestPrice(product.custom_quantities)}</span></>]}
    >
        <div className="custquantity">
            <span>Select your choice:</span>
            <div className="custom-wrapper">
            {product && product.custom_quantities.map((quantity, index)=>{
                return <Badge
                        key={index}
                        onClick={() => {
                            addProductToCart(product, quantity);
                        }}
                        className="custom-quat"
                    >
                        <span style={{ paddingRight: "10px" }}>{quantity.name} {`${quantity.quantity} unit(s)`}</span>
                        <span style={{ paddingRight: "10px" }}>-</span>
                        <span>
                            {merchant.currency}
                            {quantity.price}
                        </span>
                    </Badge>
                {/* </div> */}
            })}
            </div>
        </div>
    </Modal>
  )
}

export default CustomQuantityModal;