import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import { StopOutlined } from "@ant-design/icons";

//Import Images
import { ErrorImg } from "./assets/icons";

const ErrorPage = () => {
	//meta title
	document.title = "404 Error Page";

	return (
		<React.Fragment>
			<div className="flex_center_column">
				<div>
					<Row>
						<Col>
							<div>
								<h1 className="title_text" style={{ textAlign: "center", margin: 40, fontSize: 52 }}>
									4<StopOutlined style={{margin: "0 5px"}} spin={true} />4
								</h1>
								<h4 className="title_text">Sorry, page not found</h4>
								<div className="flex_center">
									<Link to="/">Back to Home</Link>
								</div>
							</div>
						</Col>
					</Row>
					<Row className="flex_center">
						<Col md="8" xl="6">
							<div>
								<img
									src={ErrorImg}
									style={{ width: 400 }}
									alt=""
									className="img-fluid"
								/>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ErrorPage;
