import { LogoWhite, QrCode } from "../assets/icons";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, Tooltip } from "antd";
import { EnvironmentTwoTone } from "@ant-design/icons";
import { QRCode } from "react-qrcode-logo";
// import { useSelector } from "react-redux";
// import { AiOutlineShoppingCart } from "react-icons/ai";

const Header = ({
  openLoginModal,
  organisation,
  organise,
  merchantee,
  // titleComponent,
  merchantLink,
  inOrganise,
  setShowBranchSelect,
	showBranchSelect,
  merchantBranches
}) => {
  const [showProfile, setShowProfile] = useState(false);
  // const [cartItemNumber, setCartItemNumber] = useState(0);
  // const { carts, loading } = useSelector((state) => state.Order);
  const [merchantUrl, setMerchantUrl] = useState("");

  const storedMerchant = localStorage.getItem("merchant_details");
  const merchant = storedMerchant ? JSON.parse(storedMerchant) : "";
  const storageUser = localStorage.getItem("user_customer_details");
  let user = null;
		try {
		  if (storageUser && typeof storageUser === "string") {
		    user = JSON.parse(storageUser);
		  }
		} catch (err) {
		  console.error("Error parsing user from storage:", err);
		}



  const QR_CODE_URL = `${process.env.REACT_APP_LOYSTAR_BASE_URL}/me/${user?.phone_number}`;

  useEffect(() => {
    setMerchantUrl(JSON.parse(localStorage.getItem("urewards_merchant_url")));
    // console.log(merchantUrl);
  }, [merchantUrl]);

  const logoutUser = () => {
    localStorage.clear();
    window.location.href = merchantUrl;
  };

  // useEffect(()=>{
  // console.log(carts.length);
  // }, [carts])
  // const setCartNumber = () =>{
  // const numOfCartItems = JSON.parse(localStorage.getItem("cartie"));
  // console.log("num", numOfCartItems.length);

  // numOfCartItems && setCartItemNumber(numOfCartItems.length)

  // }

  // useEffect(()=>{
  // 	setCartNumber();
  // }, [cartItemNumber])
  return (
    <>
      {showProfile && (
        <Modal
          open={showProfile}
          onCancel={() => setShowProfile(false)}
          className="profile_modal"
          footer={null}
        >
          <div className="sign_up_content">
            {/* <div style={{width: "110%",display: "flex", justifyContent: "space-between", alignItems: "center"}}>
							<h3
								className="title_text"
								style={{ textAlign: "center", margin: 0 }}>
								{user?.first_name}
							</h3>
							<div style={{marginLeft: "-7%"}}><Avatar src={merchant?.image || LogoWhite} size="large" /></div>
							<span>
					
								<img src={QrCode} alt=""/>
							</span>
						</div> */}

            <div style={{ marginTop: 10 }}></div>

            <div className="flex_center_column">
              <QRCode
                size={250}
                quietZone={50}
                value={QR_CODE_URL}
                bgColor="#000"
                fgColor="#fff"
                qrStyle="dots"
                ecLevel="H"
                eyeRadius={[
                  {
                    outer: [10, 10, 0, 10],
                    inner: [0, 10, 10, 10],
                  },
                  [10, 10, 10, 0],
                  [10, 0, 10, 10],
                ]}
              />
              <div>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "24px",
                    textAlign: "center",
                    padding: "0 5%",
                    fontWeight: "600",
                  }}
                >
                  Show your barcode at the counter to collect points
                </p>
              </div>
              {/* <div style={{ height: 40 }}></div> */}
            </div>
            {/* 
						{!user.address && (
							<div className="flex_center_column">
								<p>Phone Number</p>
								<p>{user?.phone_number}</p>
							</div>
						)} */}

            {user.address && (
              <div className="contact_address">
                <div className="contact">
                  <p>Phone Number</p>
                  <p>{user?.phone_number}</p>
                </div>
                <div className="address">
                  <p>Address</p>
                  <p>{user?.address || "Nil"}</p>
                </div>
              </div>
            )}

            {/* <div style={{ marginTop: 30 }}>
							<p>
								<b>Complete this Task and Earn some Points</b>
							</p>
							<p style={{ color: "#6B6A6A" }}>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.sed do
								eiusmod tempor
							</p>
						</div> */}

            {/* <div style={{ marginTop: 30 }}>
							<Button
								style={{
									background: "#6B6A6A",
									width: "100%",
									height: "63px",
									marginBottom: 20,
								}}
								type="primary">
								<span>Update Your Contact</span>
							</Button>
							<Button
								style={{
									background: "#6B6A6A",
									width: "100%",
									height: "63px",
									marginBottom: 20,
								}}
								type="primary">
								<span>3 Daily Purchases</span>
							</Button>
							<Button
								style={{
									background: "#6B6A6A",
									width: "100%",
									height: "63px",
									marginBottom: 20,
								}}
								type="primary">
								<span>Visit 3 Stores</span>
							</Button>
						</div> */}

            <div className="logout">
              <Button
                style={{
                  background: "rgb(107, 106, 106)",
                  width: "100%",
                  height: "63px",
                  margin: 0,
                  border: "none",
                }}
                onClick={logoutUser}
                type="primary"
              >
                <span>Logout</span>
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <div
        style={{
          ...styles.container,
          backgroundColor: inOrganise || "#131313",
        }}
      >
        <div style={styles.dateCont}>
          {user ? (
            <h2 className="title_text" style={{ margin: 0 }}>
              👋🏽{user?.first_name ? user?.first_name : "New User"}
            </h2>
          ) : (
            <h2>👋🏽</h2>
          )}
        </div>
        <div style={{ cursor: "pointer" }}>
          <Link to={merchantUrl || window.location}>
            {organise && (
              <img
                src={
                  organisation?.organisation_logo
                    ? organisation.organisation_logo
                    : LogoWhite
                }
                style={styles.img}
                alt="merchant logo"
              />
            )}
            {merchantee && (
              <img
                src={merchant?.image ? merchant?.image : LogoWhite}
                style={styles.img}
                alt="merchant logo"
              />
            )}
          </Link>
        </div>
        <div style={styles.flexSpaceBtw}>
          {merchantLink !== undefined && merchantBranches.length > 1 && <div>
            <Tooltip
              title="Click to Select the branch you want to purchase from"
            >
              <EnvironmentTwoTone 
                size="large"
                className="branch-icon"
                color="#fff"
                onClick={()=>setShowBranchSelect(!showBranchSelect)}
                twoToneColor="#fff"
              />
            </Tooltip>
          </div>}
          <div
            onClick={() => (user ? setShowProfile(true) : openLoginModal())}
            style={{ cursor: "pointer" }}
          >
            <img src={QrCode} alt="user avatar" />
          </div>
        </div>
      </div>
      <div style={{ height: 100 }}></div>
    </>
  );
};

export default Header;

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: "0 0 10px",
    padding: "40px 20px 10px",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 2,
  },
  dateCont: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
  },
  flexSpaceBtw: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "15px",
    alignItems: "center",
    width: "40%",
  },
  img: {
    width: "50px",
    height: "50px",
    marginTop: "-30px",
  },
  cart: {
    display: "flex",
    alignItems: "center",
  },
};
