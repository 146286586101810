import * as Types from "../actions/types";


const initialState = {
    productLoading: false,
    productSuccess: false,
    productsProduct: []
}
export const productReducer = ( state=initialState, {type, payload} ) =>{
    switch (type){
        case Types.PRODUCT_LOADING: {
            return {
                ...state,
                productLoading: true,
                productSuccess: false
            }
        }
        case Types.PRODUCT_SUCCESS: {
            return {
                ...state,
                productLoading: false,
                productSuccess: true,
                productsProduct: payload
            }
        }
        default: {
            return state;
        }
    }

}