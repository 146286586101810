import React from 'react';
import { Modal, Button, Input } from 'antd';

const VariantFilter = ({ 
    open, 
    onCancel, 
    value, 
    onChange, 
    onClick,
    resetFilter
}) => {

  return (
    <Modal
        open={open}
        onCancel={onCancel}
        footer={[
            <>
            <Button
                type="default"
                onClick={resetFilter}
            >
                Reset
            </Button>
            <Button
            type="primary"
            onClick={onClick}
            >
                Apply
            </Button>
        </>
        ]}
        className="gps_modal"
        title="Variant Filter"
    >
        <h4 style={{color: "#000"}}>Enter Variant value</h4>

        <div>
            <Input 
                value={value}
                onChange={onChange}
            />
        </div>
    </Modal>
  )
}

export default VariantFilter;