import React, { useEffect, useState } from 'react';
import { Modal, Card, Button, Table, message } from "antd";
import { Link, Redirect, useHistory } from "react-router-dom";
import { ADD_PARTNERS_CUST } from '../../../constants';
import { useLazyQuery } from '@apollo/client';
import { SIGNUP_USER } from '../../../constants';
import { reSignInLoading } from '../../../redux/actions/orders';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
const MerchantModal = ({ open, onCancel, category, merchants, loading}) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [ userDetails, setUserDetails ] = useState({});
    const [addCustToMerchant, { data: addData, loading: addLoad, error, refetch}] = useLazyQuery(ADD_PARTNERS_CUST) 
    useEffect(()=>{
        const checkUser = localStorage.getItem("user_customer_details");
        console.log("checkUser", checkUser);
        if(checkUser){
            setUserDetails(JSON.parse(checkUser));
        }
    }, [])
    const getUrewardsId = (merchant) => {
		let string = "";
		for (let letter of merchant.contact_number) {
			string = isNaN(Number(letter)) ? string : (string += letter);
		}
		// dispatch( getMerchantByDispatch(merchant) )
		return string;
	};

    const [signUpUser] = useMutation(SIGNUP_USER, {
		onCompleted: (data) => {
            console.log(data);
            localStorage.setItem("user_customer_details", JSON.stringify(data?.signUpUser));
			// dispatch(reSignInSuccess(merchantDetails?.merchant_details.id));	
		},
		onError: (error) => {
			message.error(error.message, 5);
		},
	});
    const handleRowClick = (record) => {
        const checkUser = localStorage.getItem("user_customer_details");
        const exist = checkUser && JSON.parse(checkUser);
        const newUserDetails = {
            first_name: exist.first_name,
            phone_number: exist.phone_number,
            // email: userDetails.email,
            sex: "-" ,
            merchant_rewards_id: getUrewardsId(record),
            isFromOrg: true
        }
        try {
            signUpUser({
                variables: {
                    data: {
                        ...newUserDetails,
                        merchant_rewards_id: `${getUrewardsId(record)}` || "",
                        sex: "-",
                    },
                },
            });
        } catch (error) {
            message.error(error.message);
        }
        // console.log(record)
        history.push({
            pathname: `/${getUrewardsId(record)}`,
            state: {
                merchant_id: record.id
            },
        })
    }
    

    const columns = [
		{
			title: <b>Merchants</b>,
			key: "business_name",
			render: (data, record) => (
				<Card
					hoverable
					key={record.id}
					bordered={false}
					style={{ padding: 0, margin: "10px 0" }}
					onClick={()=>handleRowClick(record)}
					>
					{record.business_name}
				</Card>
			)

		}
	]

  return (
    (
        <Modal
            open={open}
            title={category}
            className="login_modal"
            bodyStyle={{ padding: 0 }}
            onCancel={onCancel}
            footer={[
                <Button type="primary" onClick={onCancel}>
                    Cancel
                </Button>,
            ]}>
            <Card bordered={false} loading={loading}>
                <Table dataSource={merchants} columns={columns} bordered={false} style={{padding: 0}} />
                {merchants.length === 0 && (
                    <div>
                        <p style={{ color: "#000", textAlign: "center" }}>
                            <b>Sorry, no merchants in this category yet</b>
                        </p>
                    </div>
                )}
            </Card>
        </Modal>
    )
  )
}

export default MerchantModal