import React, { useState, useEffect } from 'react'
import { Modal, Spin } from "antd";
import bankCodes from "../../../banks.json";
import { useSelector, useDispatch, connect } from 'react-redux';
import { saveInvoice } from '../../../redux/actions/ussdActions';
import PaymentCodemodal from '../../cart/modal/paymentCodemodal';
import Pusher from "pusher-js";


const UssdService = ({ 
    open, 
    onCancel, 
    merchant, 
    total 
}) => {
    const dispatch = useDispatch();
    const [ displayCodes, setDisplayCodes ] = useState(false);
    const [ selectedBank, setSelectedBank ] = useState({});
    const [ codeLoading, setCodeLoading ] = useState(false);
    const [ openPayModal, setOpenPayModal ] = useState(false);
    const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v2`;
    const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
    const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;
    const authUrl = `${BASE_URL}/pusher/auth`;
    const storageUser = localStorage.getItem("user_customer_details");
    const user = storageUser ? JSON.parse(storageUser) : null;
    const  { 
        merchantloyaltySuccess, 
        invoice, 
        invoiceSuccess, 
        ussdOrderSuccess, 
        giftcard,
        uRewardsAuth,
        status
    }  = useSelector( state => state.urewardsAuth );

    const toastConfig = {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    };


    useEffect(() => {
        const merchants = JSON.parse(localStorage.getItem("merchant_details"));
          const pusher = new Pusher(PUSHER_KEY, {
            cluster: PUSHER_CLUSTER,
            authEndpoint: authUrl,
            auth: {
              params: {
                uid: uRewardsAuth?.uid,
                access_token: uRewardsAuth?.access_token,
                client: uRewardsAuth?.client,
              },
            },
          });
    
          const channel = pusher.subscribe(
            `private-ussd_payment_${merchants?.id}`
          );
    
          channel.bind("new_payment_made", function (data) {
            const {
              status,
              ref_code,
              invoice: { status: invoiceStatus, paid_at, invoice_id, amount },
              customer: { first_name, last_name, phone_number, email },
            } = data;
    
            if (status === "success") {
                Modal.success({
                    title: `Successfully paid ${
                        merchants?.currency + total.toFixed(2)
                    } via USSD!`,
                    onOk: () => {
                        setCodeLoading(false);
                        setOpenPayModal(false);   
                        onCancel();
                    }
                })
            }
          });
    
          return () => {
            pusher.unsubscribe(
              `private-ussd_payment_${merchants?.id}`
            );
          };
        
    }, []);

    const handleclick = (code) =>{
        const list = JSON.parse(localStorage.getItem("serviceItems"));
        const merchant = JSON.parse(localStorage.getItem("urewards_merchant_details"));
        const branchdetails = JSON.parse(localStorage.getItem("branchdetails"));

        const newList = [];

        list.map((lis)=>{
            let newss = {
                amount: lis.amount_charged,
                branch_id: branchdetails.id,
                description: lis.description,
                id: null,
                merchant_id: merchant.id,
                merchant_loyalty_program_id: lis.loyalty_id,
                merchant_product_category_id: null,
                name: lis.name,
                picture: lis.image_url,
                price: lis.amount_charged.toString(),
                product_type: "service",
                quantity: lis.quantity,
                service_id: lis.id,
                service_name: lis.service_name,
                tax: null,
                tax_rate: null,
                tax_type: null,
                total_quantity: lis.quantity,
                track_inventory: false,
                variants: []
            }
            newList.push(newss);
        })

        const data = {
            user_id: user?.user_id,
            customer_id: user?.id,
            due_amount: total.toString(),
            // business_branch_id: "",
            cartList: newList,
            payment_method: "ussd",
            status: "unpaid",
            payment_message: `Dial the USSD code: *${code}*000*380*`,
            due_date: new Date(),
            send_notification: false,
            // has_discount: hasDiscount,
            // discount_amount: discountAmount,
        };

        dispatch( saveInvoice(data) );

    }
    
  return (
    <>
        <Modal
            open={open}
            onCancel={onCancel}
            title={`Pay ${merchant.currency + total} With USSD`}
            className='ussdservice'
        >
           {bankCodes.map((bank, index)=>{
            return <div 
                className="bank_codes" 
                key={index}
                onClick={()=>{
                    // console.log(codeLoading, merchantloyaltySuccess);
                    if(!codeLoading && merchantloyaltySuccess){
                        handleclick(bank.code);
                        setSelectedBank(bank);
                        setCodeLoading(true);
                        setOpenPayModal(true);
                            // invoiceSuccess && onCancel();
                        
                    }
                }}
            >
                <h4>{bank.name}</h4>
                <img 
                    src={bank.image_url} 
                    alt={bank.short}
                />
                <div className="ussd_modal">
                    {selectedBank.name === bank.name && (
                        codeLoading && <Spin />)}
                    <p>*{bank.code}*</p>
                </div>
            </div>})}
        </Modal>

       {openPayModal && invoiceSuccess && <PaymentCodemodal 
            open={openPayModal}
            onCancel={()=>{
                // setSelectedStatus(false);
                setCodeLoading(false);
                setOpenPayModal(false);
            }}
            bank={selectedBank}
            invoiceId={invoice?.invoice_id}
            total={total}
        />}
    </>
  )
}

const mapStateToProps = (state) => ({
	state: state,
	carts: state.carts,
	merchantDetails: state.merchantDetails,
	orderdetails: state.orderdetails,
    urewardsAuth: state.urewardsAuth
});
export default connect(mapStateToProps)(UssdService);