import React, { useState } from "react";
import Step1 from "./step1/Step1";
import Step2 from "./step2/Step2";
import Step3 from "./step3/Step3";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BookingSchema } from "./Booking.Schema";

const Booking = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);

  const servicesData = [
    {
      name: "Beauty",
      services: [
        {
          id: 1,
          name: "Nails",
          description: "Professional nail services",
          price: 100,
          time: "30 mins",
        },
        {
          id: 2,
          name: "Hair",
          description: "Professional hair services",
          price: 100,
          time: "45 mins",
        },
        {
          id: 3,
          name: "Makeup",
          description: "Professional makeup services",
          price: 100,
          time: "60 mins",
        },
        {
          id: 4,
          name: "Facial",
          description: "Professional facial services",
          price: 100,
          time: "45 mins",
        },
        {
          id: 5,
          name: "Massage",
          description: "Professional massage services",
          price: 100,
          time: "60 mins",
        },
      ],
    },
    {
      name: "Cleaning",
      services: [
        {
          id: 1,
          name: "Home Cleaning",
          description: "Professional home cleaning services",
          price: 100,
          time: "30 mins",
        },
        {
          id: 2,
          name: "Car Cleaning",
          description: "Professional car cleaning services",
          price: 100,
          time: "45 mins",
        },
        {
          id: 3,
          name: "Office Cleaning",
          description: "Professional office cleaning services",
          price: 100,
          time: "60 mins",
        },
        {
          id: 4,
          name: "Carpet Cleaning",
          description: "Professional carpet cleaning services",
          price: 100,
          time: "45 mins",
        },
        {
          id: 5,
          name: "Pool Cleaning",
          description: "Professional pool cleaning services",
          price: 100,
          time: "60 mins",
        },
      ],
    },
    {
      name: "Plumbing",
      services: [
        {
          id: 1,
          name: "Pipe Fitting",
          description: "Professional pipe fitting services",
          price: 100,
          time: "30 mins",
        },
        {
          id: 2,
          name: "Pipe Repair",
          description: "Professional pipe repair services",
          price: 100,
          time: "45 mins",
        },
        {
          id: 3,
          name: "Pipe Cleaning",
          description: "Professional pipe cleaning services",
          price: 100,
          time: "60 mins",
        },
        {
          id: 4,
          name: "Pipe Installation",
          description: "Professional pipe installation services",
          price: 100,
          time: "45 mins",
        },
        {
          id: 5,
          name: "Pipe Maintenance",
          description: "Professional pipe maintenance services",
          price: 100,
          time: "60 mins",
        },
      ],
    },
    {
      name: "Automobile",
      services: [
        {
          id: 1,
          name: "Car Repair",
          description: "Professional car repair services",
          price: 100,
          time: "30 mins",
        },
        {
          id: 2,
          name: "Car Maintenance",
          description: "Professional car maintenance services",
          price: 100,
          time: "45 mins",
        },
        {
          id: 3,
          name: "Car Cleaning",
          description: "Professional car cleaning services",
          price: 100,
          time: "60 mins",
        },
        {
          id: 4,
          name: "Car Painting",
          description: "Professional car painting services",
          price: 100,
          time: "45 mins",
        },
        {
          id: 5,
          name: "Car Washing",
          description: "Professional car washing services",
          price: 100,
          time: "60 mins",
        },
      ],
    },
    {
      name: "Electrical",
      services: [
        {
          id: 1,
          name: "Fan Repair",
          description: "Professional fan repair services",
          price: 100,
          time: "30 mins",
        },
        {
          id: 2,
          name: "Fan Installation",
          description: "Professional fan installation services",
          price: 100,
          time: "45 mins",
        },
        {
          id: 3,
          name: "Fan Maintenance",
          description: "Professional fan maintenance services",
          price: 100,
          time: "60 mins",
        },
        {
          id: 4,
          name: "Fan Cleaning",
          description: "Professional fan cleaning services",
          price: 100,
          time: "45 mins",
        },
        {
          id: 5,
          name: "Fan Painting",
          description: "Professional fan painting services",
          price: 100,
          time: "60 mins",
        },
      ],
    },
  ];

  const {
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    register,
    control,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(BookingSchema),
  });

  const onSubmit = async (data) => {
    console.log(data);
  };

  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedServiceDetails, setSelectedServiceDetails] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const bookingDetails = {
    service: selectedServices,
    date: selectedDate,
    time: selectedTime,
  };

  console.log(selectedServices);

  const showUI = () => {
    switch (step) {
      case 0:
        return (
          <Step1
            servicesData={servicesData}
            selectedServices={selectedServices}
            setSelectedServices={setSelectedServices}
            selectedServiceDetails={selectedServiceDetails}
            setSelectedServiceDetails={setSelectedServiceDetails}
            isValid={isValid}
            setStep={setStep}
          />
        );

      case 1:
        return (
          <Step2
            servicesData={servicesData}
            selectedServiceDetails={selectedServiceDetails}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            control={control}
            setStep={setStep}
          />
        );

      case 2:
        return <Step3 bookingDetails={bookingDetails} loading={loading} />;

      default:
        return <div>Something went wrong</div>;
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>{showUI()}</form>
    </div>
  );
};

export default Booking;
