import * as Types from "../actions/types";

const merchantIdArray = JSON.parse(localStorage.getItem("merchant_id_array"))
const initialState = {
    loading: false,
    success: false,
    merchantIdArray: merchantIdArray ? merchantIdArray : []
}

export const resignReducer = ( state= initialState, {type, payload} )=>{
    switch (type) {
        case Types.RESIGNIN_LOADING: {
            return {
                ...state,
                loading: true,
                success: false
            }
        }
        case Types.RESIGNIN_SUCCESS: {
            state.loading = false;
            state.success = true;
            state.merchantIdArray = [...state.merchantIdArray, payload];
            localStorage.setItem("merchant_id_array", JSON.stringify(state.merchantIdArray));
            return state;
        }
        default: {
            return state
        }
    }
}