import * as Types from "../actions/types";


const initialState = {
    success: false,
    loading: false,
    orderdetails: localStorage.getItem("ureward_orderDetails") ? JSON.parse(localStorage.getItem("ureward_orderDetails")) : null,
    order_id : localStorage.getItem("urewards_orderid") ? JSON.parse(localStorage.getItem("urewards_orderid")) : 1,
    error: null,
}

export const orderReducer = ( state = initialState, {type, payload}) =>{
    switch (type) {
        case Types.ORDER_PENDING: {
            state.loading = true;
            return state;
        }
        case Types.ORDER_SUCCESS: {
            state.loading = false;
            state.success = true;
            state.orderdetails = payload;
            state.error = null;
            return state;
        }
        case Types.ORDER_FAILURE: {
            state.loading = false;
            state.success = false;
            state.orderdetails = [];
            state.error = payload
            return state;
        }
        case Types.CREATE_ID:{
            state.order_id = payload;
            localStorage.setItem("urewards_orderid", JSON.stringify(state.order_id));
            return state;
        }
        default: {
			return {
				...state,
			};
		}
    }
}

// export const getOrderSelectors = ( state ) => state.Order;