import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Homepage from "./pages/homepage";
import Cart from "./pages/cart";
import Discover from "./pages/discover";
import CheckIn from "./pages/check_in";
// import CheckOut from "./pages/check_out";
import Profile from "./pages/profile";
import Rewards from "./pages/rewards";
import CustomerPage from "./pages/customer";
import Transactions from "./pages/transactions";
import Booking from "./pages/booking";
import ProgressBar from "./pages/homepage/partials/ProgressBar";
import MerchantsPage from "./pages/merchants";
import CredpalPage from "./pages/credpal";
import ErrorPage from "./Error404";
import ScrollReveal from "scrollreveal";
import LeaderBoard from "./pages/leaderboard"
import "./app.less";
import "./App.scss";
import { ApolloProvider } from "@apollo/client";
import client from "./client";
import LogoBlack from "./assets/icons/logo_black.svg";
import UrewardsLogoBlack from "./assets/icons/ureward_icon_white.png";
import Organisation from "./pages/organisation";
import { Provider, ErrorBoundary } from "@rollbar/react";
import BookingWidget from "./pages/booking-widget";
// import { HelmetProvider } from 'react-helmet-async';

function App() {
  useEffect(() => {
    ScrollReveal().reveal(".fade", fadeIn);
  });

  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLABAR_ACCESSTOKEN,
    environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
  };
  

  useEffect(() => {
    const savedMerchant = localStorage.getItem("merchant_details");
    const isMerchantUrl = localStorage.getItem("urewards_merchant_url");
    const merchant = savedMerchant ? JSON.parse(savedMerchant) : {};
    const merchantUrl = isMerchantUrl ? JSON.parse(isMerchantUrl) : ""
    const myDynamicManifest = {
      name: merchant?.business_name || "Loystar Shop",
      short_name: merchant.business_name || "Loystar",
      description:
        merchant.description ||
        (merchant.business_name || "Loystar") + " shop",
      start_url: `${process.env.REACT_APP_WEB_URL}${merchantUrl}`,
      background_color: "#fff",
      theme_color: "#000",
      display: "fullscreen",
      icons: [
        {
          src: merchant.image || UrewardsLogoBlack,
          sizes: "16x16",
          type: "image/x-icon",
        },
        {
          src: merchant.image || LogoBlack,
          sizes: "72x72",
          type: "image/png",
        },
        {
          src: merchant.image || LogoBlack,
          sizes: "144x144",
          type: "image/png",
        },
        {
          src: merchant.image || UrewardsLogoBlack,
          sizes: "512x512",
          type: "image/png",
        },
      ],
    };
    const link = document.getElementById("manifest");
    link.rel = "manifest";
    const stringManifest = JSON.stringify(myDynamicManifest);
    link.setAttribute(
      "href",
      "data:application/json;charset=utf-8," +
        encodeURIComponent(stringManifest)
    );
    document.head.appendChild(link);
  });
  // function TestError() {
  // 	const a = null;
  // 	return a.hello();
  //   }
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <ApolloProvider client={client}>
          {/* <HelmetProvider> */}
          <Router>
            <Switch>
              <Route exact path="/" component={ProgressBar} />
              <Route exact path="/home" component={Homepage} />
              <Route exact path="/cart" component={Cart} />
              <Route
                exact
                path="/o/:organisation_id/:card_id"
                component={Organisation}
              />
              <Route
                exact
                path="/o/:organisation_id/:card_id/:phone_number"
                component={Organisation}
              />
              <Route
                exact
                path="/o/:organisation_id"
                component={Organisation}
              />
              <Route exact path="/discover" component={Discover} />
              <Route exact path="/check-in" component={CheckIn} />
              {/* <Route exact path="/check-out" component={CheckOut} /> */}
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/rewards" component={Rewards} />
              <Route exact path="/my" component={CustomerPage} />
              <Route exact path="/transactions" component={Transactions} />
              <Route exact path="/credpal" component={CredpalPage} />
              <Route exact path="/404" component={ErrorPage} />
              <Route exact path="/:merchantpage/bookings" component={Booking}/>
              <Route exact path="/leaderboard" component={LeaderBoard}/>
              <Route exact path="/widget/:merchantpage/bookings" component={BookingWidget}/>
              <Route component={MerchantsPage} />
            </Switch>
          </Router>
          {/* </HelmetProvider> */}
        </ApolloProvider>
      </ErrorBoundary>
    </Provider>
  );
}

const fadeIn = {
  reset: true,
  duration: 200,
};
export default App;
