import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  HomeIcon,
  SearchIcon,
  CartIcon,
  GiftcardIcon,
  QrCode,
} from "../assets/icons";
import { QRCode } from "react-qrcode-logo";
import { Avatar, Button, Modal } from "antd";
import { useSelector, connect } from "react-redux";
import { useEffect } from "react";

const Footer = ({ active, disable, isOrganise, isService, merchanturl }) => {
  // const { organisation_id, card_id } = useParams();
  const storageUser = localStorage.getItem("user_customer_details") ?? null;
  const user = (storageUser !== undefined && storageUser !== null) ? JSON.parse(storageUser) : null;
  const [showProfile, setShowProfile] = useState(false);
  const { merchantDetails } = useSelector((state) => state.merchant);
  const { carts } = useSelector((state) => state.Order);
  const { giftcard } = useSelector((state) => state.urewardsAuth);
  const [orgId, setOrgId] = useState("");
  const [carddId, setCarddId] = useState("");
  const logoutUser = () => {
    localStorage.clear();
    window.location.href = "/home";
  };

  useEffect(() => {
    const checkFi = localStorage.getItem("organ_params") ?? null;
    if (checkFi) {
      const newFi = JSON.parse(checkFi);
      setCarddId(newFi.card_id);
      setOrgId(newFi.organisation_id);
    }
  }, []);

  const totalOrder = (cart) => {
    if (cart && cart.length === 0 ) return 0;
    const cartItemWithVariants = cart.filter((item)=>{
      return item.variants && item.variants.length > 0;
    });

    const cartItemsWithCust = cart.filter((item)=>{
      return item.custom_quantities && item.custom_quantities.length > 0;
    });

    const cartBundle = cart.filter((item)=>{
      return item.bundle_quantity && typeof item.bundle_quantity === "object";
    });

    const normItem = cart.filter((item)=>{
      return item.variants && item.variants.length === 0 && item.custom_quantities && item.custom_quantities.length === 0;
    });
    
    const totalVaraiant = cartItemWithVariants.reduce((prev, curr)=>{
      return Number(prev) + (Number(curr.quantity) * Number(curr.variants[0].price));
    }, 0);

    const totalCust = cartItemsWithCust.reduce((prev, curr)=>{
      return Number(prev) + (Number(curr.quantity) * Number(curr.custom_quantities[0].price));
    }, 0);

    const totalBundle = cartBundle.reduce((prev, curr)=>{
      return Number(prev) + (Number(curr.quantity) * Number(curr.price));
    }, 0);

    const otherTotal = normItem.reduce((prev, curr) => {
      return prev + Number(curr.quantity) * Number(curr.price);
    }, 0); 

    return otherTotal + totalCust + totalVaraiant + totalBundle;
  };

  return (
    <>
      {showProfile && (
        <Modal
          open={showProfile}
          onCancel={() => setShowProfile(false)}
          className="profile_modal"
          footer={null}
        >
          <div className="sign_up_content">
            <div>
              <Avatar src={QrCode} size="large" />
              <span
                style={{
                  margin: "25px 0 0 -13px",
                  position: "absolute",
                }}
              ></span>
            </div>

            <div style={{ marginTop: 20 }}>
              <h3
                className="title_text"
                style={{ textAlign: "center", margin: 0 }}
              >
                {user.first_name}
              </h3>
            </div>

            <div className="flex_center_column">
              <QRCode
                size={250}
                quietZone={50}
                value={`${process.env.REACT_APP_LOYSTAR_BASE_URL}/me/${user.phone_number}`}
                bgColor="#000"
                fgColor="#fff"
                qrStyle="dots"
                ecLevel="H"
                eyeRadius={[
                  {
                    outer: [10, 10, 0, 10],
                    inner: [0, 10, 10, 10],
                  },
                  [10, 10, 10, 0],
                  [10, 0, 10, 10],
                ]}
              />
              <div style={{ height: 40 }}></div>
            </div>

            {!user.address && (
              <div className="flex_center_column">
                <p>Phone Number</p>
                <p>{user.phone_number}</p>
              </div>
            )}
            {user.address && (
              <div className="contact_address">
                <div className="contact">
                  <p>Phone Number</p>
                  <p>{user.phone_number}</p>
                </div>
                <div className="address">
                  <p>Address</p>
                  <p>{user.address || "Nil"}</p>
                </div>
              </div>
            )}

            {/* <div style={{ marginTop: 30 }}>
							<p>
								<b>Complete this Task and Earn some Points</b>
							</p>
							<p style={{ color: "#6B6A6A" }}>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.sed do
								eiusmod tempor
							</p>
						</div> */}

            {/* <div style={{ marginTop: 30 }}>
							<Button
								style={{
									background: "#6B6A6A",
									width: "100%",
									height: "63px",
									marginBottom: 20,
								}}
								type="primary">
								<span>Update Your Contact</span>
							</Button>
							<Button
								style={{
									background: "#6B6A6A",
									width: "100%",
									height: "63px",
									marginBottom: 20,
								}}
								type="primary">
								<span>3 Daily Purchases</span>
							</Button>
							<Button
								style={{
									background: "#6B6A6A",
									width: "100%",
									height: "63px",
									marginBottom: 20,
								}}
								type="primary">
								<span>Visit 3 Stores</span>
							</Button>
						</div> */}

            <div className="logout">
              <Button
                style={{
                  background: "rgb(107, 0, 0)",
                  width: "100%",
                  height: "63px",
                  marginBottom: 20,
                }}
                onClick={logoutUser}
                type="primary"
              >
                <span>Logout</span>
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <div style={{ height: 100 }}></div>
      {/* <hr style={{width: "90%", position: "fixed", bottom: "11%", left: "5%"}}/> */}
      <div
        className="footer_div"
        style={{ backgroundColor: isOrganise || "#131313" }}
      >
        <Link to={merchanturl ? merchanturl : ""} style={{ display: disable ? "none" : "inherit" }}>
          <div className={active === "home" ? "nav_item active" : "nav_item"}>
            <img src={HomeIcon} alt="home" />
            <span>Home</span>
          </div>
        </Link>
        <Link to="/discover" style={{ display: disable ? "none" : "inherit" }}>
          <div
            className={active === "discover" ? "nav_item active" : "nav_item"}
          >
            <img src={SearchIcon} alt="home" />
            <span>Discover</span>
          </div>
        </Link>
        {giftcard !== null && (
          <Link
            to={`/o/${orgId}/${carddId}`}
            // style={{display: disable ? "none" : "inherit"}}
          >
            <div className={active === "home" ? "nav_item active" : "nav_item"}>
              <img src={HomeIcon} alt="home" />
              <span>Home</span>
            </div>
          </Link>
        )}
        {!isService && <Link to="/cart">
          <div
            className={active === "cart" ? "nav_item active" : "nav_item"}
            id="cartBasket"
          >
            <div
              style={{
                position: "relative",
              }}
            >
              <img src={CartIcon} alt="home" />
              <sup
                style={{
                  position: "absolute",
                  top: "7%",
                  right: "-3%",
                  fontSize: "9px",
                }}
              >
                {carts.length > 0 ? carts.length : ""}
              </sup>
            </div>
            <span style={{ fontSize: carts.length === 0 ? "16px" : "12px" }}>
              {carts.length === 0
                ? "Cart"
                : ((merchantDetails &&
                    merchantDetails.currency === undefined) ||
                  null
                    ? ""
                    : merchantDetails.currency) + totalOrder(carts).toFixed(2)}
            </span>
            {/* + "(" + (carts && carts.length) + ")" */}
          </div>
        </Link>}

        <Link to="/rewards" style={{ display: disable ? "none" : "inherit" }}>
          <div
            className={active === "rewards" ? "nav_item active" : "nav_item"}
          >
            <img src={GiftcardIcon} alt="home" />
            <span>Rewards</span>
          </div>
        </Link>
        {/* <div
						onClick={() => (user ? setShowProfile(true) : openLoginModal())}
						className={active === "profile" ? "nav_item active" : "nav_item"}
						style={{ cursor: "pointer" }}>
						<img src={QrCode} alt="user avatar" />
						<span>Rewards</span>
					</div> */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  state: state,
  carts: state.carts,
  merchantDetails: state.merchantDetails,
  urewardsAuth: state.urewardsAuth,
});
export default connect(mapStateToProps)(Footer);
