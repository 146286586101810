import React, { useEffect, useState } from "react";
import { Button, Col, Spin, message, Modal, Card } from "antd";
import bankCodes from "../../../banks.json";
import { saveInvoice } from "../../../redux/actions/ussdActions";
import { useDispatch, useSelector , connect} from "react-redux";
import PaymentCodeModal from "./paymentCodemodal";
import { loadingPNG } from "../../../assets/icons";
import SuccessModal from "./successModal";


const USSDModal = ({open, onCancel, total}) => {
    const dispatch = useDispatch();
    const { merchantDetails } = useSelector( state => state.merchant );
    const  { carts }  = useSelector( state => state.Order );
    const  { merchantloyaltySuccess, invoice, invoiceSuccess, ussdOrderSuccess, giftcard
    }  = useSelector( state => state.urewardsAuth );
    const storageUser = localStorage.getItem("user_customer_details");
    const user = storageUser ? JSON.parse(storageUser) : null;
	const [ codeLoading, setCodeLoading ] = useState(false);
    const [ choiceModal, setChoiceModal ] = useState(false);
    const [ selected, setSelected ] = useState({});
    const [ selectedStatus, setSelectedStatus ] = useState(false);

    const handleclick = (code) =>{

        const data = {
            user_id: user?.user_id,
            customer_id: user?.id,
            due_amount: total.toString(),
            // business_branch_id: "",
            cartList: carts,
            payment_method: "ussd",
            status: "unpaid",
            payment_message: `Dial the USSD code: *${code}*000*380*`,
            due_date: new Date(),
            send_notification: false,
            // has_discount: hasDiscount,
            // discount_amount: discountAmount,
        };
        dispatch( saveInvoice(data) );

    }
    useEffect(()=>{
        if(invoiceSuccess){
            setCodeLoading(false);
            setSelectedStatus(true);
        }
    }, [invoiceSuccess, invoice]);
    useEffect(()=>{
        setSelectedStatus(false);
        setCodeLoading(false);
        onCancel();
    }, [ussdOrderSuccess])

  return (
    <>
    {selectedStatus === false ?  
    <Modal
        open={open}
        // open={true}
        onCancel={onCancel}
        className="bank_modal" 
        onOk={onCancel}
		footer={null}
        title={`Pay ${merchantDetails?.currency + total} With USSD`}
    >
        {(codeLoading || !merchantloyaltySuccess ? 
        <SuccessModal 
            open={codeLoading || !merchantloyaltySuccess}
            body="Please wait..."
            onCancel={()=>setCodeLoading(false)}
        />
        : bankCodes.map((bank, index)=>{
            return <div 
            className="bank_codes" 
            key={index}
            onClick={()=>{
                if(!codeLoading && merchantloyaltySuccess){
                    handleclick(bank.code);
                    setSelected(bank);
                    setCodeLoading(true);
                }
            }}
            >
                <h4>{bank.name}</h4>
                <img 
                    src={bank.image_url} 
                    alt={bank.short}
                />
                <div className="ussd_modal">
                    {selected.name === bank.name && (
                        codeLoading && <Spin />)}
                    <p>*{bank.code}*</p>
                </div>
            </div>
        }))
        }
    </Modal> : 
    <PaymentCodeModal 
        open={open}
        onCancel={()=>{
            setSelectedStatus(false);
            setCodeLoading(false);
            onCancel();
        }}
        bank={selected}
        invoiceId={invoice?.invoice_id}
        total={total}
    />
    }
    </>
  )
}

const mapStateToProps = (state) => ({
	state: state,
	carts: state.carts,
	merchantDetails: state.merchantDetails,
	orderdetails: state.orderdetails,
    urewardsAuth: state.urewardsAuth
});
export default connect(mapStateToProps)(USSDModal);