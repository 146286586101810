import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button, InputNumber, Spin, Form, message } from 'antd';
import { InputOTP } from 'antd-input-otp';
import "./index.scss";
import Pusher from "pusher-js";
import { initiateTransaction, checkoutTransaction } from '../../../../redux/actions/credpal';
import { useSelector, connect, useDispatch } from 'react-redux';
import { useMutation } from "@apollo/client";
import { ADD_ORDERS } from '../../../../constants';
import OrderSuccess from '../ordersuccess';
import { clearCart } from '../../../../redux/actions/orders';
import { useHistory } from "react-router-dom";

const FormItem = Form.Item;
const milisecondsToDaysConst = 86400000;
const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v2`;
const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;
const authUrl = `${BASE_URL}/pusher/auth`;

const CredpalProcessModal = ({ 
    open, 
    onCancel, 
    totalAmount,
    setOrderDetails,
    cancelAsk
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [ credPalLoading, setCredPalLoading ] = useState(false);
    const [ openModalOne, setOpenModalOne ] = useState(true);
    const [ openModalTwo, setOpenModalTwo ] = useState(false);
    const [ openModalThree, setOpenModalThree ] = useState(false);
    const [ openModalFour, setOpenModalFour ] = useState(false);
    const [ phoneNumber, setPhoneNumber ] = useState(null);
    const [ email, setEmail ] = useState(null);
    const [ merchant, setMerchant ] = useState(null);
    const [ OTP, setOTP ] = useState([]);
    const [ isError, setIsError ] = useState(false);
    const [ openSuccessModal, setOpenModalSuccess ] = useState(false);
    const [ userDetails, setUserDetails ] = useState(null);
    const [merchantUrl, setMerchantUrl] = useState(null);
    const [ paymentSession, setPaymentSession ] = useState({
        available_credit: null,
        sessionId: null,
        token: null
    });
    const otpRef = useRef();
    const { carts } = useSelector((state) => state.Order);
    const { orderdetails } = useSelector((state) => state.Orders);
    const { uRewardsAuth } = useSelector((state) => state.urewardsAuth);
    const {
        isbranchSet,
        branch_address1,
        branch_address2,
    } = useSelector((state) => state.branch);
    const [addOrders] = useMutation(ADD_ORDERS);
    
    useEffect(()=>{
        const user = JSON.parse(localStorage.getItem("user_customer_details"));
        const merchant = JSON.parse(localStorage.getItem("urewards_merchant_details"));
        const isMerchantUrl = localStorage.getItem("urewards_merchant_url");

        user && setPhoneNumber(user.phone_number);
        user && setUserDetails(user);
        merchant && setEmail(merchant.email)
        merchant && setMerchant(merchant);
        isMerchantUrl && setMerchantUrl(JSON.parse(isMerchantUrl));
    }, []);

    useEffect(() => {
        const merchant = JSON.parse(localStorage.getItem("urewards_merchant_details"));

        const pusher = new Pusher(PUSHER_KEY, {
            cluster: PUSHER_CLUSTER,
            authEndpoint: authUrl,
            auth: {
                params: {
                    uid: uRewardsAuth?.uid,
                    access_token: uRewardsAuth?.access_token,
                    client: uRewardsAuth?.client,
                },
            },
        });
    
        const channel = pusher.subscribe(`private-ussd_payment_${merchant.id}`);
    
        channel.bind("new_payment_made", function (data) {
            const {
                status,
                ref_code,
                invoice: { status: invoiceStatus, paid_at, invoice_id, amount },
                customer: { first_name, last_name, phone_number, email },
            } = data;

            if (status === "success") {
                message.success("payment successful")

                addOrders({ 
                    variables: { data: orderdetails },
                    onCompleted: (data) => {
                        setOpenModalSuccess(true);
                    } 
                });
                // setAskCart(false);
                // orderdetails && addOrders({ variables: { data: orderdetails } });

                // toast.success(`Order has been made`, toastConfig);
            }
        });
    
        return () => {
            pusher.unsubscribe(
                `private-ussd_payment_${merchant.id}`
            );
        };
    }, []);

    useEffect(()=>{
        if(openModalTwo){
            otpRef.current.focus();
        }
    }, [openModalTwo]);


    const verifyPhoneNumber = () => {
        const phone_number = phoneNumber;
        
        setCredPalLoading(true);
        initiateTransaction({email, phone_number})
        .then((res)=>{
            if(res.status === false ){
                return Modal.error({
                    title: res.data?.errors?.bvn[0] || res.data?.message || "An unexpected error occured, please try again!",
                    onOk: () => setCredPalLoading(false)
                });
            }else{
                setPaymentSession({
                    available_credit: res.data.data.available_credit,
                    sessionId: res.data.data.sessionId,
                    token: res.data.data.token
                });
                if(res.data.data.available_credit < totalAmount()) setIsError(true);
    
                setOpenModalOne(false);
                setOpenModalTwo(true);
                setCredPalLoading(false);
            }
        })
        .catch((err)=>{
            Modal.error({
                title: err?.response?.data || "An unexpected error occured" + ", please try again or contact admin" ,
                onOk: () => setCredPalLoading(false)
            });
        })
        
    };

    const handleOTPInput = (value) =>{
        setOTP((prev)=>{
            return [...prev, ...value.filter(val=>val !== undefined)]
        });
    };

    const verifyOTP = () => {
        const value = OTP.toString().split(",").join("");
        if(value.length !== 6){
            Modal.error({
                title: "OTP must be six letter",
                onOk: setCredPalLoading(false)
            })
        }else if(value !== paymentSession.token){
            Modal.error({
                title: "Wrong otp input",
                onOk: setCredPalLoading(false)
            });
        }else{
            setOpenModalTwo(false);
            setOpenModalThree(true);
        }
    };
 
    const verifyPayment = () =>{
        const date = new Date().getTime();

        const data = {
            token: paymentSession.token,
            session_id: paymentSession.sessionId,
            amount: totalAmount(),
            description: JSON.stringify(carts),
            reference: `credley_${date}`
        };

        const newData = {
            email,
            data
        }

        setCredPalLoading(true);
        checkoutTransaction(newData)
        .then((res)=>{
            if(res.data.message === "Processing transaction"){
                setOrderDetails();
                setCredPalLoading(false);
                setOpenModalThree(false);
                setOpenModalFour(true);
            }else{
                Modal.error({
                    title: res?.data?.message || "An unexpected error occured, pls try again",
                    onOk: () => setCredPalLoading(false)
                })
            }
        })
        .catch((err)=>{
            console.log("error", err);
            Modal.error({
                title: err?.response?.data || "An unexpected error occured",
                onOk: () => setCredPalLoading(false)
            });
        })
        
    };

    const handleLinkClick = () => {
        dispatch(clearCart());
    
        merchantUrl ? history.push(merchantUrl) : history.push("/404");
    };

    return (
        <><Modal
            open={open}
            onCancel={onCancel}
            confirmLoading={credPalLoading}
            footer={null}
            title={
                openModalOne 
                ? "Pay with CredPal" 
                : openModalTwo 
                ? "Enter verification code" 
                : openModalThree 
                ? "Payment Confirmation" 
                : openModalFour 
                ? "Transaction In Progress" : null}
            destroyOnClose
        >
            {openModalOne && <div className="modal_1">
                <div className="md_1">
                    <div>
                        <Form
                            onFinish={verifyPhoneNumber}
                            autoComplete="off"
                        >
                            <FormItem
                               label="Phone Number"
                               name="phone_number"
                               rules={[{ required: false, message: 'Please input your phone number!' }]}
                            >
                                <InputNumber 
                                    value={phoneNumber}
                                    onChange={(value)=>setPhoneNumber(value)}
                                    defaultValue={phoneNumber}
                                    disabled
                                    placeholder={phoneNumber}
                                />
                            </FormItem>
                            <FormItem className='btn-div'>
                                <Button 
                                    loading={credPalLoading}
                                    type="primary"
                                    // onClick={verifyPhoneNumber}
                                    htmlType='submit'
                                >
                                    Verify Identity
                                </Button>
                            </FormItem>
                        </Form>
                    </div>
                </div>
            </div>}
            {openModalTwo && <div className="modal_2">
                <div className="md_1">
                    <div>
                        <p>A 4-digit code was sent to your number ending with {phoneNumber.substr(phoneNumber.length - 4, phoneNumber.length)}</p>
                        <InputOTP 
                            inputType='numeric'
                            length={6}
                            autoSubmit={verifyOTP}
                            autoFocus={true}
                            onChange={handleOTPInput}
                            ref={otpRef}
                        />
                    </div>

                    <div className="btn-div">
                        <Button 
                            loading={credPalLoading}
                            type="primary"
                            onClick={verifyOTP}
                        >
                            Verify
                        </Button>
                        <p>Didn't get the code? <span onClick={verifyPhoneNumber}>Resend code</span></p>
                    </div>
                </div>
            </div>}
            {openModalThree && <div className="modal_3">
                <div className="md_1">
                    <div>
                        <p style={{color: isError ? "red" : "#000"}}>*Credit is good, transaction can proceed</p>
                        <p>Do you want to proceed with this transaction and pay {merchant && merchant.currency}{totalAmount()} to {merchant && merchant.business_name}?</p>
                    </div>

                    <div className="btn-div">
                        <Button 
                            loading={credPalLoading}
                            type="primary"
                            onClick={verifyPayment}
                        >
                            Yes
                        </Button>
                        <Button 
                            onClick={onCancel}
                        >
                            No
                        </Button>
                    </div>
                </div>
            </div>}
            {openModalFour && <div className="modal_4">
            <div className="md_1">
                    <div>
                        <p>Do not close this page.</p>

                        <div><Spin size='large'/></div>

                        <p>Your payment is processing and you will be redirected once it is approved.</p>
                    </div>
                </div>
            </div>}
        </Modal>
            {openSuccessModal 
            && 
                <OrderSuccess 
                    open={openSuccessModal}
                    onCancel={()=>{
                        dispatch(clearCart());
                        onCancel();
                        cancelAsk();
                        setOpenModalSuccess(false);
                    }}
                    merchantDetails={merchant}
                    merchant={merchant}
                    user={userDetails}
                    orderdetails={orderdetails}
                    carts={carts}
                    word1="WE'RE PREPARING YOUR ORDER"
                    onClick={() => handleLinkClick()}
                    isbranchSet={isbranchSet}
                    branch_address1={branch_address1}
                    branch_address2={branch_address2}
                />
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    state: state,
    carts: state.carts,
    merchantDetails: state.merchantDetails,
    orderdetails: state.orderdetails,
    urewardsAuth: state.urewardsAuth,
    branch: state.branch
});
export default connect(mapStateToProps)(CredpalProcessModal);


