import * as Types from "../actions/types";

const currentLocation = localStorage.getItem("current_location_details");

let initState = {
	loading: false,
	currentLocation: currentLocation ? JSON.parse(currentLocation) : null,
};

export const currentLocationReducer = (state = initState, action) => {
	switch (action.type) {
		case Types.SET_COUNTRY_DETAILS: {
			return {
				...state,
                currentLocation: action.payload
			};
		}
		default: {
			return {
				...state,
			};
		}
	}
};
