import React, { useEffect, useState, useRef } from "react";
import Header from "../../reusables/Header";
import "./partials/modal/index.scss";
// import Footer from "../../reusables/Footer";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  CHECK_IN,
  FETCH_MERCHANT_DETAILS,
  SIGNUP_USER,
  GET_CUSTOMER_POINT_DETAILS,
  FETCH_MERCHANT_BY_ID,
  GET_USERS_BY_PHONE,
} from "../../constants";
import "./index.scss";
import {
  Button,
  Col,
  message,
  Row,
  Card,
  Modal,
  Pagination,
  Tabs,
  Spin,
  Input,
  Select,
  Carousel,
  Image,
  Badge
} from "antd";
import { organ1 } from "../../assets/images";
import { SearchOutlined, SlidersOutlined, PicLeftOutlined } from "@ant-design/icons";
import { Redirect, useHistory } from "react-router-dom";
import {
  HomePic6,
  LoveIcon,
  JayAutos,
  LogoBlack,
  addButton,
  Rectangle,
  Rectangle_13,
  AddBlack,
  ScrollToFind,
} from "../../assets/icons";
import SigninModal from "../homepage/partials/SigninModal";
import Axios from "axios";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  addToCart,
  clearCart,
  getMerchantByDispatch,
  reSignInSuccess,
  reSignInLoading,
} from "../../redux/actions/orders";
import Footer from "../../reusables/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
// import { Helmet } from 'react-helmet-async';
import {
  getApp,
  getMerchantApp,
  saveMerchantState,
  getProductBundle,
  getSingleCacheData
} from "../../redux/actions/merchant";
import { getUrewardsAuth } from "../../redux/actions/ussdActions";
import GiftModal from "./partials/modal/giftModal";
import VariantModal from "./partials/modal/variantModal";
import { BranchModal } from "./partials/modal/branchmodal";
import { setBranchAddress } from "../../redux/actions/branch";
import Booking from "../../features/booking/Booking";
import CustomQuantityModal from "./partials/modal/custQuantitymodal";
import { getMerchantLoyaltyProgram } from "../../redux/actions/productaction";
import PriceFilter from "./partials/modal/PriceChecker";
import VariantFilter from "./partials/modal/VariantFilter";
import { getCountryCode, saveCountryDetails } from "../../redux/actions/general";

const Option = Select.Option;
function MerchantsPage() {
  const [signInModalVisible, setSignInModalVisible] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [newUser, setNewUser] = useState(null);
  const [merchantProduct, setMerchantProduct] = useState([]);
  const [displayVariant, setDisplayVariant] = useState(false);
  const [productWithVariant, setproductWithVariant] = useState([]);
  const [variantQuantity, setVariantQuantity] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [askCart, setAskCart] = useState(false);
  const [confirmSignin, setConfirmSignIn] = useState(false);
  const [preventLoad, setPreventLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currrentPageNumber, setCurrentPageNumber] = useState(10);
  const [totalProductNum, setTotalProductNum] = useState(null);
  const [productsLoading, setProductsLoading] = useState(false);
  const [checkDescription, setCheckDescription] = useState(false);
  const [description, setDescription] = useState({});
  const [source, setSource] = useState("");
  const [confirmGiftCard, setConfirmGiftCard] = useState(false);
  const [openVariantModal, setOpenVariantModal] = useState(false);
  const [ openCustQuantity, setOpenCustQuantity ] = useState(false);
  const [productVariantModal, setProductVariantModal] = useState({});
  const [productCustomQuantityModal, setProductCustomQuantityModal] = useState(null);
  const [merchantLoad, setMerchantLoad] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const [productCategory, setProductCategory] = useState([]);
  const [catName, setCatName] = useState([]);
  const [merchantProductFilter, setMerchantProductFilter] = useState([]);
  const [allMerchantProduct, setAllMerchantProduct] = useState([]);
  const [loadingAllProduct, setLoadingAllProduct] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [openBranchModal, setOpenBranchModal] = useState(false);
  const [merchantBranches, setMerchantBranches] = useState([]);
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const [isBranchSelected, setIsBranchSelected] = useState(false);
  const [ selectedBranchDetails, setSelectedBranchDetails ] = useState(null);
  const [showBranchSelect, setShowBranchSelect] = useState(false);
  const [ bundledProduct, setBundledProduct ] = useState([]);
  const [ merchantLoyaltyProgram, setMerchantLoyaltyProgram ] = useState([]);
  const [ isCredPalEnabled, setIsCredPalEnabled ] = useState(false);
  const [ openPriceFilter, setOpenPriceFilter ] = useState(false);
  const [ openVariantFilter, setOpenVariantFilter ] = useState(false);
  const [ variantValue, setVariantValue ] = useState(null);
  const [ minPrice, setMinPrice ] = useState(0);
  const [ maxPrice, setMaxPrice ] = useState(0);
  const [ keys1, setKeys1 ] = useState([]);
  const [ groupVariant, setGroupedVariant ] = useState({});


  const inputRef = useRef();

  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, search, state } = history.location;
  const merchant_rewards_id = pathname.slice(1).toLowerCase();
  const identifier_string = search.slice(1);
  const merchant_id = state?.merchant_id;
  const { carts, loading } = useSelector((state) => state.Order);
  const { merchantDetails: MerchantDetails } = useSelector(
    (state) => state.merchant
  );
  const [ customerLoyaltyProgram, setCustomerLoyaltyProgram ] = useState(null);
  const {
    success,
    loading: resignLoading,
    merchantIdArray,
  } = useSelector((state) => state.resign);
  const { productLoading, productSuccess, productsProduct } = useSelector(
    (state) => state.productState
  );
  const [getUserDetails] = useLazyQuery(GET_USERS_BY_PHONE);
  const {
    success: merchantsuccess,
    loading: merchantloading,
    merchanted,
  } = useSelector((state) => state.merchantState);
  const { uRewardsAuth, status, ussdOrderSuccess } = useSelector(
    (state) => state.urewardsAuth
  );

  const [isServicesEnabled, setIsServicesEnabled] = useState(false);
  const [isProductBundleEnabled, setIsProductBundleEnabled] = useState(false);

  useEffect(() => {
    dispatch( getApp() )
    .then((res)=>{
      const application = res.data.data;

      const services = application.filter((app)=>{
        return app.app.app_name === "Services, Bookings & Appointments";
      });

      const productBundle = application.filter(app=>{
        return app.app.app_name === "Product Bundles";
      });

      const isCredPalActive = application.filter((app)=>{
        return app.app.app_name === "Credpal Loystar Credit Service";
      });

      services.length > 0 
        ? setIsServicesEnabled(true)
          : setIsServicesEnabled(false) 

      productBundle.length > 0 
        ? setIsProductBundleEnabled(true)
          : setIsProductBundleEnabled(false);

      isCredPalActive.length > 0 
        ? setIsCredPalEnabled(true) 
          : setIsCredPalEnabled(false);
    })
    .catch((err)=>{
      console.log("err", err);
    });
  }, [merchanted]);

  useEffect(()=>{
    isProductBundleEnabled && dispatch(getProductBundle())
    .then(resp=>setBundledProduct(resp.data.products))
    .catch(err=>console.log("e", err))
  }, [isProductBundleEnabled])

  useEffect(() => {
    const storageUser = localStorage.getItem("user_customer_details");
    const user = storageUser ? JSON.parse(storageUser) : null;
    !!user && setNewUser(user);
  }, []);

  useEffect(() => {
    const storageUser = localStorage.getItem("user_customer_details");
    const user = storageUser ? JSON.parse(storageUser) : null;
    user !== null && setNewUser(user);
  }, []);

  useEffect(() => {
    !!newUser &&
      localStorage.setItem("user_customer_details", JSON.stringify(newUser));
  }, [newUser]);

  // useEffect(()=>{
  // 	dispatch(getMerchantApp());
  //   }, []);
  // useEffect(() => {
  // 	if (!merchant_id) {
  // 		setRedirect("/404");
  // 	}

  // }, [merchant_rewards_id, merchant_id]);

  useEffect(() => {
    if (merchanted?.payout_active === true) {
      const email = merchanted.email;
      dispatch(getUrewardsAuth({ email }));
    }
  }, [merchanted]);

  // useEffect(() => {
  // 	const storageUser = localStorage.getItem("user_customer_details");
  // 	!newUser &&
  // 		!storageUser &&
  // 		setTimeout(() => {
  // 			message.warn("sign in to continue")
  // 			setSignInModalVisible(true);
  // 		}, 5000);
  // }, [newUser]);

  //saving location to state
  useEffect(() => {
    const merchantUrl = history.location.pathname + history.location?.search;
    localStorage.setItem("urewards_merchant_url", JSON.stringify(merchantUrl));
  }, [history]);

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.background = "#000";
  }, []);

  useEffect(() => {
    handleGetCurrentLocation();
  }, [])

  const handleOpenVariant = (product) => {
    const storageUser = localStorage.getItem("user_customer_details");
    const user = storageUser ? JSON.parse(storageUser) : null;
    if (!user) {
      message.error("Sign in to add products to your cart");
      return setSignInModalVisible(true);
    }

    const merchantDetails = JSON.parse(
      localStorage.getItem("merchant_details")
    );

    const cart_items = JSON.parse(localStorage.getItem("urewards-carts"));

    const new_cart =
      cart_items &&
      cart_items.find((items) => {
        return items.merchant_id !== merchantDetails.id;
      });

    if (new_cart) {
      toast.warning(`Check out from previous merchant to proceed to cart`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setAskCart(true);
      return;
    }

    setProductVariantModal(product);
    setOpenVariantModal(true);
  };

  const handleGetCurrentLocation = () => {
    getCountryCode()
    .then(res => {
      if(res.status === 200){
        dispatch(saveCountryDetails(res.data));
      }
    })
    .catch(err => {
      console.log("err", err);
      
    })
  }

  const handleSignIn = (merchant) => {
    const isUser = localStorage.getItem("user_customer_details");
    if(!isUser) return;
    const user = JSON.parse(isUser);
    const phone_number = user.phone_number;
    

    // let userPhone = phone_number.includes("+") ? phone_number : "+"+phone_number
		
		getUserDetails({ 
			variables: { 
			  phone_number: phone_number,
        merchant_id: merchant
			}, 
			onCompleted: (data) => {
				const { message: mess, user } = data.getUserByPhone;

        if(mess === "failed, user does not exist!"){
          return handleSignUp(user)
        }
        
				if(user){
          const storageuser = localStorage.getItem("user_customer_details");
          const newUser = JSON.parse(storageuser)
          newUser.user_id = user.user_id;
          localStorage.setItem("user_customer_details", JSON.stringify(newUser));
          setNewUser(user);
          setCustomerLoyaltyProgram(user.loyalty_points)
          !!checkInAgain && checkInAgain();
        }
			}
		});
	}

  const displayVariants = (product) => {
    if(product.variants.length === 0) return;
    const groupedVariants = {};
    const selectVariantsObj = {};

    product?.variants?.map(variant => {
        if (groupedVariants[variant.type]) {
            groupedVariants[variant.type].push(variant);
        } else {
            selectVariantsObj[variant.type] = [];
            groupedVariants[variant.type] = [variant];
        }
    });

    return <div className="variants"><div className='oi'>
      {Object.keys(groupedVariants) && Object.keys(groupedVariants).map((keyo, index)=>{
        return <div key={index}>
        <span className="type" key={index}>{`${keyo}:`}</span>
        <div className="variant-wrapper">
        {groupedVariants && groupedVariants[keyo]?.map((variant, index) => {
          return (
            <div  style={{display: "flex", flexDirection: "column-reverse", alignItems: "center", gap:0}} key={index}>
               <Badge
                  key={index}
                  className={
                  "variant"
                  }
                  onClick={() => {
                    addProductToCartVariant(product, variant)
                  }}
              >
                <span style={{ paddingRight: "10px" }}>{variant.value}</span>
                <span style={{ paddingRight: "10px" }}>-</span>
                <span>
                  {merchant.currency}
                  {product.tax_rate
                    ? (
                        (product.tax_rate / 100) * variant.price +
                        parseFloat(variant.price)
                      ).toFixed(2)
                    : variant.price}
                </span>
              </Badge>
              {variant.picture && <div>
                  {<Image 
                    src={variant.picture}
                    alt={variant.value}
                    style={{width: 70, height:70}}
                  />}
                </div>}
            </div>
          );
        })}
      </div></div>
      })}
    </div></div>
  };

  const addProductToCartVariant = (produce, variant) => {
    const itemC = localStorage.getItem("urewards-carts");
    const carts = itemC ? JSON.parse(itemC) : [];
    const totalProductCount = variant.quantity;
    let product = {...produce}
    product.variants = [variant];
    // let 
    const qty = 1;

		if(Number(variant.quantity) <= 1 ){
			toast.warning(`${variant.value} is out of stock`, {
				position: "top-center",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
			   });
			return;
		}

		const basket = document.querySelector("#cartBasket");
		basket.classList.add("cart_animate");
		setTimeout(() => {
			basket.classList.remove("cart_animate")
		}, 700);
	
		const ifProductIsAdded = carts.filter((item)=>{
			return item.id === product.id;
		});
		
		if( ifProductIsAdded.length !== 0 ){
      const isSameItemVariantPresent = ifProductIsAdded.filter((item)=>{
        return item.variants[0].id === variant.id;
      });

      if(isSameItemVariantPresent.length !== 0){
        const quantity = isSameItemVariantPresent[0].quantity + 1 ;
        if(product.track_inventory){
          if(quantity > Number(variant.quantity)){
            toast.warning(`${variant.value} is out of stock`, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              });
            return;
          }
        }
        
        dispatch( addToCart( {product, quantity, totalProductCount, variant} ) );
        toast.success(`Successfully added ${variant ? variant.value : "Item"} to Cart`, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // onCancel();
        
      }else{
        const quantity = qty;
        dispatch( addToCart( {product, quantity, totalProductCount, variant} ) );

        toast.success(`Successfully added ${variant ? variant.value : "Item"} to Cart`, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // onCancel();
      }
			
		}else{
			const quantity = qty;
			dispatch( addToCart( {product, quantity, totalProductCount, variant} ) );

			toast.success(`Successfully added ${variant ? variant.value : "Item"} to Cart`, {
				position: "top-center",
				autoClose: 1000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
        });
        // onCancel();
		}

	};

  const handleSelectCustomQuantity = (product) => {
    const storageUser = localStorage.getItem("user_customer_details");
    const user = storageUser ? JSON.parse(storageUser) : null;
    if (!user) {
      message.error("Sign in to add products to your cart");
      return setSignInModalVisible(true);
    }

    const merchantDetails = JSON.parse(
      localStorage.getItem("merchant_details")
    );

    const cart_items = JSON.parse(localStorage.getItem("urewards-carts"));

    const new_cart =
      cart_items &&
      cart_items.find((items) => {
        return items.merchant_id !== merchantDetails.id;
      });

    if (new_cart) {
      toast.warning(`Check out from previous merchant to proceed to cart`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setAskCart(true);
      return;
    }

    setProductCustomQuantityModal(product);
    setOpenCustQuantity(true);
  }

  const [signUpUser] = useMutation(SIGNUP_USER, {
    onCompleted: (data) => {
      setNewUser(data.signUpUser);
      // message.success(
      // 	`${data.signUpUser?.first_name ? data.signUpUser?.first_name : "New User"}, you have been checked in successfully`
      // );
      dispatch(reSignInSuccess(merchantDetails?.merchant_details.id));
    },
    onError: (error) => {
      message.error(error.message, 5);
    },
  });

  const getCustomerByPhone = (phone_num) => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v2/get_urewards_customer?phone=${phone_num}`
    )
      .then((res) => {
        if (res.status === 200) {
          setNewUser(res.data);
        }
      })
      .catch((err) => {
        message.error(err.response?.data.message || err.message);
      });
  };

  const getMerchantProgram = (email) => {
    getMerchantLoyaltyProgram(email)
    .then((res)=>setMerchantLoyaltyProgram(res.loyalty_program))
    .catch(err=>console.log(err));

  }

  const getCustomerPoint = (program_name) => {
    if(!customerLoyaltyProgram) return 0;
    const programs = [
      ...customerLoyaltyProgram["simple_points"],
      ...customerLoyaltyProgram["stamps_program"],
    ]

    const isProgramFound = programs.find(program => program.program_name === program_name);

    if(isProgramFound) return isProgramFound?.accumulated_points ? isProgramFound.accumulated_points : isProgramFound.accumulated_stamps;

    return 0;
  }

  const onSlidderChange = (min, max) =>{
      setMinPrice(min);
      setMaxPrice(max);
  }

  const handleApplyPriceFilter = () =>{
    const priceTotal = allMerchantProduct.filter((prod) => {
      return minPrice <= Number(prod.price) && Number(prod.price) <= maxPrice;
    })
    setMerchantProduct(priceTotal);

    setTotalProductNum(Number(priceTotal.length));
    setOpenPriceFilter(false);
  };

  const handleSetVariantValue = () => {
    if(variantValue === "") return resetFilter();
    const variantProduct = allMerchantProduct.filter((prod) => {
      return prod.variants.length > 0 && prod.variants.find((variant) => variant.value === variantValue ) 
    })

    setMerchantProduct(variantProduct);

    setTotalProductNum(Number(variantProduct.length));
    setOpenVariantFilter(false);

  }

  const resetFilter = () => {
    getMerchantProduction(1, 10);
    setMaxPrice(0)
    setMinPrice(0)
    setVariantValue(null);
    setOpenPriceFilter(false);
    setOpenVariantFilter(false);
  }


  useEffect(() => {
    newUser?.phone_number && getCustomerByPhone(newUser.phone_number);
  }, []);
  const handleVariantChoice = (item) => {
    setVariantQuantity(item.quantity);
  };

  const handleVariantAdd = () => {
    if (variantQuantity === null) {
      message.warn("Click variant to purchase");
      return;
    }
    if (quantity >= variantQuantity) {
      message.warn("Item cannot exceed this quantity");
      return;
    }
    setQuantity(quantity + 1);
  };

  const handleVariantRemove = () => {
    if (quantity <= 1) {
      return;
    }
    setQuantity(quantity - 1);
  };

  const handleVariantDisplay = (product) => {
    setproductWithVariant(product);
    setDisplayVariant(true);
  };

  const handleCancelOrder = () => {
    dispatch(clearCart());
    setAskCart(false);
  };

  const addProductToCart = (product, qty, totalProductCount) => {
    // console.log("yes it has", product, qty, totalProductCount);
    const storageUser = localStorage.getItem("user_customer_details");
    const user = storageUser ? JSON.parse(storageUser) : null;
    if (!user) {
      message.error("Sign in to add products to your cart");
      return setSignInModalVisible(true);
    }
    const { products } = product;
    if(products && products.length > 0) product.bundle_id = product.id;

    // if (bundle_products.length > 0) product.bundle_id = product.id;

    if (product.quantity === "0.0" || product.quantity === "0") {
      toast.warning(`${product.name} is out of stock`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
    if (product.price === "0.0" || product.price === "0") {
      toast.warning(`${product.name} is out of stock`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
    // console.log( "test",product);
    // if(product.variants.length !== 0){
    // 	//
    // 	handleVariantDisplay(product);
    // 	return;
    // }
    // if(!qty) quantity=1
    const basket = document.querySelector("#cartBasket");
    basket.classList.add("cart_animate");
    setTimeout(() => {
      basket.classList.remove("cart_animate");
    }, 700);
    const merchantDetails = JSON.parse(
      localStorage.getItem("merchant_details")
    );
    const cart_items = JSON.parse(localStorage.getItem("urewards-carts"));

    const new_cart =
      cart_items &&
      cart_items.find((items) => {
        return items.merchant_id !== merchantDetails.id;
      });

    if (new_cart) {
      toast.warning(`Check out from previous merchant to proceed to cart`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setAskCart(true);
      return;
    }
    const ifProductIsAdded = carts.find((item) => {
      return item.id === product.id;
    });

    if (ifProductIsAdded) {
      const quantity = ifProductIsAdded.quantity + 1;
      if (product.track_inventory) {
        if (quantity > Number(totalProductCount)) {
          toast.warning(`${product.name} is out of stock`, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          return;
        }
      }
      dispatch(addToCart({ product, quantity, totalProductCount }));
      toast.success(
        `Successfully added ${product ? product.name : "Item"} to Cart`,
        {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    } else {
      const quantity = qty;
      dispatch(addToCart({ product, quantity, totalProductCount }));

      toast.success(
        `Successfully added ${product ? product.name : "Item"} to Cart`,
        {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };

  // useEffect(() => {
  // 	const detail = JSON.parse(localStorage.getItem("user_customer_details"));
  // 	if (detail?.merchant_id !== merchant?.id || detail?.merchant_id === null) {
  // 		handleSignUp();
  // 		console.log("true");
  // 	}
  // }, []);

  // Service booking
  const [serviceBooking, setServiceBooking] = useState(false);

  const { data: checkIn, refetch } = useQuery(
    CHECK_IN,
    {
      variables: { id: String(newUser?.user_id), identifier_string },
    },
    {
      onError: (error) => {
        message.error(error.message);
      },
    }
  );

  const checkInAgain = () => {
    refetch({ id: String(newUser?.user_id), identifier_string });
  };

  const [getMerchantPoints, { data: getCustomerPointDetails }] = useLazyQuery(
    GET_CUSTOMER_POINT_DETAILS,
    {
      variables: { customer_id: Number(newUser?.user_id) },
      // skip: !newUser?.user_id,
    },
    {
      onError: (error) => {
        message.error(error.message);
      },
    }
  );

  const totalCustomerPoints = getCustomerPointDetails?.getCustomerPointDetails
    .map((point) => Number(point.points_value))
    .reduce((a, b) => a + b, 0);

  useEffect(() => {
    if (totalCustomerPoints) {
      localStorage.setItem(
        "total_customer_point",
        JSON.stringify(totalCustomerPoints)
      );
    }
  }, [totalCustomerPoints]);

  useEffect(() => {
    checkInAgain();
    if(newUser) getMerchantPoints()
  }, [identifier_string, newUser?.user_id]);

  // console.log({ checkIn });

  const { data: merchantDetailsData, loading: merchantDetailsLoading } =
    useQuery(
      FETCH_MERCHANT_DETAILS,
      {
        variables: { uRewardsId: merchant_rewards_id },
        skip: !!merchant_id,
        onCompleted: (data) => {
          const merchanDet = JSON.parse(data.fetchMerchantDetails);

          const branches = merchanDet.merchant_details.business_branches;

          // console.log(branches);
          setMerchantBranches(branches);

          const homeBranch = branches.filter((branch) => {
            return branch.name.toLowerCase().includes("home");
          });
          
          const branchpayload = {
            isbranchSet: true,
            address_line1: homeBranch[0].address_line1,
            address_line2: homeBranch[0].address_line2,
            branch_id: homeBranch[0].id,
            branch_name: homeBranch[0].name,
            latitude: homeBranch[0].latitude,
            longitude: homeBranch[0].longitude
          };

          localStorage.setItem("homebranch", JSON.stringify(homeBranch));
          localStorage.setItem("allBranchDetails", JSON.stringify(branches));
          const isBranchSet = localStorage.getItem("branchdetails");
          handleSignIn(merchanDet.merchant_details.id)

          dispatch(getMerchantApp());
          getMerchantProduction(1, 10);
          getAllMerchantProduct(1, 1000);
          getProductCategory();
          getMerchantProgram(merchanDet.merchant_details.email)

          if(isBranchSet){
            const setbranch = JSON.parse(isBranchSet);
            const doesBranchExist = branches.filter(branch => branch.name === setbranch.branch_name && branch.id === setbranch.branch_id);
            // doesBranchExist.length === 0 && setOpenBranchModal(true); 
            if(doesBranchExist.length > 0){
                setSelectedBranchId(doesBranchExist[0].id);
                setIsBranchSelected(true);
                setSelectedBranchDetails(doesBranchExist[0]);
                dispatch(setBranchAddress(doesBranchExist[0]));
            }else{
              if(branches.length > 1) return setOpenBranchModal(true); 
              setSelectedBranchId(branches[0].id);
              setIsBranchSelected(true);
              setSelectedBranchDetails(branches[0]);
              dispatch(setBranchAddress(branches[0]));
            }
          }else{
            if( merchanDet.merchant_details.business_branches.length > 1 ){
              setOpenBranchModal(true)
            }else{
              localStorage.setItem("branchdetails", JSON.stringify(branchpayload));
              dispatch(setBranchAddress(branchpayload));
            }
          }
        },
      },
      {
        onError: (error) => {
          message.error(error.message);
        },
      }
    );

  const { data: merchantDetailsByIdData, loading: merchantDetailsByIdLoading } =
    useQuery(
      FETCH_MERCHANT_BY_ID,
      { variables: { merchant_id: String(merchant_id) }, skip: !merchant_id },
      {
        onError: (error) => {
          message.error(error.message);
        },
      }
    );

  //get_product_by_id?product_id=id product with zer0 price, only display products that belongs to a branch alone. disable add and substract button, remove and fecting products by id and field from cart.
  const getMerchantDetails = (string) => {
    if (!string) return null;
    try {
      const merchant =
        string !== "Merchant not found" ? JSON.parse(string) : null;
      return merchant;
    } catch (error) {
      return error;
    }
  };

  const merchantDetails =
    getMerchantDetails(
      merchantDetailsData?.fetchMerchantDetails ||
        merchantDetailsByIdData?.fetchMerchantById
    ) || null;
  const merchantProducts =
    (merchantDetails &&
      merchantDetails.all_merchant_product?.messages[0].attachment?.payload
        .elements) ||
    [];

  const getMerchantProduction = (page, pageNum) => {
    setProductsLoading(true);
    if (isBranchSelected) {
      Axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v2/merchants/products_list/${merchantDetails?.merchant_details.id}?business_branch_id=${selectedBranchId}&page[number]=${page}&page[size]=${pageNum}`
      )
        .then((res) => {
          if (res.status === 200) {
            setProductsLoading(false);
            setMerchantProduct(() => {
              return res.data.filter((prod) => {
                return prod.publish_to_loystar_shop === true;
              });
            });
            setMerchantProductFilter(() => {
              return res.data.filter((prod) => {
                return prod.publish_to_loystar_shop === true;
              });
            });
            res.headers.total && setTotalProductNum(Number(res.headers.total));
          }
        })
        .catch((err) => {
          setProductsLoading(false);
          message.error(err.response?.data.message || err.message);
        });
    } else {
      Axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v2/get_products_of_merchant_urewards?merchant_id=${merchantDetails?.merchant_details.id}&page[number]=${page}&page[size]=${pageNum}`
      )
        .then((res) => {
          if (res.status === 200) {
            setMerchantProduct(() => {
              return res.data.filter((prod) => {
                return prod.publish_to_loystar_shop === true;
              });
            });
            setMerchantProductFilter(() => {
              return res.data.filter((prod) => {
                return prod.publish_to_loystar_shop === true;
              });
            });
            setTotalProductNum(Number(res.headers.total));
            setProductsLoading(false);
            setPreventLoad(true);
          }
        })
        .catch((err) => {
          message.error(err.response?.data.message || err.message);
        });
    }
  };

  const getAllMerchantProduct = (page, pageNum) => {
    setLoadingAllProduct(true);
    if (isBranchSelected) {
      Axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v2/merchants/products_list/${merchantDetails?.merchant_details.id}?business_branch_id=${selectedBranchId}&page[number]=${page}&page[size]=${pageNum}`
      )
        .then((res) => {
          if (res.status === 200) {
            setLoadingAllProduct(false);
            setAllMerchantProduct(() => {
              return res.data.filter((prod) => {
                return prod.publish_to_loystar_shop === true;
              });
            });
          }
        })
        .catch((err) => {
          setLoadingAllProduct(false);
          message.error(err.response?.data.message || err.message);
        });
    } else {
      Axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v2/get_products_of_merchant_urewards?merchant_id=${merchantDetails?.merchant_details.id}&page[number]=${page}&page[size]=${pageNum}`
      )
        .then((res) => {
          if (res.status === 200) {
            setLoadingAllProduct(false);
            setAllMerchantProduct(() => {
              return res.data.filter((prod) => {
                return prod.publish_to_loystar_shop === true;
              });
            });
          }
        })
        .catch((err) => {
          setLoadingAllProduct(false);
          message.error(err.response?.data.message || err.message);
        });
    }
  };

  const getProductCategory = () => {
    const merchant_email = merchantDetails?.merchant_details.email;
    Axios.post(
      `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/get_merchant_category`,
      { merchant_email, id: merchantDetails?.merchant_details.id }
    )
      .then((res) => {
        if (res.status === 200) {
          setProductCategory(res.data.data);
        }
      })
      .catch((err) => {
        message.error(`unable to process request, ${err.response.statusText}`);
      });
  };

  useEffect(() => {
    if (merchantDetailsData?.fetchMerchantDetails === "Merchant not found")
      return;
    setCurrentPage(1);
    setCurrentPageNumber(10);
    if (
      !merchantDetailsLoading &&
      !merchantDetailsByIdLoading &&
      isBranchSelected
    ) {
      dispatch(getMerchantApp());
      getMerchantProduction(1, 10);
      getAllMerchantProduct(1, 1000);
      getProductCategory();
    } else if (
      !merchantDetailsLoading &&
      !merchantDetailsByIdLoading &&
      !isBranchSelected
    ) {
      dispatch(getMerchantApp());
      getMerchantProduction(1, 10);
      getAllMerchantProduct(1, 1000);
      getProductCategory();
    }
  }, [selectedBranchId]);

  const handleSignUp = (values) => {
    try {
      dispatch(reSignInLoading());
      signUpUser({
        variables: {
          data: {
            ...values,
            merchant_rewards_id: `${values.merchant_rewards_id}` || "",
            sex: "-",
          },
        },
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    const storageUser = localStorage.getItem("user_customer_details");
    const storageMerchant = localStorage.getItem("merchant_details");
    const userd = storageUser && JSON.parse(storageUser);
    const merchnt = storageMerchant && JSON.parse(storageMerchant);

    if (userd !== null && merchnt?.id !== undefined) {
      const id = merchant_rewards_id.toString();
      const values = {
        first_name: userd?.first_name,
        phone_number: userd?.phone_number,
        merchant_rewards_id: id,
      };
      if (userd.merchant_id === null || userd?.merchant_id !== merchnt.id) {
        if (merchantIdArray.includes(merchnt.id)) {
          return;
        } else {
          handleSignUp(values);
        }
      }
    }
  }, [merchantDetailsByIdData, merchantDetailsData]);

  const merchant = merchantDetails && merchantDetails.merchant_details;
  const activeLoyalty = merchantDetails?.allMultiLoyalties?.find(
    (loyalty) => loyalty.status?.toLowerCase() === "active"
  );
  activeLoyalty &&
    localStorage.setItem("active_loyalty", JSON.stringify(activeLoyalty));

  //document.title = merchant?.busiFness_name || "uRewards Merchant";
  merchant &&
    localStorage.setItem("merchant_details", JSON.stringify(merchant));

  useEffect(() => {
    if (merchantsuccess === false) {
      merchant && dispatch(saveMerchantState(merchant));
    }
  }, [merchant]);

  useEffect(() => {
    if (showSearchIcon === true) {
      inputRef.current.focus();
    }
  }, [showSearchIcon]);

  useEffect(() => {
    let catNames = [];
    productCategory.length > 0 &&
      productCategory.map((cat, index) => {
        catNames.push(cat.id);
      });
    setCatName(catNames);
  }, [productCategory]);
  
  const handleSelectChange = (value) => {
    setSelectedBranchId(value);
    setIsBranchSelected(true);
    const selectedBranch = merchantBranches.filter((branch) => {
      return branch.id === value;
    });

    // console.log("dfd", selectedBranch);
    setSelectedBranchDetails(selectedBranch[0]);

    const branchpayload = {
      isbranchSet: true,
      address_line1: selectedBranch[0].address_line1,
      address_line2: selectedBranch[0].address_line2,
      branch_id: selectedBranch[0].id,
      branch_name: selectedBranch[0].name,
      latitude: selectedBranch[0].latitude,
      longitude: selectedBranch[0].longitude
    };

    localStorage.setItem("branchdetails", JSON.stringify(branchpayload));
    dispatch(setBranchAddress(branchpayload));
    dispatch(clearCart());
    setShowBranchSelect(false);
  };

  const handleProductSearch = (value) => {
    const merchant_email = merchantDetails?.merchant_details.email;
    setProductsLoading(true);

    const productToFind = allMerchantProduct.filter((prod) => {
      return prod.name.toLowerCase().includes(value.toLowerCase());
    });

    if (productToFind.length < 1) {
      Axios.post(
        `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/search_products_name`,
        { value, merchant_email, id: merchantDetails?.merchant_details.id }
      )
        .then((res) => {
          if (res.status === 200) {
            setProductsLoading(false);
            setMerchantProduct(res.data.data);
          } else {
            setMerchantProduct(merchantProductFilter);
          }
        })
        .catch((err) => {
          setProductsLoading(false);
          setMerchantProduct(merchantProductFilter);
          message.error(
            `unable to process request, ${err.response.statusText}`
          );
        });
    } else {
      setProductsLoading(false);
      setMerchantProduct(productToFind);
    }
  };

  const itemD = () => {
    let cate = isProductBundleEnabled ? [
      {
        key: "All",
        label: "All",
      },
      {
        key: "Bundles",
        label: "Bundles"
      }
    ] : [ {
      key: "All",
      label: "All",
    }];
    let catNames = [];
    productCategory.length > 0 &&
      productCategory.map((cat, index) => {
        if (cat.deleted) {
          return;
        } else {
          const newCat = {
            key: index,
            label: cat.name,
            children: <>{}</>,
          };
          catNames.push(cat.name);
          cate.push(newCat);
        }
      });

    // setCatName(catNames)
    return cate;
  };

  const calculateLowestAndHighestPrice = (item) => {
    const sortedItems = item.sort((a, b)=> {
      return Number(a.price) - Number(b.price)
    });

    const lowestPrice = sortedItems[0];
    const highestPrice = sortedItems[sortedItems.length - 1];

    if(Number(lowestPrice.price) === Number(highestPrice.price)){
      return <span>{Number(highestPrice.price).toFixed(2)}</span> 
    }else{
      return <span>{Number(lowestPrice.price).toFixed(2)} - {Number(highestPrice.price).toFixed(2)}</span>
    }
  }

  const items = productCategory.length > 0 && itemD();

  // const customer_loyalty_programs = newUser?.loyalty_points
  //   ? [
  //       ...newUser?.loyalty_points.simple_points,
  //       ...newUser?.loyalty_points.stamps_program,
  //     ]
  //   : [];

  if (
    !merchantDetailsLoading &&
    merchantDetailsData?.fetchMerchantDetails === "Merchant not found"
  ) {
    return <Redirect to="404" />;
  }
  if (
    !merchantDetailsByIdLoading &&
    merchantDetailsByIdData?.fetchMerchantById === "Merchant not found"
  ) {
    return <Redirect to="404" />;
  }
  

  if (!!redirect) return <Redirect to={redirect} />;
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={
            merchant?.description ||
            `Browse products and Services from ${merchant?.business_name}, make a purchase and earn rewards 🎁`
          }
        />
        <meta property="title" content={merchant?.business_name} />

        <meta
          name="keywords"
          content={merchant?.business_name || merchant?.description}
        />
        <link rel="shortcut icon" id="favicon" href={merchant?.cover_image || LogoBlack} />
        <link rel="icon" id="icon-fav" href={merchant?.cover_image || LogoBlack} />
        <title>{merchant?.business_name}</title>
        <meta property="og:title" content={merchant?.business_name} />
        <meta
          property="og:description"
          content={
            merchant?.description ||
            `Browse products and Services from ${merchant?.business_name}, make a purchase and earn rewards 🎁`
          }
        />
        <meta
          property="og:image"
          itemprop="image"
          content={merchant?.cover_image || JayAutos}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <VariantModal
        open={openVariantModal}
        onCancel={() => setOpenVariantModal(false)}
        product={productVariantModal}
      />
      <CustomQuantityModal 
        open={openCustQuantity}
        onCancel={()=>{
          setOpenCustQuantity(false);
        }}
        product={productCustomQuantityModal}
        merchant={merchant}
      />
      {signInModalVisible && (
        <SigninModal
          visible={signInModalVisible}
          merchant_rewards_id={merchant_rewards_id}
          onCancel={() => setSignInModalVisible(false)}
          setNewUser={setNewUser}
          merchant={merchant}
          checkInAgain={checkInAgain}
          setConfirmGiftCard={() => {
            setConfirmGiftCard(true);
          }}
          setCustomerLoyaltyProgram={setCustomerLoyaltyProgram}
        />
      )}
      {openPriceFilter && (
        <PriceFilter 
          open={openPriceFilter}
          onCancel={() => setOpenPriceFilter(false)}
          minPrice={minPrice}
          maxPrice={maxPrice}
          onChange={(value)=>{
            onSlidderChange(value[0], value[1])
          }}
          onClick={handleApplyPriceFilter}
          user={merchant}
          resetFilter={() => resetFilter()}
        />
      )}
      {openVariantFilter && (
        <VariantFilter 
          open={openVariantFilter}
          onCancel={() => setOpenVariantFilter(false)}
          value={variantValue}
          onChange={(e) => setVariantValue(e.target.value)}
          onClick={handleSetVariantValue}
          resetFilter={() => resetFilter()}
        />
      )}
      <GiftModal
        open={confirmGiftCard}
        onCancel={() => setConfirmGiftCard(false)}
        setNewUser={setNewUser}
        checkInAgain={checkInAgain}
      />
      <BranchModal
        open={openBranchModal}
        onCancel={() => setOpenBranchModal(false)}
        branches={merchantBranches}
        businessName={merchant?.business_name}
        setSelectedBranchId={setSelectedBranchId}
        selectedBranchId={selectedBranchId}
        setIsBranchSelected={setIsBranchSelected}
        setSelectedBranchDetails={setSelectedBranchDetails}
        merchant={merchantDetails}
      />
      {checkDescription && (
        <Modal
          open={checkDescription}
          onCancel={() => setCheckDescription(false)}
          className="description_modal"
          // onOk={}
          title={
            <p
              style={{
                fontSize: "24px",
                lineHeight: "23px",
                color: "#000",
                textAlign: "center",
                marginBottom: "-0.1rem",
              }}
            >
              <b>{description.name}</b>
            </p>
          }
          footer={[
            <>
              <div className="p_div">
                <p
                  style={{
                    fontSize: "24px",
                    lineHeight: "14px",
                    color: "#000",
                  }}
                >
                  <b>
                    {merchant?.currency}
                    {Number(description.price).toFixed(2)}
                  </b>
                </p>
              </div>
              <div
                onClick={() => {
                  if (description.variants && description.variants.length > 0) {
                    return;
                    handleOpenVariant(description);
                    setCheckDescription(false);
                  } 
                  else if(description.custom_quantities && description.custom_quantities.length > 0){
                    handleSelectCustomQuantity(description);
                    setCheckDescription(false);
                  }else {
                    addProductToCart(description, 1, description.quantity);
                  }
                }}
                style={{
                  color: "#fff",
                  width: "30%",
                  height: "3rem",
                  // display: "flex",
                  // justifyContent: "right"
                }}
                className="add_icon_footer"
              >
                <img
                  src={AddBlack}
                  alt="add to cart"
                  style={{
                    height: "40px",
                    width: "40px",
                  }}
                />
              </div>
            </>,
          ]}
        >
          <div className="div_body">
            {/* <div className="div_img"> */}
            {description.extra_pictures.length > 0 ? (
              <Carousel autoplay autoplaySpeed={4000} dots={true} style={{minWidth: "300px", minHeight: "300px"}}>
               {description.extra_pictures.map((pictures, index) => {
                return (
                <div key={index} style={{height:300}}>
                  <div style={{height: 300}}>
                  <Image
                    src={pictures}
                    alt={pictures}
                    style={{
                      // height: "300px",
                      // width: "auto",
                      // objectFit: "cover",
                      // maxHeight: "300px",
                      // maxWidth: "300px",
                    }}
                  />
                  </div>
                </div>
                ) 
                })}
                <Image
                    src={description.picture}
                    alt={description.name}
                    style={{
                      // height: "300px",
                      // width: "auto",
                      // objectFit: "cover",
                      // maxHeight: "300px",
                      // maxWidth: "300px",
                    }}
                />
            </Carousel>     
            ) : (
              <img
                src={description.picture || Rectangle}
                alt={description.name}
                style={{
                  height: "auto",
                  width: "auto",
                  objectFit: "cover",
                  maxHeight: "300px",
                  maxWidth: "300px",
                }}
            />
            )}
            <p
              style={{
                fontSize: "14px",
                lineHeight: "16px",
                padding: "0.5rem 2rem 0",
                marginBottom: 0,
              }}
            >
              {
                description.products 
                  && description.products.length > 0 
                  && description.products.map((pro, index)=>{
                    return <div style={{marginBottom: 0}} key={index}><b>{pro.name}</b> - {description.quantity[index]} (units)</div>
                  })
              }
              {description.description}
            </p>
            <div>{displayVariants(description)}</div>
          </div>
        </Modal>
      )}
      {askCart && (
        <Modal
          open={askCart}
          onCancel={() => setAskCart(false)}
          className="sure_modal"
          onOk={() => handleCancelOrder()}
          footer={[
            <div>
              <Button onClick={() => handleCancelOrder()}>Yes</Button>
              <Button onClick={() => setAskCart(false)}>No</Button>
            </div>,
          ]}
        >
          {/* <div> */}
          <h4>
            Item(s) in cart have not been checkedout from previous merchant, do
            you want to cancel order?
          </h4>
          {/* </div> */}
        </Modal>
      )}
      <Modal
        open={displayVariant}
        onCancel={() => {
          setQuantity(1);
          setVariantQuantity(null);
          setDisplayVariant(false);
        }}
        title={productWithVariant?.name}
        className="variants"
        footer={[
          <Button key="cancel">Cancel</Button>,
          <Button key="submit">Add to cart</Button>,
        ]}
      >
        <div className="variants">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>Variants:</span>
            <Button
              type="btn-primary"
              onClick={() => {
                setQuantity(1);
                setVariantQuantity(null);
              }}
            >
              Clear variants
            </Button>
          </div>
          <div>
            {productWithVariant?.variants?.map((item, index) => {
              return (
                <div key={index}>
                  <span style={{ fontSize: "14px" }} key={index}>{item?.type}</span>
                  <div
                    style={{ margin: "1rem 0" }}
                    onClick={() => handleVariantChoice(item)}
                    key={index}
                  >
                    <span className="item_variant">
                      <span>{item.value}</span>
                      <span> - </span>
                      <span>
                        {merchant?.currency} {item.price}
                        <sup>{item.quantity}</sup>
                      </span>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <span style={{ fontSize: "13px" }}>Quantity:</span>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "10% 80% 10%",
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              <span
                className="btn_change"
                onClick={() => handleVariantRemove()}
              >
                -
              </span>
              <span>{quantity}</span>
              <span className="btn_change" onClick={() => handleVariantAdd()}>
                +
              </span>
            </div>
          </div>
        </div>
      </Modal>
      <div style={{ position: "relative" }}>
        <Header
          openLoginModal={() => setSignInModalVisible(true)}
          titleComponent={
            <h2 className="title_text" style={{ margin: 0 }}>
              {merchant?.business_name || "Merchant"}
            </h2>
          }
          merchantLink={merchant_rewards_id}
          merchantee
          setShowBranchSelect={setShowBranchSelect}
          showBranchSelect={showBranchSelect}
          merchantBranches={merchantBranches}
        />
      </div>
      <div className="container_main">
        {showBranchSelect && (
          <div className="select-branch">
            {/* <h3 className="title_text" id="items_products" style={{ margin: "0px 10px 0"}}>
						Please select the branch you would like to purchase from
				</h3> */}
            <Select
              style={{
                width: "100%",
              }}
              size="large"
              placeholder="Please select the branch you would like to purchase from"
              onChange={handleSelectChange}
              defaultValue={"HQ"}
              value={selectedBranchId}
            >
              {merchantBranches.map((branch) => {
                return (
                  <Option
                    key={branch.id}
                    style={{ width: "100%" }}
                    value={branch.id}
                  >
                    {branch.name.toLowerCase().includes("home")
                      ? "HQ"
                      : branch.name}
                  </Option>
                );
              })}
            </Select>
          </div>
        )}
        <Card
          bordered={false}
          bodyStyle={{ padding: 0 }}
          loading={merchantDetailsLoading || merchantDetailsByIdLoading}
          style={{ backgroundColor: "#000", border: "none" }}
        >
          <div className="flex_center_column">
            <div
              className="flex_center_column"
              style={{
                background: `url(${
                  merchant?.cover_image || JayAutos
                }) center center/cover no-repeat`,
                width: "100%",
                height: "400px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundColor: "rgba(0,0,0,0.4)",
                backgroundBlendMode: "multiply"
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  margin: "4rem 1.5rem 0",
                }}
              >
                <h3 className="title_text" style={{ marginBottom: 0 }}>
                  {merchant?.business_name}
                </h3>
                <p
                  style={{ marginTop: 10, fontSize: "14x", lineHeight: "150%" }}
                >
                  {merchant?.business_type && merchant?.business_type}
                </p>
                <p
                  style={{
                    marginTop: 10,
                    fontSize: "14px",
                    lineHeight: "150%",
                  }}
                >
                  {merchant?.description}
                </p>
                {selectedBranchDetails ? <p>{selectedBranchDetails.address_line1 ?  selectedBranchDetails.address_line1 : "" }{selectedBranchDetails.address_line2 ? ", " + selectedBranchDetails.address_line2 : "" }</p> : <p
                  style={{
                    marginTop: 10,
                    fontSize: "14px",
                    lineHeight: "150%",
                  }}
                >
                  {merchant?.address_line1}
                  {merchant?.address_line2
                    ? ", " + merchant?.address_line2
                    : ""}
                  {merchant?.state ? ", " + merchant?.state : ""}
                  {merchant?.country ? " ," + merchant?.country : ""}
                </p>}
              </div>
              <div style={{ marginTop: "1.5rem" }}>
                <Button
                  type="primary"
                  style={{
                    padding: "5% 1rem 2rem",
                  }}
                  // disabled={merchantProduct.length === 0 ? true : false}
                >
                  <a
                    href={
                      merchantProduct.length !== 0
                        ? "#items_products"
                        : undefined
                    }
                  >
                    {carts && carts.length === 0 ? "Start Order" : "View Items"}
                  </a>
                </Button>
              </div>
            </div>
          </div>
          <div className="multi_loyalty">
            {activeLoyalty && <hr style={{ width: "100%" }} />}
            
            <>
              <div className="loyaltyDiv"> 
                {merchantLoyaltyProgram.length > 0 
                && 
                <Carousel autoplay dots={false} autoplaySpeed={4000}>
                  {merchantLoyaltyProgram.map((program, index)=>{
                    return (<div key={index}>
                      <div style={{
                        background: `center / cover no-repeat  url(${
                          program.images.length > 0 ? program.images[0] : Rectangle
                        })`,
                        height: "343px",
                        width: "100%",
                        position: "relative"
                      }}>
                        <div className="upperpose">
                          <p
                            style={{
                              fontSize: "24px",
                              padding: 0,
                              fontWeight: "600",
                              marginBottom: 0
                            }}
                          >
                            {program && program?.name}
                          </p>
                          <div style={{
                            display: "flex",
                            gap:"5px",
                            alignItems: "center"
                          }}> 
                            <p
                              style={{
                                color: "#fff",
                                fontSize: "14px",
                                opacity: "0.6",
                              }}
                            >
                              {program?.program_type === "StampsProgram" 
                                ? `You have ${getCustomerPoint(program.name)} stamps, earn ${program?.threshold} more to redeem ${program?.reward}` 
                                  : program?.program_type === "SimplePoints" 
                                    ? `You have ${getCustomerPoint(program.name)} pts, earn ${program?.threshold} points to redeem ${program?.reward}` : null}
                            </p>
                            {/* <p
                              style={{
                                color: "#fff",
                                fontSize: "10px",
                                opacity: "0.4",
                              }}
                            >
                              {program && program?.reward} 
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </div>)
                  })}
                </Carousel>
                }
                {activeLoyalty && (
                  <div
                    className="banner_text img_banner"
                    style={{
                      margin: "auto",
                      background: `center / cover no-repeat  url(${
                        activeLoyalty?.image_url || Rectangle_13
                      })`,
                    }}
                  >
                    <div>
                      <div
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.71)",
                          padding: "10px",
                          paddingRight: "15px",
                          display: "grid",
                          gridTemplateColumns: "2.5fr 0.5fr",
                        }}
                      >
                        <div
                          style={{
                            color: "#fff",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "left",
                            paddingRight: "2%", 
                          }}
                        >
                          <span
                            style={{
                              fontSize: "24px",
                              padding: 0,
                              fontWeight: "600",
                            }}
                          >
                            {activeLoyalty && activeLoyalty?.name}
                          </span>
                          <span
                            style={{
                              color: "#fff",
                              fontSize: "10px",
                              opacity: "0.4",
                            }}
                          >
                            Every {merchantDetails?.merchant_details?.currency}
                            {activeLoyalty?.currency_value} you spend with
                            us,give you {activeLoyalty?.points} points to redeem
                            items for free.
                            {/* {totalCustomerPoints || 0} points */}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "left",
                            color: "#fff",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "10px",
                              opacity: "0.4",
                            }}
                          >
                            YOU HAVE
                          </span>
                          <span
                            style={{
                              color: "#fff",
                              fontSize: "20px",
                              fontWeight: 600,
                              padding: 0,
                            }}
                          >
                            {totalCustomerPoints
                              ? Number(totalCustomerPoints).toFixed(2) + "pts"
                              : 0 + "pts"}
                          </span>

                          {/* <Button
											type="primary"
											onClick={() => !newUser && setSignInModalVisible(true)}
											style={{ marginRight: 10 }}>
											Redeem
										</Button> */}
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* <p
										style={{
											fontWeight: 600,
											fontSize: "28px",
											color: "#000",
											paddingBottom: 0,
										}}>
										{activeLoyalty?.name}
									</p> */}
                      {/* <p style={{ color: "#000", paddingBottom: 0 }}>
										Get {activeLoyalty.points} points for every{" "}
										{merchantDetails.merchant_details.currency}
										{activeLoyalty.currency_value} spent
									</p> */}
                    </div>
                    {/* <div className="button_div">
									<Button type="primary">Claim Now</Button>
								</div> */}
                  </div>
                )}
              </div>
              
              <div className="display_credit">
                {isServicesEnabled && (
                  <div
                    className="booking"
                    // style={{
                    //   border: "1px solid white",
                    //   height: "100%",
                    //   display: "flex",
                    //   flexDirection: "column",
                    //   alignItems: "center",
                    //   justifyContent: "center",
                    // }}
                  >
                    <h2>Service booking</h2>
                    <p>click here to book a service</p>
                    <div className="bookingbtn">
                      <Button
                        type="primary"
                        // style={{
                        //   padding: "5% 1rem 2rem",
                        //   backgroundColor: "#fff",
                        //   color: "#000",
                        // }}
                        onClick={() => {
                          // setServiceBooking(!serviceBooking)
                          // <Redirect to={`${}`} />
                          history.push({
                            pathname: `${history.location.pathname}/bookings`,
                            state: {
                              selectedBranchDetails: selectedBranchDetails
                            }
                          })
                        }}
                      >
                        Book Now
                      </Button>
                    </div>
                  </div>
                )}
                <div style={{height:20}} id="tabdds"></div>
                {newUser && isCredPalEnabled && (
                  <div
                    className="booking"
                    // style={{
                    //   border: "1px solid white",
                    //   height: "100%",
                    //   display: "flex",
                    //   flexDirection: "column",
                    //   alignItems: "center",
                    //   justifyContent: "center",
                    // }}
                  >
                    <h2>Want to get credit on purchased items?</h2>
                    <p>click here to apply for credit</p>
                    <div className="bookingbtn">
                      <Button
                        type="primary"
                        onClick={() => history.push("/credpal")}
                      >
                        Apply Now
                      </Button>
                    </div>
                  </div>
                )}
              </div> 
            </>

             <Modal
              open={serviceBooking}
              onCancel={() => {
                setServiceBooking(!serviceBooking);
              }}
              title="Customer booking"
              className="variants"
              footer={null}
            >
              <Booking />
            </Modal> 
          </div>

          {/* {customer_loyalty_programs.length > 0 && (
            <div>
              <h3 className="title_text" style={{ marginTop: 30 }}>
                Loyalty Programs
              </h3>
              <Row>
                {customer_loyalty_programs.map((program, index) => (
                  <Col span={24} md={12} lg={8} key={index}>
                    <div
                      className="flex_center_column"
                      style={{
                        background:
                          "linear-gradient(180deg, #C4C4C4 0%, rgba(196, 196, 196, 0) 100%)",
                        height: "280px",
                        margin: "20px 10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: 20,
                          width: "100%",
                        }}
                      >
                        <img src={LoveIcon} alt="favourite" />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: "100px",
                          width: "100%",
                          padding: "20px",
                        }}
                      >
                        <p style={{ float: "left", color: "#fff" }}>
                          <b>
                            {program.program_name}: {program.accumulated_points}
                          </b>
                        </p>
                        <p
                          style={{
                            float: "right",
                            color: "#fff",
                            textAlign: "right",
                          }}
                        >
                          <Button type="primary">
                            <b>Get</b>
                          </Button>
                        </p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )} */}
          {/* <hr style={{width: "100%"}}/> */}

          <hr style={{ width: "100%" }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3
              className="title_text"
              id="items_products"
              style={{ margin: "0px 10px 0" }}
            >
              Place Order
            </h3>
            <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
              {/* <PicLeftOutlined 
                style={{ cursor: "pointer", color: "#fff", fontSize: 24 }}
                onClick={() => setOpenVariantFilter(true)}
              /> */}
              <Image 
                src={ScrollToFind}
                alt="price filter"
                preview={false}
                height={26}
                width={26}
                onClick={() => setOpenPriceFilter(true)}
                style={{cursor: "pointer"}}
              />
              {/* <SlidersOutlined 
                size={50}
                style={{ cursor: "pointer", color: "#fff", fontSize: 24 }}
                onClick={() => setOpenPriceFilter(true)}
              /> */}
              <SearchOutlined
                size="large"
                style={{ cursor: "pointer", color: "#fff" }}
                onClick={() => {
                  setShowSearchIcon(!showSearchIcon);
                }}
              />
            </div>
          </div>
          {showSearchIcon ? (
            <Input
              value={searchText}
              placeholder="search.."
              size="large"
              ref={inputRef}
              style={{ margin: "1rem 0" }}
              onChange={(e) => {
                const { value } = e.target;
                setSearchText(value);
                if (value === "") {
                  setMerchantProduct(merchantProductFilter);
                } else {
                  handleProductSearch(value);
                }
              }}
            />
          ) : (
            productCategory.length > 0 &&
             (
              <div>
                <Tabs
                  activeKey={activeKey}
                  onChange={(key) => {
                    if (key === "All") {
                      setMerchantProduct(merchantProductFilter);
                      const element = document.getElementById("tabdds");
                      element.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
                    } else if(key === "Bundles"){
                      setMerchantProduct(bundledProduct);
                      const element = document.getElementById("tabdds");
                      element.scrollIntoView({ behavior: "smooth" });
                    }else {
                      setMerchantProduct(() => {
                        return allMerchantProduct.filter((prod) => {
                          return (
                            prod.merchant_product_category_id === catName[key]
                          );
                        });
                      });
                      const element = document.getElementById("tabdds");
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                  items={items}
                ></Tabs>
              </div>
            )
          )}
          <div id="product_list">
            {productsLoading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "#fff",
                }}
              >
                <Spin
                  size="large"
                  // visible={true}
                  style={{ textAlign: "center", color: "#fff" }}
                  className="merchant_spin"
                />
              </div>
            ) : merchantProduct.length === 0 ? (
              <h2 style={{ color: "#fff" }}>No Product Found</h2>
            ) : (
              <Row>
                {merchantProduct.map((product, index) => (
                  <Col
                    span={12}
                    md={12}
                    lg={8}
                    key={index}
                    style={{
                      height: "300px",
                      // display: product.publish_to_loystar_shop ? "initial" : "none"
                    }}
                  >
                    <div
                      className="flex_center_column"
                      onClick={() => {
                        setDescription(product);
                        setCheckDescription(true);
                      }}
                      style={{
                        backgroundImage: product.picture
                          ? `url(${product.picture})`
                          : `url(${Rectangle}) `,
                        height: "90%",
                        width: "90%",
                        margin: "20px 10px",
                        justifyContent: "space-between",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: 20,
                          width: "100%",
                        }}
                      >
                        {/* <img src={LoveIcon} alt="favourite" style={{backgroundSize: "cover", backgroundRepeat: "no-repeat"}}/> */}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: "120px",
                          background: "rgb(0, 0, 0, 0.3)",
                          width: "100%",
                          padding: "20px",
                        }}
                      >
                        <div
                          style={{
                            // float: "left",
                            color: "#fff",
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "space-between",
                            margin: "-0.5rem -0.2rem 0 -0.5rem",
                            fontSize: "14px",
                            lineHeight: "14px",
                            gap: "0.3rem",
                            // zIndex: "1"
                          }}
                        >
                          <b style={{ wordBreak: "break-all" }}>
                            {product.name}
                          </b>
                          <b>{product.variants && product.variants.length > 0 ? calculateLowestAndHighestPrice(product.variants) : product.custom_quantities && product.custom_quantities.length > 0 ? calculateLowestAndHighestPrice(product.custom_quantities) : Number(product.price).toFixed(2)}</b>
                        </div>
                      </div>
                    </div>
                    <p
                      style={{
                        float: "right",
                        color: "#fff",
                        textAlign: "right",
                        // paddingBottom: "3rem"
                        position: "absolute",
                        bottom: "1.3rem",
                        right: "1.7rem",
                      }}
                    >
                      <Button
                        type="link"
                        onClick={() => {
                          if (product.variants && product.variants.length > 0) {
                            const prod = {...product};
                            handleOpenVariant(prod);
                          } else if(product.custom_quantities && product.custom_quantities.length > 0){
                            handleSelectCustomQuantity(product);
                          }else {
                            const totalCount = typeof product.quantity === "object" ? 0 : product.quantity;
                            addProductToCart(product, 1, totalCount);
                          }
                        }}
                        // disabled={product.variants.length !== 0}
                      >
                        <img src={addButton} alt="add" style={{ scale: "2" }} />
                      </Button>
                    </p>
                  </Col>
                ))}
              </Row>
            )}
          </div>

          <Pagination
            total={totalProductNum}
            pageSize={currrentPageNumber}
            pageSizeOptions={[10, 30, 50, 100]}
            current={currentPage}
            onChange={(page, pageSize) => {
              getMerchantProduction(page, pageSize);
              setCurrentPage(page);
              setCurrentPageNumber(pageSize);
            }}
            style={{
              display: merchantProduct.length === 0 ? "none" : "inherit",
              textAlign: "center",
              marginTop: "2rem",
              marginBottom: "2rem",
            }}
          />
        </Card>
        <hr style={{ width: "100%", marginBottom: "-2%" }} />
      </div>
      <Footer active="discover" disable />
      <ToastContainer />
    </>
  );
}
const mapStateToProps = (state) => ({
  state: state,
  carts: state.carts,
  merchantDetails: state.merchantDetails,
  resignSuccess: state.resign,
  merchantIdArray: state.merchantIdArray,
  productState: state.productState,
  merchantState: state.merchantState,
  urewardsAuth: state.urewardsAuth,
});

export default connect(mapStateToProps)(MerchantsPage);
