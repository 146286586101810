import React, { useEffect, useState } from 'react';
import { Card, Button, Image, message, Modal } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { Rectangle } from '../../assets/icons';
import { CREATE_BOOKING, FETCH_MERCHANT_DETAILS } from '../../constants';
import { useMutation, useQuery } from '@apollo/client';
import Axios from 'axios';
import { BookFilled } from '@ant-design/icons';
import "./index.scss";

const BookingWidget = () => {
    const history = useHistory();
    const location = useLocation();
    const [signInModalVisible, setSignInModalVisible] = useState(false);
    const [ merchant, setMerchant ] = useState({});
    const [ services, setServices ] = useState([]);
    const [ onlineService, setOnlineServices ] = useState([]);
    const [checkDescription, setCheckDescription] = useState(false);
    const [description, setDescription] = useState({});
    const [ selectedService, setSelectedServices ] = useState([]);
    const [ selectedServiceId, setSelectedServicesId ] = useState([]);
    const [ serviceLoading, setServiceLoading ] = useState(false);
    const [ hasBookDate, setHasBookDate ] = useState(false);
    const [ repeat, setRepeat ] = useState(false);
    const [ repeat_frequency, setRepeat_frequency ] = useState(null);
    const [ repeat_number, setRepeat_number ] = useState(null);
    const [ selectedDate, setSelectedDate ] = useState(null);
    const [ branchStaff, setBranchStaff ] = useState([]);
    const [ branchStaffLoading, setBranchStaffLoading ] = useState(false);
    const [ merchantBranches, setMerchantBranches ] = useState([]);
    const [ branchAddress, setBranchAddress ] = useState(null);
    const [ selectedStaff, setSelectedStaff ] = useState(null);
    const [ displayCondition, setDisplayCondition ] = useState(null);
    const [ serviceTotal, setServiceTotal ] = useState(null);
    const [ openPaymentOption, setOpenPaymentOption ] = useState(false);
    const [ isPaymentSuccessful, setIsPaymentSuccessful ] = useState(false);
    const [ lastIndex, setLastIndex ] = useState(null);
    const [ openSuccessModal, setOpenSuccessModal ] = useState(false);
    const [ user, setUser ] = useState(null);
    const [ workHours, setWorkHours ] = useState([]);
    const [ gettingWorkHours, setGettingWorkHours ] = useState(false);
    const [ merchantEmail, setMerchantEmail ] = useState(null);
    const [ selectedBranch, setSelectedBranch ] = useState(null);
    const [ showDatePicker, setShowDatePicker ] = useState(false);
    const [ showBookingForm, setShowBookingForm ] = useState(false);

    const { loading: merchantDetailsLoading } =
    useQuery(
      FETCH_MERCHANT_DETAILS,
      {
        variables: { uRewardsId: history.location.pathname.split("/")[2] },
        // skip: !!merchant_id,
        onCompleted: (data) => {
          data.fetchMerchantDetails === "Merchant not found" && history.push("/404");
          const merchanDet = JSON.parse(data.fetchMerchantDetails);

          const branches = merchanDet.merchant_details.business_branches;

          setMerchant(merchanDet.merchant_details);
          localStorage.setItem("merchant_details",JSON.stringify(merchanDet.merchant_details));

          const homeBranch = branches.filter((branch) => {
            return branch.name.toLowerCase().includes("home");
          });

          const branchpayload = {
            isbranchSet: true,
            address_line1: homeBranch[0].address_line1,
            address_line2: homeBranch[0].address_line2,
            branch_id: homeBranch[0].id,
            branch_name: homeBranch[0].name
          };

          getMerchantApp(merchanDet.merchant_details.email);
          setMerchantEmail(merchanDet.merchant_details.email);
          getMerchantStaffDetails(merchanDet.merchant_details.email, homeBranch[0].id);
          getMerchantServices(merchanDet.merchant_details.id);
          getMerchantWorkHours(merchanDet.merchant_details.id)

          localStorage.setItem("branchdetails", JSON.stringify(branchpayload));

          localStorage.setItem("homebranch", JSON.stringify(homeBranch));
        },
      },
      {
        onError: (error) => {
          message.error(error.message);
        },
      }
    );

    const [createBooking, { loading: isCreateBookingLoading }] = useMutation(
        CREATE_BOOKING,
        {
          onCompleted: data => {
            // form.resetFields();
            // Modal.success({
            //     title: "Booking created successfully",
            // });
            if(lastIndex === selectedService.length - 1){
                setOpenSuccessModal(true);
            }
          },
          onError: error => {
            lastIndex === selectedService.length - 1 && Modal.error({
                title: error.message,
            });
          },
        //   refetchQueries: [{ query: GET_BOOKINGS }],
        }
    );

    const getMerchantApp = async ( email ) => {
        await Axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/urewards_merchant_app`, { merchant_email: email }, {
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then((response)=>{
            if(response.status === 200){
                const apps = response.data.data;

                const services = apps.filter((app)=>{
                    return app.app.app_name === "Services, Bookings & Appointments"
                });

                const url = history.location.pathname.split("/")[1];
                services.length === 0 && history.push(`/${url}`);
            }
        })
        .catch((error)=>{
            return error.message;
        })
    }


    const getMerchantServices = async (id) => {
        const merchant_id  = id;
        setServiceLoading(true);
        Axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/get_merchant_services`, { merchant_id })
        .then((res)=>{
            setServiceLoading(false);
            setServices(res.data.services);
            setOnlineServices(()=>{
                return res.data.services.filter(serv => serv.online_booking === "Enabled")
            })
        })
        .catch((err)=> {
            setServiceLoading(false);
            console.log(err);
            message.error("unable to fetch services, please try again")
        })
    }

    const getMerchantWorkHours = async (id) => {
        const merchant_id  = id;
        setGettingWorkHours(true);
        Axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/get_merchant_work_hours`, { merchant_id })
        .then((res)=>{
            setGettingWorkHours(false);
            setWorkHours(res.data.workHours);
        })
        .catch((err)=> {
            setGettingWorkHours(false);
            console.log(err);
            message.error("unable to fetch services, please try again")
        })
    }

    const getMerchantStaffDetails = async (merchant_email, branch_id) => {  
        setBranchStaffLoading(true);
        Axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/get_merchant_branch_staffdetails`, { merchant_email, branch_id })
        .then((res)=>{
            setBranchStaffLoading(false);
            setBranchStaff(res.data.data);
            setMerchantBranches(res.data.branches)
        })
        .catch((err)=> {
            setBranchStaffLoading(false);
            console.log(err);
            // message.error("unable to fetch services, please try again")
        })
    }

    const handleSelectServiceDecrease = (services) => {
        const newService = {...services};
        let quantity;

        if(services.quantity <= 1){
            setSelectedServicesId((prev)=>{
                return [
                    ...prev.filter((serv)=>serv !== services.id)
                ]
            })

            setSelectedServices((prev)=>{
                return [
                    ...prev.filter((serv)=>serv.id !== services.id)
                ]
            })

            setServices((prev)=>{
                return [
                    ...prev.map((servic)=>{
                        if(servic.id === services.id){
                            delete servic.quantity;
                            return servic;
                        }else{
                            return servic;
                        }
                    })
                ]
            })
            
        }else{
            let quantity = services.quantity;
            setSelectedServices((prev)=>{
                return [
                    ...prev.map((serv)=>{
                        if(serv.id === services.id){
                            serv.quantity = quantity - 1;
                            return serv;
                        }else{
                            return serv;
                        }
                    })
                ]
            })

            setServices((prev)=>{
                return [
                    ...prev.map((servic)=>{
                        if(servic.id === services.id){
                            servic.quantity = quantity - 1;
                            return servic;
                        }else{
                            return servic;
                        }
                    })
                ]
            })
        }
    }

    const handleSelectService = (services) => {
        const newService = {...services};
        let quantity;
        if(!services.quantity){
            newService.quantity = 1;
            quantity = 1
        }else{
            newService.quantity = Number(services.quantity) + 1;
            quantity = Number(services.quantity) + 1;
        }


        const isSelectedServices = selectedService.find(service => service.id === services.id);

        if(isSelectedServices){
            setSelectedServices((prev)=>{
                return [
                    ...prev.map((serv)=>{
                        if(serv.id === services.id){
                            serv.quantity = quantity;
                            return serv;
                        }else{
                            return serv;
                        }
                    })
                ]
            })
        }else{
            setSelectedServices((prev)=>{
                return [...prev, newService]
            })
        }

        setServices((prev)=>{
            return [
                ...prev.map((servic)=>{
                    if(servic.id === services.id){
                        servic.quantity = quantity;
                        return servic;
                    }else{
                        return servic;
                    }
                })
            ]
        })
    } 
    
    const handleSelectPaymentType = () => {

        const storageUser = localStorage.getItem("user_customer_details");
        const user = storageUser ? JSON.parse(storageUser) : null;
        const branchDetails = JSON.parse(localStorage.getItem("branchdetails"));
        const merchants = JSON.parse(localStorage.getItem("merchant_details")); 
        const branch_id  = branchDetails.branch_id;
        const branch_name  = branchDetails.branch_name;
        const merchant_id = merchants.id;
        const merchant_email = merchants.email;
        const first_name = merchants.first_name;

        if (!user) {
            message.error("Sign in to add products to your cart");
            return setSignInModalVisible(true);
        }

        localStorage.setItem("serviceItems", JSON.stringify(selectedService));

        setOpenPaymentOption(true);
    }

    useEffect(()=>{
        const displayServiceCondition = window.matchMedia("(max-width: 768px)").matches && selectedService.length === 0;
        setDisplayCondition(displayServiceCondition);
    }, [selectedService, window.matchMedia("(max-width: 768px)").matches])


    window.addEventListener("scroll", (e)=>{
        const totall = document.querySelector("#serviceviewtotal");
        if (window.matchMedia("(max-width: 768px)").matches) {
            totall && totall.classList.add("fixedPosition");
        } else {
            totall && totall.classList.add("fixedPosition");
            // if(window.pageYOffset >= 50){
                
            // }else{
            //     totall && totall.classList.remove("fixedPosition")
            // }
        }
    })
  return (
    <>
        {/* <div style={{ position: "fixed", height: "fit-content" }}> */}
        {<Card
            bordered={false}
            bodyStyle={{ padding: 20,background: showBookingForm ? "" : "transparent" }}
            
            loading={serviceLoading || merchantDetailsLoading}
            style={{ backgroundColor: showBookingForm ? "#000" : "transparent", border: "none" }}
        >
            {<div className="flex_center_column">
                <div style={{width: "100%"}}>
                    <h2 style={{ textAlign: "center", textTransform: "uppercase"}}>Select Services</h2>
                    
                    <div className="serviceview">
                        <div className="serviceviewservice">
                            {services.length > 0 ? services.map((product, index) => (
                                // product.online_booking === "Enabled" && 
                                <div 
                                    key={index} 
                                    className="serviceinitial"
                                    onClick={() => {
                                        setDescription(product);
                                        setCheckDescription(true);
                                    }}   
                                >   
                                    <div className='servicefes'>
                                        <Image 
                                            src={product.image_url || Rectangle} 
                                            alt={product.service_name}
                                        />
                                    </div>
                                    <div className='services'>
                                        <h3>{product.service_name}</h3>
                                        <div className="servicebelow">
                                            <p>{product.completion_time || product.duration} mins | {Number(product.amount_charged).toFixed(2)}</p>
                                            <div style={{zIndex: 1}}>
                                                {selectedServiceId.includes(product.id) ? 
                                                    <div className="plusandminus">
                                                        <Button
                                                            onClick={(e)=>{
                                                                e.stopPropagation();
                                                                handleSelectServiceDecrease(product)}
                                                            }
                                                        >
                                                            -
                                                        </Button>
                                                        <p style={{marginBottom: "0"}}>{product.quantity}</p>
                                                        <Button
                                                            onClick={(e)=>{
                                                                e.stopPropagation();
                                                                handleSelectService(product)}
                                                            }
                                                        >
                                                            +
                                                        </Button>
                                                    </div>
                                                :
                                                <Button
                                                onClick={(e)=>{
                                                    e.stopPropagation();
                                                    const storageUser = localStorage.getItem("user_customer_details");
                                                    const user = storageUser ? JSON.parse(storageUser) : null;
                                                    if (!user) {
                                                        message.error("Sign in to add products to your cart");
                                                        return setSignInModalVisible(true);
                                                    }
                                                    // setCheckDescription(false);
                                                    setSelectedServicesId((prev)=>{
                                                        return [...prev, product.id]
                                                    })
                                                    handleSelectService(product);
                                                }}
                                                className="selectbtn"
                                            >
                                                Select
                                            </Button>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                            )) : <h2>No service to display</h2>}
                        </div>
                        <div className="serviceviewtotal" id="serviceviewtotal"
                            style={{
                                display: displayCondition && "none"
                            }}
                        >
                            <h2 className="stitle">Service Summary</h2>
                            <hr style={{ width: "100%" }} />
                            {selectedService.length > 0
                                ? 
                                    <><div className="supDiv">
                                        {selectedService.map((service, index)=>{
                                            return (
                                                <div key={index} className="selectedServicess"
                                                >
                                                    <h3>{service.service_name}</h3>
                                                    <div className="lowerdiv">
                                                        <p>{service.completion_time || service.duration} mins | {Number(service.amount_charged).toFixed(2)}</p>
                                                        <p><b>{service.quantity}</b></p>
                                                    </div>
                                                    
                                                </div>
                                            ) 
                                        })}
                                    </div>
                                    </>
                                : <h3 style={{textAlign: "center"}}>No selected service</h3>
                            }
                            {selectedService.length > 0 
                                && 
                                <>
                                    <hr style={{ width: "100%" }} />
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}>
                                        <b style={{color: "#fff"}}>Total:</b>
                                        <p style={{margin: 0}}>{merchant.currency}{serviceTotal}</p>
                                    </div>
                                </>
                                }
                            <hr style={{ width: "100%" }} />
                            {selectedDate && <p><b>Date:</b> {selectedDate.format("DD-MM-YYYY   hh:mma")}</p>}
                            {selectedStaff && <p><b>staff:</b> {selectedStaff.staff_name} ({branchAddress})</p>}
                            {selectedDate && <hr style={{ width: "100%" }} />}
                            <div style={{
                                display: "flex", 
                                alignItems: "center", 
                                // flexDirection: hasBookDate ? "column" : "unset",
                                justifyContent: "center",
                                gap: "10px"
                                }}>
                                <Button 
                                    className="proceed"
                                    disabled={selectedService.length === 0}
                                    onClick={()=>setShowDatePicker(true)}
                                >
                                    {hasBookDate ? "Change date" : "Continue"}
                                </Button>
                                {hasBookDate 
                                    && 
                                    <Button 
                                        className="proceed"
                                        onClick={()=>handleSelectPaymentType()}
                                        loading={isCreateBookingLoading}
                                    >
                                        Book now
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                        
                    
                </div>
            </div>}
                             
        </Card>}
        {<div onClick={() => setShowBookingForm(!showBookingForm)} className={`book_filled ${!showBookingForm ? "" : "opacity_50"}`}>
            <BookFilled color='#fff' size={40} className='book_' style={{color: "#fff", fontSize: "40px"}} />
        </div>}  
        {/* </div> */}
    </>
    
  )
}

export default BookingWidget