import axios from "axios";
import * as Types from "./types";

export const getCountryCode = async () => {
    const url = `https://ipapi.co/json/`;
    const response = await axios.get(url);
    return Promise.resolve(response);
}

export const setBranchAddress = ( address ) => ( dispatch ) => {
    dispatch({ type: Types.SETBRANCH, payload: address})
}
export const saveCountryDetails = (data) => ( dispatch ) =>  {
    localStorage.setItem("current_location_details", JSON.stringify(data))
	dispatch({ type: Types.SET_COUNTRY_DETAILS, payload: data });
}