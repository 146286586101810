import React from 'react';
import { Modal, Button, Slider } from 'antd';

const PriceFilter = ({ 
    open, 
    onCancel, 
    minPrice, 
    maxPrice, 
    onChange, 
    onClick,
    user,
    resetFilter 
}) => {

  return (
    <Modal
        open={open}
        onCancel={onCancel}
        footer={[
            <>
                <Button
                    type="default"
                    onClick={resetFilter}
                >
                    Reset
                </Button>
                <Button
                type="primary"
                onClick={onClick}
                >
                    Apply
                </Button>
            </>
            
        ]}
        className="gps_modal"
        title="Price Filter"
    >
        <h4 style={{color: "#000"}}>See Prices Between</h4>
        <div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around"
                }}
            >
                <h4
                    style={{color: "#000", marginBottom: "-0.1rem"}}
                >
                {user.currency}{minPrice}</h4>
                <h4
                    style={{color: "#000", marginBottom: "-0.1rem"}}
                >
                {user.currency}{maxPrice}</h4>
            </div>
            <div
                style={{
                    padding: "0 1rem"
                }}
            >
            <Slider 
                min={0}
                max={1000000}
                range={true}
                step={2}
                onChange={onChange}
            />
            </div>
        </div>
        <div>
       
        </div>
    </Modal>
  )
}

export default PriceFilter;