import * as Types from "./types";
import Axios from "axios";
import { message } from "antd";
export const getProductLoading =()=>( dispatch )=>{

    

}

export const getProductSuccess = ( merchant_id ) => ( dispatch ) => {

    Axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v2/get_products_of_merchant_urewards?merchant_id=${ merchant_id}`
    )
    .then((res) => {
        if(res.status === 200){
            dispatch({ type: Types.PRODUCT_SUCCESS, payload: res.data});
        }
    })
    .catch((err) => {
        message.error(err.response?.data.message || err.message);
    });
    

}

export const getMerchantLoyaltyProgram = async (email) => {
    const url = `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/get_merchant_stamp_loyalty`;

    const response = await Axios.post(url, {email}, {
        headers: {
            "Content-Type": "application/json",
        }
    });

    return Promise.resolve(response.data);
}