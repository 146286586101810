import React, { useEffect, useState } from "react";
import Header from "../../reusables/Header";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_POINT_DETAILS, GET_CUSTOMER } from "../../constants";
import { Button, Col, message, Row, Card } from "antd";
import { Redirect, useHistory } from "react-router-dom";
import { LoveIcon, LogoBlack } from "../../assets/icons";
import Axios from "axios";
import { QRCode } from "react-qrcode-logo";

const CustomerPage = () => {
	// const [redirect, setRedirect] = useState(null);
	const [newUser, setNewUser] = useState(null);

	const history = useHistory();
	const { search } = history.location;
	const user = JSON.parse(localStorage.getItem("user_customer_details"));
	const phone_number = search.slice(1) || user.phone_number;
	
	useEffect(() => {
		getCustomerByPhone(phone_number);
	}, [phone_number]);

	const getCustomerByPhone = (phone_num) => {
		Axios.get(
			`${process.env.REACT_APP_BASE_URL}/api/v2/get_urewards_customer?phone=${phone_num}`
		)
			.then((res) => {
				if (res.status === 200) {
					setNewUser(res.data);
					console.log("kdk", res.data);
				}
				// else setRedirect("/404");
			})
			.catch((err) => {
				// setRedirect("/404");
				message.error(err.response?.data.message || err.message);
			});
	};

	const { data: getCustomerPointDetails } = useQuery(
		GET_CUSTOMER_POINT_DETAILS,
		{
			variables: { customer_id: Number(newUser?.user_id) },
			skip: !newUser?.user_id,
		},
		{
			onError: (error) => {
				message.error(error.message); 
			},
		}
	);
	const totalCustomerPoints = getCustomerPointDetails?.getCustomerPointDetails
		.map((point) => Number(point.points_value))
		.reduce((a, b) => a + b, 0);

	document.title = "uRewards Customer";

	const favicon = document.getElementById("favicon");
	favicon.href = LogoBlack;

	const customer_loyalty_programs = newUser?.loyalty_points
		? [
				...newUser?.loyalty_points.simple_points,
				...newUser?.loyalty_points.stamps_program,
		  ]
		: [];

	// if (!!redirect) return <Redirect to={redirect} />;
	return (
		<>
			<Header
				titleComponent={
					<h3>
						<b>{newUser?.first_name ||  "Customer"}</b>
					</h3>
				}
				openLoginModal={() => {}}
			/>
			<div className="container_main">
				<Card
					bordered={false}
					bodyStyle={{ padding: 0 }}
					style={{ backgroundColor: "#000", border: "none" }}>
					<div className="flex_center_column">
						<QRCode
							size={200}
							quietZone={50}
							value={`${process.env.REACT_APP_LOYSTAR_BASE_URL}/${newUser?.phone_number}`}
							logoWidth={100}
							logoHeight={100}
							bgColor="#000"
							fgColor="#fff"
							logoOpacity={0.6}
							qrStyle="dots"
							ecLevel="H"
							eyeRadius={[
								{
									outer: [10, 10, 0, 10],
									inner: [0, 10, 10, 10],
								},
								[10, 10, 10, 0],
								[10, 0, 10, 10],
							]}
						/>
					</div>

					<div className="multi_loyalty">
						<div
							style={{
								width: "95%",
								maxWidth: "450px",
								marginTop: 30,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								height: "100%",
							}}>
							<div
								style={{
									width: "100%",
									background: "#6B6A6A",
									padding: "10px",
									borderRadius: 5,
								}}>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}>
									<div>
										<p
											style={{
												fontSize: "12px",
												padding: 0,
											}}>
											LOYALTY POINTS
										</p>
										<p
											style={{
												color: "#fff",
												marginTop: 10,
												fontSize: "16px",
												fontWeight: 600,
											}}>
											{totalCustomerPoints || 0} points
										</p>
									</div>
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}></div>
								</div>
							</div>
						</div>
						{/* {activeLoyalty && (
							<div
								className="banner_text img_banner"
								style={{
									width: "95%",
									maxWidth: "450px",
									margin: "auto",
									marginTop: 30,
									background: `center / cover no-repeat  url(${
										activeLoyalty?.image_url || HomePic6
									})`,
								}}>
								<div>
									<p
										style={{
											fontWeight: 600,
											fontSize: "28px",
											color: "#000",
											paddingBottom: 0,
										}}>
										{activeLoyalty.name}
									</p>
									<p style={{ color: "#000", paddingBottom: 0 }}>
										Get {activeLoyalty.points} points for every{" "}
										{merchantDetails.merchant_details.currency}
										{activeLoyalty.currency_value} spent
									</p>
								</div>
								<div className="button_div">
									<Button type="primary">Claim Now</Button>
								</div>
							</div>
						)} */}
					</div>

					{customer_loyalty_programs.length > 0 && (
						<div>
							<h3 className="title_text" style={{ marginTop: 30 }}>
								Loyalty Programs
							</h3>
							<Row>
								{customer_loyalty_programs.map((program) => (
									<Col span={24} md={12} lg={8}>
										<div
											className="flex_center_column"
											style={{
												background:
													"linear-gradient(180deg, #C4C4C4 0%, rgba(196, 196, 196, 0) 100%)",
												height: "280px",
												margin: "20px 10px",
												justifyContent: "space-between",
											}}>
											<div
												style={{
													display: "flex",
													justifyContent: "flex-end",
													padding: 20,
													width: "100%",
												}}>
												<img src={LoveIcon} alt="favourite" />
											</div>

											<div
												style={{
													display: "flex",
													flexDirection: "column",
													justifyContent: "space-between",
													height: "100px",
													width: "100%",
													padding: "20px",
												}}>
												<p style={{ float: "left", color: "#fff" }}>
													<b>
														{program.program_name}: {program.accumulated_points}
													</b>
												</p>
												<p
													style={{
														float: "right",
														color: "#fff",
														textAlign: "right",
													}}>
													<Button type="primary">
														<b>Get</b>
													</Button>
												</p>
											</div>
										</div>
									</Col>
								))}
							</Row>
						</div>
					)}
				</Card>
			</div>
		</>
	);
};

export default CustomerPage;
