import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import promise from 'redux-promise-middleware';
import reducers from '../redux/reducers';

const middleWare = [thunk, promise()];

const store = createStore(reducers, compose(
    applyMiddleware(...middleWare),
        window.devToolsExtension ? window.devToolsExtension() : f => f
    )
)

export default store;