import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Footer from "../../reusables/Footer";
import Header from "../../reusables/Header";
import { GET_ORGANISATION, CONFIRM_GIFT_CARD_NUMBER } from "../../constants";
import { Card, Row, Col, message } from "antd";
import { JayAutos } from "../../assets/icons";
import { useLazyQuery } from "@apollo/client";
import PopUpModal from "./partials/popUpModal";
import {
  organ1,
  organ10,
  organ11,
  organ12,
  organ13,
  organ14,
  organ15,
  organ16,
  organ2,
  organ3,
  organ4,
  organ5,
  organ6,
  organ7,
  organ8,
  organ9,
} from "../../assets/images";
import MerchantModal from "./partials/merchnatModal";
import axios from "axios";
import { useSelector, connect, useDispatch } from "react-redux";
import {
  expireOTP,
  isOTPVerified,
  giftCardAction,
} from "../../redux/actions/ussdActions";

const Organisation = () => {
  const dispatch = useDispatch();
  const { organisation_id, card_id, phone_number } = useParams();
  const [organisationDetails, setOrganisationDetails] = useState({});
  const [openUpModal, setPopUpModal] = useState(false);
  const [category, setCategory] = useState("");
  const [merchantModal, setMerchantModal] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [merchantLoading, setMerchantLoading] = useState(false);
  const { isOTPverified, giftcard } = useSelector(
    (state) => state.urewardsAuth
  );
  const [
    confirmCard,
    {
      data: confirmData,
      loading: confirmLoading,
      error: confirmerror,
      refetch: confirmRefetch,
    },
  ] = useLazyQuery(CONFIRM_GIFT_CARD_NUMBER);

  useEffect(() => {
    const body = document.querySelector("body");
    if (organisationDetails?.organisation_design !== null) {
      body.style.background =
        organisationDetails?.organisation_design?.backgroundColor;
    }
  }, [organisationDetails]);

  useEffect(() => {
    const item = localStorage.getItem("otp_verify");
    if (item) {
      const verify = JSON.parse(item);
      if (new Date().getTime() > verify.expiry) {
        dispatch(expireOTP());
        return;
      }
      if (verify.value) {
        dispatch(isOTPVerified());
      }
    }
  }, []);

  useEffect(() => {
    let paramObj;
    if (!card_id) {
      paramObj = {
        organisation_id,
      };
    } else {
      paramObj = {
        organisation_id,
        card_id,
        phone_number,
      };
    }

    localStorage.setItem("organ_params", JSON.stringify(paramObj));
  }, [card_id, organisation_id, phone_number]);

  useEffect(() => {
    const checkCard = localStorage.getItem("gift_card");
    if (checkCard) {
      const giftcard = JSON.parse(checkCard);
      const giftCardNum = giftcard.card_number;
      // console.log("hj", confirmData, confirmLoading);
      if (confirmData !== null && confirmLoading === false) {
        // console.log("hj", confirmData, confirmLoading);
        confirmRefetch({ card_number: giftCardNum });
      }
      confirmCard({
        variables: { card_number: giftCardNum },
        onCompleted: (data) => {
          // console.log(data);
          if (data.confirmGiftCardNumber?.message === "Success") {
            const giftcard = data.confirmGiftCardNumber?.giftCard;
            dispatch(giftCardAction(giftcard));
          } else if (data.confirmGiftCardNumber?.message === "failure") {
            console.log("failed");
          }
        },
        onError: (error) => {
          console.log(error);
        },
      });
      // dispatch( giftCardAction( giftcard ) )
    }
  }, [card_id]);

  const { data, loading, error } = useQuery(GET_ORGANISATION, {
    variables: { organisation_id: organisation_id && Number(organisation_id) },
    onCompleted: (data) => {
      setOrganisationDetails(data?.getOrganisation?.organisationDetails);
      if (isOTPverified === false && card_id !== null) {
        setPopUpModal(true);
      }
    },
  });

  const handleGetMerchant = (category) => {
    if (isOTPverified === false) {
      message.warn("Confirm phone Number to continue");
      return setPopUpModal(true);
    }
    setCategory(category);
    setMerchantLoading(true);
    setMerchantModal(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v2/merchants/find_merchant_by_business_type?type=${category}&page[number]=1&page[size]=100`
      )
      .then((res) => {
        if (res.status === 200) {
          setMerchantLoading(false);
          setMerchants(res.data);
        }
      })
      .catch((err) => {
        setMerchantLoading(false);
        setMerchantModal(false);
        message.error(err.response?.data.message || err.message);
      });
  };

  // useEffect(()=>{
  //     setOrganisationDetails(data?.getOrganisation?.organisationDetails)
  // }, [data])
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={
            organisationDetails?.description ||
            `Browse products and Services from ${organisationDetails?.business_name}, make a purchase and earn rewards 🎁`
          }
        />

        <meta
          name="keywords"
          content={
            (organisationDetails && organisationDetails?.business_name) ||
            organisationDetails?.description
          }
        />
        <meta
          property="og:title"
          content={organisationDetails && organisationDetails?.business_name}
        />
        <meta
          property="og:description"
          content={
            (organisationDetails && organisationDetails?.description) ||
            `Browse products and Services from ${
              organisationDetails && organisationDetails?.business_name
            }, make a purchase and earn rewards 🎁`
          }
        />
        <meta
          property="og:image"
          itemprop="image"
          content={organisationDetails && organisationDetails?.image}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <PopUpModal
        organisation={(organisationDetails && organisationDetails) || ""}
        open={openUpModal}
        // open={true}
        onCancel={() => setPopUpModal(false)}
      />
      <MerchantModal
        open={merchantModal}
        onCancel={() => setMerchantModal(false)}
        loading={merchantLoading}
        merchants={merchants}
        category={category}
      />
      <Header
        titleComponent={
          <h2 className="title_text" style={{ margin: 0 }}>
            {organisationDetails?.organisation_name || "Merchant"}
          </h2>
        }
        organisation={organisationDetails}
        organise
        inOrganise={
          organisationDetails?.organisation_design &&
          organisationDetails?.organisation_design?.navColor
        }
      />
      <div className="container_main">
        <Card
          bordered={false}
          bodyStyle={{ padding: 0 }}
          loading={loading}
          style={{
            backgroundColor: organisationDetails?.organisation_design
              ? organisationDetails?.organisation_design?.backgroundColor
              : "#000",
            border: "none",
          }}
        >
          <div>
            <div
              className="flex_center_column"
              style={{
                background: `url(${
                  organisationDetails?.organisation_banner
                    ? organisationDetails?.organisation_banner
                    : JayAutos
                }) rgba(0, 0,0,0.4) center center/cover no-repeat`,
                backgroundColor: "rgba(0, 0,0,0.4)",
                backgroundBlendMode: "darken",
                width: "100%",
                height: "100%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  margin: "4rem 1.5rem 0",
                }}
              >
                <h3 className="title_text" style={{ marginBottom: 0 }}>
                  {organisationDetails?.organisation_name}
                </h3>
                <p
                  style={{ marginTop: 10, fontSize: "14x", lineHeight: "150%" }}
                >
                  {organisationDetails?.business_type &&
                    organisationDetails?.business_type}
                </p>
                <p
                  style={{
                    marginTop: 10,
                    fontSize: "14px",
                    lineHeight: "150%",
                  }}
                >
                  {organisationDetails?.description}
                </p>
                <p
                  style={{
                    marginTop: 10,
                    fontSize: "14px",
                    lineHeight: "150%",
                  }}
                >
                  {organisationDetails?.organisation_address}
                  {organisationDetails?.organisation_state
                    ? ", " + organisationDetails?.organisation_state
                    : ""}
                  {organisationDetails?.organisation_country
                    ? " ," + organisationDetails?.organisation_country
                    : ""}
                </p>
              </div>
              <div style={{ marginTop: "1.5rem" }}>
                {/* <Button 
                            type="primary" 
                            style={{
                                padding: "5% 1rem 2rem"
                            }}
                            // disabled={merchantProduct.length === 0 ? true : false}
                            >
                        <a href={merchantProduct.length !== 0 && "#items_products"}>{carts && carts.length === 0 ? "Start Order" : "View Items"}</a></Button> */}
              </div>
            </div>
            {giftcard !== null && (
              <>
                <hr />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: organisationDetails?.organisation_design
                      ? organisationDetails?.organisation_design?.brandColor
                      : "#000",
                    padding: "0 1rem",
                    margin: 0,
                  }}
                >
                  <h3>Gift Card Balance is</h3>
                  <p>
                    {organisationDetails.currency}
                    {giftcard?.amount}
                  </p>
                </div>
                <hr />
              </>
            )}
            <div className="organisationCat">
              <h1 style={{ color: "#000" }}>Merchant Categories</h1>
              <Row gutter={[25, 25]}>
                {allCategories.map((category, index) => {
                  return (
                    <Col lg={6} sm={12} xs={12} key={index}>
                      <div
                        style={{
                          background: `
                                        url(${category.image}) center center/cover no-repeat`,
                          height: "150px",
                          position: "relative",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        onClick={() => {
                          handleGetMerchant(category.name);
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <h3 style={{}}>{category.name}</h3>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </Card>
      </div>
      <Footer
        disable
        isOrganise={
          organisationDetails?.organisation_design &&
          organisationDetails?.organisation_design?.navColor
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  state: state,
  carts: state.carts,
  merchantDetails: state.merchantDetails,
  orderdetails: state.orderdetails,
  urewardsAuth: state.urewardsAuth,
});
export default connect(mapStateToProps)(Organisation);

const allCategories = [
  { name: "Food and Drinks", image: organ2 },
  { name: "Fashion and Accessories", image: organ1 },
  { name: "Gym and Fitness", image: organ3 },
  { name: "Beverages and Desserts", image: organ4 },
  { name: "Bakery and Pastry", image: organ5 },
  { name: "Travel and Hotel", image: organ6 },
  { name: "Hair and Beauty", image: organ7 },
  { name: "Home and Gifts", image: organ8 },
  { name: "Others", image: organ9 },
  { name: "Washing and Cleaning", image: organ10 },
  { name: "Groceries", image: organ11 },
  { name: "Salon and Beauty", image: organ12 },
  { name: "Gadgets and Electronics", image: organ13 },
  { name: "Herbs and Spices", image: organ14 },
  { name: "Raw Foods", image: organ15 },
  { name: "Restaurants, Bars and Cafes", image: organ16 },
  { name: "Agriculture", image: organ10 },
];
