import React, { useEffect } from 'react';
import { DatePicker, Modal, Select, Radio, Input, message } from "antd";
import moment from "moment";
import { useState } from 'react';

const Option = Select.Option;
const SelectDate = ({ 
    open, 
    onCancel, 
    selectedDate, 
    setSelectedDate,
    selectedStaff,
    branchStaff,
    setSelectedStaff,
    branchStaffLoading,
    setHasBookDate,
    setRepeat,
    setRepeat_frequency,
    setRepeat_number,
    repeat,
    repeat_frequency,
    repeat_number,
    workHours,
    merchantBranches,
    setBranchID,
    branchLoading,
    getMerchantStaffDetails,
    merchantEmail,
    setBranchAddress,
    selectedBranch, 
    setSelectedBranch
}) => {

    const [ daysOfTheWeek, setDaysOfTheWeek ] = useState([
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday"
    ]);
    const [ staffOfBranch, setStaffOfBranch ] = useState([]);
    

    useEffect(() => {
        selectedBranch && setStaffOfBranch(() => {
            const branchFound = merchantBranches && merchantBranches.find(branc => branc.id == selectedBranch);
            setBranchID(() => {
                return {
                    branch_id: branchFound.id,
                    branch_name: branchFound.name
                }
            })
            setBranchAddress(() => {
                return `${branchFound.address_line1 ? branchFound.address_line1 : ""}${branchFound.address_line2 ? ", " +branchFound.address_line2 : ""}`
            })
            return branchFound.staff;
        })
    }, [selectedBranch, merchantBranches])

    const handleSelectStaff = (staffid) => {
        if(staffid === "any"){
            setSelectedStaff(()=>{
                return {
                    staff_name: "any",
                    staff_email: null,
                    staff_id: null
                }  
            })
        }else{
            const staffToFind = staffOfBranch.length > 0 && staffOfBranch.find(staff=>staff.id == staffid);
            setSelectedStaff(()=>{
                return {
                    staff_name: staffToFind.username,
                    staff_email: staffToFind.email,
                    staff_id: staffToFind.id
                }
            })
        }
    }
    
    const handleOk = () => {
        if(!selectedDate) return message.error("select date to continue");
        if(!selectedStaff) return message.error("select staff to continue");

        const todaysDate = new Date().getTime();
        if(new Date(selectedDate).getTime() < todaysDate){
            return message.error("Cannot book past date!")
        }
        
        if(repeat){
            if(!repeat_frequency) return message.error("select repeat frequency");
            if(!repeat_number) return message.error("select repeat frequency")
        }
        setHasBookDate(true);
        onCancel();
    }
    const radioOptions =  [
        { label: "Yes", value: true },
        { label: "No", value: false },
    ]
  return (
    <Modal
        open={open}
        onCancel={onCancel}
        title="Book Appointment Date and Select Staff"
        onOk={()=>handleOk()}
    >
        <h3 style={{ color: "#000", margin: 0}}>Book Date</h3>
        <DatePicker 
            format="DD-MM-YYYY   hh:mma"
            size="large"
            showTime
            showNow={false}
            value={selectedDate ? moment(selectedDate) : ""}
            onChange={(value)=>{
                if(workHours && workHours.length > 0){
                    const selectedDOFW = daysOfTheWeek[new Date(value).getDay()];
                    const keys = Object.keys(workHours[0]);
                    const values = Object.values(workHours[0]);
                    const choseDay = keys && keys.find(day => day === selectedDOFW);
                    const choseValue = values[keys.indexOf(choseDay)];
                    if(choseValue === "") return message.info("This day is not available");
                    setSelectedDate(moment(value));
                }else{
                    setSelectedDate(moment(value));
                }
            }}
        />
        <h3 style={{ color: "#000", margin: 0, marginTop: "20px"}} >Select Business Branch</h3>
        <Select
            style={{width: "100%"}}
            size='large'
            placeholder="select your preferred branch"
            value={selectedBranch && selectedBranch}
            onChange={(value)=>{
                setSelectedBranch(value);
                // getMerchantStaffDetails(merchantEmail, value)
            }}
            // defaultValue={selectedBranch}
            loading={branchStaffLoading}
        >
            {merchantBranches && merchantBranches.length > 0 && 
                merchantBranches.map((branch, index)=>{
                    return <Option key={branch.id}>{branch.name}</Option>
                })
            }
        </Select>
        
        {selectedBranch && (
            <div>
                <h3 style={{ color: "#000", margin: 0, marginTop: "20px"}} >Select Staff</h3>
                <Select
                    style={{width: "100%"}}
                    size='large'
                    placeholder="select your preferred staff"
                    value={selectedStaff && selectedStaff.staff_name}
                    onChange={(value)=>handleSelectStaff(value)}
                    loading={branchStaffLoading}
                >
                    <Option key="any">Any</Option>
                    {staffOfBranch.map((stf)=>{
                            return <Option key={stf.id}>{stf.username}</Option>
                    })
                    }
                </Select>
            </div>
        )}
        

        <h3 style={{ color: "#000", margin: 0, marginTop: "20px"}} >Repeat booking</h3>
        <Radio.Group 
            value={repeat}
            className="radioBooking"
            options={radioOptions}
            onChange={(e)=>{setRepeat(e.target.value)}}
        />

        {repeat && 
            <>
                <p style={{ color: "#000", marginTop: "20px"}}>
                    <b>Repeat every</b> <br />
                    <Input 
                        size="large" 
                        onChange={(e)=>setRepeat_frequency(e.target.value)}
                        style={{width: "30%"}}
                    /> week(s)
                </p>
                <p style={{ color: "#000", marginTop: "20px"}}>
                    <b>Repeat</b> <br />
                    <Input 
                        size="large" 
                        onChange={(e)=>setRepeat_number(e.target.value)}
                        style={{width: "30%"}}
                    /> time(s)
                </p>
            </>
        }
    </Modal>
  )
}

export default SelectDate