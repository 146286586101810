import React, { useRef, useState } from "react";
import {
	Modal,
	Button,
	Carousel,
	Form,
	Input,
	DatePicker,
	Checkbox,
} from "antd";
import {
	MovieTicket,
	Compass,
	GiftCard,
	LogoBlack,
} from "../../../assets/icons";
import {
	MailOutlined,
	PhoneOutlined,
	// CalendarOutlined,
	ContactsOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";

const SignupModal = ({ visible, onCancel }) => {
	const carouselRef = useRef(null);
	const [form] = Form.useForm();
	const [otp, setOtp] = useState(null);
	const [currentIndex, setCurrentIndex] = useState(0);

	const goNext = () => carouselRef.current.next();
	const goPrev = () => carouselRef.current.prev();

	const confirmCloseModal = () =>
		Modal.confirm({
			title: "Are you sure you want to cancel your sign-up process?",
			onOk: onCancel,
		});

	return (
		<Modal
			visible={visible}
			footer={[]}
			className="login_modal"
			onCancel={confirmCloseModal}>
			{currentIndex > 1 && (
				<div className="back_button">
					<Button type="link" style={{ color: "#000" }} onClick={goPrev}>
						Back
					</Button>
				</div>
			)}
			<Carousel
				ref={carouselRef}
				dots={false}
				afterChange={(val) => setCurrentIndex(val)}>
				<div className="flex_center_column" key={1}>
					<h2
						className="title_text"
						style={{
							color: "#000",
							width: "279px",
							margin: "60px auto 0",
							paddingLeft: 10,
						}}>
						Discover Local brands & Exclusive Rewards
					</h2>
					<div>
						<div className="discover_details">
							<img src={Compass} alt="compass" />
							<div className="discover_text">
								<p>Discover Local Brands & Businesses</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor{" "}
								</p>
							</div>
						</div>

						<div className="discover_details">
							<img src={MovieTicket} alt="compass" />
							<div className="discover_text">
								<p>Get great Deals on Products & Services</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor{" "}
								</p>
							</div>
						</div>

						<div className="discover_details">
							<img src={GiftCard} alt="compass" />
							<div className="discover_text">
								<p>Get Reward for your Loyalty</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor{" "}
								</p>
							</div>
						</div>
					</div>

					<div className="action_buttons">
						<Button
							type="primary"
							style={{ width: "277px", height: "50px" }}
							onClick={goNext}>
							Continue
						</Button>
						<Button type="link" style={{ color: "#000" }}>
							Join or Login Now
						</Button>
					</div>
				</div>

				<div className="sign_up_content">
					<img src={LogoBlack} alt="urewards logo" />
					<h2
						className="title_text"
						style={{
							color: "#000",
							width: "279px",
							marginTop: 40,
						}}>
						Plugging you in...
					</h2>
					<p style={{ color: "#6B6A6A", marginTop: 30 }}>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						eiusmod tempor{" "}
					</p>
					<Form form={form}>
						<Form.Item
							name="email"
							rules={[
								{ required: true, message: "Enter your email to continue" },
							]}>
							<Input
								prefix={<MailOutlined />}
								size="large"
								type="email"
								placeholder="name@example.com"
							/>
						</Form.Item>
					</Form>
					<div className="action_buttons" style={{ marginTop: "15rem" }}>
						<Button
							type="primary"
							style={{ width: "277px", height: "50px" }}
							onClick={goNext}>
							Continue
						</Button>
						<Button type="link" style={{ color: "#000" }}>
							Having difficulties?
						</Button>
					</div>
				</div>

				<div className="sign_up_content">
					<img src={LogoBlack} alt="urewards logo" />
					<h2
						className="title_text"
						style={{
							color: "#000",
							width: "279px",
							marginTop: 40,
						}}>
						Confirmation
					</h2>
					<p style={{ color: "#6B6A6A", marginTop: 30 }}>
						Kindly input the confirmation code that was sent to your email
						address to continue your sign up
					</p>
					<OtpInput
						value={otp}
						onChange={(val) => setOtp(val)}
						numInputs={6}
						separator={false}
						shouldAutoFocus={false}
						isInputNum={true}
						isInputSecure={true}
						hasErrored={true}
						inputStyle={{
							width: "40px",
							height: "65px",
							background: "#F8F8FA",
							border: "0.5px solid #6B6A6A",
							borderRadius: "4px",
							margin: "5px",
							color: "#000",
						}}
					/>
					<div>
						<p style={{ textAlign: "right", margin: "10px 80px 0 0" }}>
							<Button type="link" style={{ color: "#000" }}>
								Resend Code!
							</Button>
						</p>
					</div>
					<div className="action_buttons" style={{ marginTop: "15rem" }}>
						<Button
							type="primary"
							style={{ width: "277px", height: "50px" }}
							onClick={goNext}>
							Continue
						</Button>
						<Button type="link" style={{ color: "#000" }}>
							Having difficulties?
						</Button>
					</div>
				</div>

				<div className="sign_up_content">
					<img src={LogoBlack} alt="urewards logo" />
					<h2
						className="title_text"
						style={{
							color: "#000",
							width: "279px",
							marginTop: 40,
						}}>
						We are almost there...
					</h2>
					<p style={{ color: "#6B6A6A", marginTop: 30 }}>
						Kindly enter your details to continue
					</p>
					<Form form={form}>
						<Form.Item
							name="firstName"
							rules={[
								{
									required: true,
									message: "Enter your first name to continue",
								},
							]}>
							<Input
								prefix={<ContactsOutlined />}
								size="large"
								type="text"
								placeholder="First Name"
							/>
						</Form.Item>
						<Form.Item
							name="firstName"
							rules={[
								{
									required: true,
									message: "Enter your first name to continue",
								},
							]}>
							<Input
								prefix={<PhoneOutlined />}
								size="large"
								type="text"
								placeholder="Your Mobile Number"
							/>
						</Form.Item>
						<Form.Item
							name="firstName"
							rules={[
								{
									required: true,
									message: "Enter your first name to continue",
								},
							]}>
							<DatePicker
								size="large"
								style={{ width: "100%" }}
								type="text"
								// suffixIcon={[]}
								placeholder="Date of Birth"
							/>
						</Form.Item>
					</Form>
					<p style={{ marginTop: 20, color: "#6B6A6A" }}>
						Our{" "}
						<Link style={{ color: "#000" }} to="/privacy">
							Privacy Policy
						</Link>
					</p>
					<div className="action_buttons" style={{ marginTop: "3rem" }}>
						<Button
							type="primary"
							style={{ width: "277px", height: "50px" }}
							onClick={goNext}>
							Continue
						</Button>
					</div>
				</div>

				<div className="sign_up_content">
					<img src={LogoBlack} alt="urewards logo" />
					<h2
						className="title_text"
						style={{
							color: "#000",
							width: "279px",
							marginTop: 20,
						}}>
						We are 50% in...
					</h2>
					<p style={{ color: "#6B6A6A", marginTop: 30 }}>
						<b>How do you prefer we reach you?</b>
					</p>
					<Form form={form}>
						<p style={{ color: "#6B6A6A" }}>
							Select one or more of ways we can send you exciting offers and
							updates. No spamming!
						</p>
						<Form.Item
							name="communications"
							rules={[
								{
									required: true,
									message: "Select one of the options",
								},
							]}>
							<Checkbox.Group
								style={{ display: "flex", flexDirection: "column" }}>
								<Checkbox
									style={{ color: "#000", margin: "10px 0" }}
									value="emails">
									I like emails
								</Checkbox>
								<Checkbox
									style={{ color: "#000", margin: "10px 0" }}
									value="sms">
									SMS is great
								</Checkbox>
								<Checkbox
									style={{ color: "#000", margin: "10px 0" }}
									value="call">
									Phone calls are good enough
								</Checkbox>
								<Checkbox
									style={{ color: "#000", margin: "10px 0" }}
									value="whatsapp">
									Whatsapp
								</Checkbox>
							</Checkbox.Group>
						</Form.Item>
					</Form>
					<div className="action_buttons" style={{ marginTop: "5rem" }}>
						<Button
							type="primary"
							style={{ width: "277px", height: "50px" }}
							onClick={goNext}>
							Continue
						</Button>
					</div>
				</div>

				<div className="sign_up_content">
					<img src={LogoBlack} alt="urewards logo" />
					<h2
						className="title_text"
						style={{
							color: "#000",
							width: "279px",
							marginTop: 20,
						}}>
						Our Terms and Conditions
					</h2>
					<p style={{ color: "#6B6A6A", marginTop: 30 }}>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis
						aute irure dolor in reprehenderit in voluptate velit esse cillum
						dolore eu fugiat nulla pariatur. <br />
						<br />
						Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
						officia deserunt mollit anim id est laborum.
					</p>

					<Checkbox style={{ color: "#000", marginTop: "30px" }} value="emails">
						I accept the terms and conditions.
					</Checkbox>
					<div className="action_buttons" style={{ marginTop: "5rem" }}>
						<Button
							type="primary"
							style={{ width: "277px", height: "50px" }}
							onClick={goNext}>
							Let's Go!
						</Button>
					</div>
				</div>
			</Carousel>
		</Modal>
	);
};

export default SignupModal;
