import * as Types from "./types";
import axios from "axios";


const addDataIntoCache = (cacheName, url, response) => {
    // Converting our response into Actual Response form
    const data = new Response(JSON.stringify(response));
    if ('caches' in window) {
      // Opening given cache and putting our data into it
      caches.open(cacheName).then((cache) => {
        cache.put(url, data);
        console.log("adding to cache...")
      });
    }
};

export const getSingleCacheData =  async (cacheName, url) => {
    // dispatch({ type: Types.PRODUCT_LOADING });
    if (typeof caches === 'undefined') return false;
    
    const cacheStorage = await caches.open(cacheName);
    const cachedResponse = await cacheStorage.match(url);
    
    // If no cache exists
    if (!cachedResponse || !cachedResponse.ok) {
        console.log("failed to retrive from cache...");
        return "Item not found"
    }
    
    return cachedResponse?.json().then((item) => {
        console.log("retriving from cache...")
        return item;
        // dispatch({ type: Types.PRODUCT_SUCCESS, payload: item});
    });
};

export const saveMerchantState = ( merchant ) => ( dispatch ) =>{

    dispatch({ type: Types.SAVE_MERCHANT_STATE, payload: merchant});

}

export const getMerchantApp = () => async ( dispatch ) => {
    const merchant = localStorage.getItem("merchant_details");
    if( merchant ){
        const merch = JSON.parse(merchant);
        const merchant_email = merch.email;
        await axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/urewards_merchant_app`, { merchant_email, id: merch.id }, {
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then((response)=>{
            if(response.status === 200){
                localStorage.setItem("merchant_app", JSON.stringify(response.data.data))
                return response.data;
            }
        })
        .catch((error)=>{
            return error.message;
        })
    }else{
        return {
            message: "no merchant found!"
        }
    }
    
}

export const getApp = () => async ( dispatch ) => {
    const merchant = localStorage.getItem("merchant_details");
    if( merchant ){
        const merch = JSON.parse(merchant);
        const merchant_email = merch.email;
        const response = await axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/urewards_merchant_app`, { merchant_email, id: merch.id }, {
            headers: {
                "Content-Type": "application/json",
            }
        })

        return Promise.resolve(response);
    }
}

export const getProductBundle = () => async ( dispatch ) => {
    const merchant = JSON.parse(localStorage.getItem("merchant_details"));
    const { id, email } = merchant;

    const url = `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/get_bundle_product`;

    const response = await axios.post(url, { id, email },{
        headers: {
            "Content-Type": "application/json",
        }
    });

    // addDataIntoCache("product bundle", url, response)
    return Promise.resolve(response);
}

export const getMerchantPayStackDetails = () => async ( dispatch ) => {
    const merchant = localStorage.getItem("merchant_details");
    if( merchant ){
        const merch = JSON.parse(merchant);
        const merchant_email = merch.email;
        const response = await axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/urewards_merchant_paystack_details`, { merchant_email, id: merch.id }, {
            headers: {
                "Content-Type": "application/json",
            }
        });

        if(response.status === 200){
            localStorage.setItem("merchant_paystack_details", JSON.stringify(response.data.data))
            return response.data;
        }else{
            return {
                message: "error"
            }
        }
    }else{
        return {
            message: "no details found!"
        }
    }
    
}