import axios from "axios";


export const initiateTransaction = async (data) => {
    const { email, phone_number } = data;

    const url = `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/initiate_credpal_transaction`;

    const response = await axios.post(url, { email, phone_number }, {
        headers: {
            "Content-Type": "application/json",
        }
    });

    return Promise.resolve(response.data);
}

export const checkoutTransaction = async (data) => {
    const url = `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/chekout_credpal_transaction`;

    const response = await axios.post(url, data, {
        headers: {
            "Content-Type": "application/json",
        }
    });

    return Promise.resolve(response.data);
}

export const saveCustomersBvn = async (data) => {
    const url = `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/update_customer_bvn`;

    const response = await axios.post(url, data, {
        headers: {
            "Content-Type": "application/json",
        }
    });

    return Promise.resolve(response.data);
}