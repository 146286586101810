import React, { useEffect, useState} from 'react';
import { Modal, Button, Badge, Image } from 'antd';
import { useDispatch, useSelector, connect } from "react-redux";
import { ToastContainer, toast} from "react-toastify";
import "./index.scss";
import { addToCart } from '../../../../redux/actions/orders';

const VariantModal = ({ open, onCancel, product }) => {
  const dispatch = useDispatch();
  const [ askCart, setAskCart] = useState(false);
  const [ groupVariant, setGroupedVariant ] = useState({});
  const [ selectedVariants, setSelectedVariants ] = useState({});
  const [ isSelected, setIsSelected ] = useState({});
  const [ keys1, setKeys1 ] = useState([]);
  const [ quantity, setQuantity ] = useState(null);
  const [ merchant, setMerchantDetails ] = useState({});

  const  { carts, loading }  = useSelector( state => state.Order );



  useEffect(()=>{
    const userCheck = localStorage.getItem("urewards_merchant_details");
    const user = userCheck ? JSON.parse(userCheck) : [];
    setMerchantDetails(user);
  }, [])

  useEffect(()=>{
    const groupedVariants = {};
    const selectVariantsObj = {};

    product?.variants?.map(variant => {
        if (groupedVariants[variant.type]) {
            groupedVariants[variant.type].push(variant);
        } else {
            selectVariantsObj[variant.type] = [];
            groupedVariants[variant.type] = [variant];
        }
    });
    setGroupedVariant(groupedVariants);
    setKeys1(Object.keys(groupedVariants));
    setSelectedVariants(selectVariantsObj);
  }, [product]);

  const calculateLowestAndHighestPrice = (item) => {

    const sortedItems = item.sort((a, b)=> {
        return Number(a.price) - Number(b.price)
    });

    
    const lowestPrice = sortedItems[0];
    const highestPrice = sortedItems[sortedItems.length - 1];

    if(Number(lowestPrice.price) === Number(highestPrice.price)){
        return <span>{Number(highestPrice.price).toFixed(2)}</span> 
    }else{
        return <span>{Number(lowestPrice.price).toFixed(2)} - {Number(highestPrice.price).toFixed(2)}</span>
    }
}

  const selectVariant = (variant, key) => {
    setQuantity(1)
    const keys = Object.keys(selectedVariants);
    let check = false;
    keys.forEach(selectedKey => {
      if (selectedVariants[selectedKey].length > 0 && key !== selectedKey) {
        if (selectedVariants[selectedKey][0].price !== variant.price) {
          check = true;
        }
      }
    });
    if (check) {
      Modal.warning({
        title: "The previous variant selection is not available for the new variant selected based on price, please change your selection.",
      });
      return;
    }
    selectedVariants[key] = [variant];

    setIsSelected(variant)
    setSelectedVariants(selectedVariants)
    
  };

  const clearSelectedVariants = () => {
    Object.keys(selectedVariants).forEach(key => {
        selectedVariants[key] = [];
    });

    setSelectedVariants(selectedVariants);
    setQuantity(1);
    setIsSelected({})
    // form.setFieldsValue({ quantity: quantity });
  };

  const addProductToCart = (product, variant) => {
    const itemC = localStorage.getItem("urewards-carts");
    const carts = itemC ? JSON.parse(itemC) : [];
    const totalProductCount = variant.quantity;
    product.variants = [variant]
    const qty = 1;

		if(Number(variant.quantity) <= 1 ){
			toast.warning(`${variant.value} is out of stock`, {
				position: "top-center",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
			   });
			return;
		}

		const basket = document.querySelector("#cartBasket");
		basket.classList.add("cart_animate");
		setTimeout(() => {
			basket.classList.remove("cart_animate")
		}, 700);
	
		const ifProductIsAdded = carts.filter((item)=>{
			return item.id === product.id;
		});
		
		if( ifProductIsAdded.length !== 0 ){
      const isSameItemVariantPresent = ifProductIsAdded.filter((item)=>{
        return item.variants[0].id === variant.id;
      });

      if(isSameItemVariantPresent.length !== 0){
        const quantity = isSameItemVariantPresent[0].quantity + 1 ;
        if(product.track_inventory){
          if(quantity > Number(variant.quantity)){
            toast.warning(`${variant.value} is out of stock`, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              });
            return;
          }
        }
        
        dispatch( addToCart( {product, quantity, totalProductCount, variant} ) );
        toast.success(`Successfully added ${variant ? variant.value : "Item"} to Cart`, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        onCancel();
        
      }else{
        const quantity = qty;
        dispatch( addToCart( {product, quantity, totalProductCount, variant} ) );

        toast.success(`Successfully added ${variant ? variant.value : "Item"} to Cart`, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        onCancel();
      }
			
		}else{
			const quantity = qty;
			dispatch( addToCart( {product, quantity, totalProductCount, variant} ) );

			toast.success(`Successfully added ${variant ? variant.value : "Item"} to Cart`, {
				position: "top-center",
				autoClose: 1000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
        });
        onCancel();
		}

	};

  const displayVariants = () => {
    <div className='oi'>
        {keys1 && keys1.map((keyo, index)=>{
          return <div key={index}>
          <span className="type" key={index}>{`${keyo}:`}</span>
          <div className="variant-wrapper">
          {groupVariant && groupVariant[keyo]?.map((variant, index) => {
            return (
              <Badge
                key={variant.id}
                className={
                  isSelected?.id === variant.id
                    ? "variant selected"
                    : "variant"
                }
                onClick={() => {
                  addProductToCart(product, variant)
                }}
              >
                <span style={{ paddingRight: "10px" }}>{variant.value}</span>
                <span style={{ paddingRight: "10px" }}>-</span>
                <span>
                  {merchant.currency}
                  {product.tax_rate
                    ? (
                        (product.tax_rate / 100) * variant.price +
                        parseFloat(variant.price)
                      ).toFixed(2)
                    : variant.price}
                </span>
              </Badge>
            );
          })}
        </div></div>
        })}
        {/* <span className="type">{`${keys1}:`}</span> */}
        
      </div>
  };

  return (
    <Modal
        open={open}
        onCancel={onCancel}
        footer={null}
        destroyOnClose
        title={[<>< span key={1}>{product && product.name}</span> <span key={2}>{product && product.variants &&  calculateLowestAndHighestPrice(product.variants)}</span></>]}
    >
      <div className="variants">
        <span>Select your chioce</span>
        <div className='oi'>
          {keys1 && keys1.map((keyo, index)=>{
            return <div key={index}>
            <span className="type" key={index}>{`${keyo}:`}</span>
            <div className="variant-wrapper">
            {groupVariant && groupVariant[keyo]?.map((variant, index) => {
              return (
                <div  style={{display: "flex", flexDirection: "column-reverse", alignItems: "center", gap:0}}>
                <Badge
                  key={variant.id}
                  className={
                    isSelected?.id === variant.id
                      ? "variant selected"
                      : "variant"
                  }
                  onClick={() => {
                    addProductToCart(product, variant)
                  }}
                >
                  <span style={{ paddingRight: "10px" }}>{variant.value}</span>
                  <span style={{ paddingRight: "10px" }}>-</span>
                  <span>
                    {merchant.currency}
                    {product.tax_rate
                      ? (
                          (product.tax_rate / 100) * variant.price +
                          parseFloat(variant.price)
                        ).toFixed(2)
                      : variant.price}
                  </span>
                </Badge>
                {variant.picture && <div>
                  {<Image 
                    src={variant.picture}
                    alt={variant.value}
                    style={{width: 70, height:70}}
                  />}
                </div>}
                </div>
              );
            })}
          </div></div>
          })}
          {/* <span className="type">{`${keys1}:`}</span> */}
          
        </div>
      </div>
    </Modal>
  )
}


const mapStateToProps = (state) => ({
	state: state,
	carts: state.carts,
	merchantDetails: state.merchantDetails,
	resignSuccess: state.resign,
	merchantIdArray: state.merchantIdArray,
	productState: state.productState,
	merchantState: state.merchantState,
	urewardsAuth: state.urewardsAuth
});

export default connect(mapStateToProps)(VariantModal);