import * as Types from "../actions/types";


const initialState = {
    success: false,
    loading: false,
    merchanted: localStorage.getItem("merchant_details") ? JSON.parse(localStorage.getItem("merchant_details")) : []
}


export const merchantReducer = (state= initialState, { type, payload}) => {
    switch (type) {
        case Types.SAVE_MERCHANT_STATE:
           state.merchanted = payload;
           state.loading = false;
           state.success = true;
           return state;
        default:
            return state;
    }
}