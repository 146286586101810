import React, { useEffect, useState } from "react";
import { Button,  Col, message } from "antd";
import { LoveIcon, MerchantSpotlight, UserAvatar } from "../../assets/icons";
import Header from "../../reusables/Header";
import Footer from "../../reusables/Footer";
import { MoneyIcon, MovieTicketWhite } from "../../assets/icons";
import moment from "moment";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import SigninModal from "../homepage/partials/SigninModal";

function Rewards() {
	const [merchantSpotlight, setMerchantSpotlight] = useState(null);
	const [proMerchants, setProMerchants] = useState([]);
	const [loading, setLoading] = useState(false);
	const [allProducts, setAllProducts] = useState([]);
	const [redirect, setRedirect] = useState(null);
	const [signInModalVisible, setSignInModalVisible] = useState(false);

	const todayString = moment(Date.now()).format("YYYY-MM-DD");

	useEffect(() => {
		const spotlightMerchant = localStorage.getItem(todayString);
		if (spotlightMerchant) {
			setMerchantSpotlight(JSON.parse(spotlightMerchant));
			getProducts(JSON.parse(spotlightMerchant));
		} else {
			if (proMerchants.length > 0) {
				const newSpotlight =
					proMerchants[Math.floor(Math.random() * proMerchants.length)];
				getProducts(newSpotlight);
				setMerchantSpotlight(newSpotlight);
				localStorage.setItem(todayString, JSON.stringify(newSpotlight));
			}
		}
	}, [proMerchants, todayString]);

	const getAllProMerchants = () => {
		setLoading(true);
		Axios.get(
			`${process.env.REACT_APP_BASE_URL}/api/v2/merchants/get_pro_merchants`
		)
			.then((res) => {
				setLoading(false);
				if (res.status === 200) {
					setProMerchants(res.data);
				}
			})
			.catch((err) => {
				setLoading(false);
				message.error(err.response?.data.message || err.message);
			});
	};

	const getProducts = (merchant) => {
		setLoading(true);
		Axios.get(
			`${process.env.REACT_APP_BASE_URL}/api/v2/products_by_merchants_chatfuel?business_type=${merchant?.business_type}&currency_code=${merchant.currency}&merchant_id=${merchant.id}`
		)
			.then((res) => {
				setLoading(false);
				if (res.status === 200) {
					setAllProducts(res.data.messages[0].attachment?.payload.elements);
				}
			})
			.catch((err) => {
				setLoading(false);
				message.error(err.response?.data.message || err.message);
			});
	};

	const getUrewardsId = (merchant) => {
		let string = "";
		for (let letter of merchant.contact_number) {
			string = isNaN(Number(letter)) ? string : (string += letter);
		}
		return string;
	};

	useEffect(() => {
		getAllProMerchants();
	}, []);

	if (redirect) return <Redirect push to={redirect} />;
	return (
		<>
			<Header
				titleComponent={
					<h2>
						<b>Rewards</b>
					</h2>
				}
				openLoginModal={() => setSignInModalVisible(true)}
			/>
			{signInModalVisible && (
				<SigninModal
					visible={signInModalVisible}
					onCancel={() => setSignInModalVisible(false)}
				/>
			)}
			<div className="container_main">
				<div className="flex_center_column ">
					<div className="user_points">
						<div>
							<img src={UserAvatar} alt="large" />
						</div>
						<div className="points">
							<p>Your Points Achievement</p>
							<h2 className="title_text">0 Points</h2>
						</div>
					</div>
					<div className="purchase_value">
						<div className="purchase">
							<p>Total Purchase Value</p>
							<p>
								<MoneyIcon />0
							</p>
						</div>
						<div className="divider" />
						<div className="membership">
							<p>Membership Programs</p>
							<p>
								<MovieTicketWhite />0
							</p>
						</div>
					</div>
					<div className="redeem_points">
						<div>
							<p>How to redeem your points...</p>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.sed do
								eiusmod tempor
							</p>
						</div>
						<div>
							<Button
								type="primary"
								style={{ background: "#6b6a6a", height: 50 }}>
								Learn More
							</Button>
						</div>
					</div>
					<div className="browse_offer">
						<div>
							<Button
								type="primary"
								style={{ background: "#6b6a6a", height: 50 }}>
								Browse Offers
							</Button>
						</div>

						<div
							className="banner_text img_banner"
							style={{
								width: "100%",
								maxWidth: "450px",
								margin: "auto",
								marginTop: 30,
								background: `center / cover no-repeat  url(${MerchantSpotlight})`,
							}}>
							<div>
								<p
									style={{
										fontWeight: 600,
										fontSize: "28px",
										color: "#fff",
										paddingBottom: 0,
									}}>
									Merchant Spotlight
								</p>
								<p style={{ color: "#fff", paddingBottom: 0 }}>
									Our Merchant for today is{" "}
									<b>{merchantSpotlight?.business_name}</b>
								</p>
							</div>
							<div className="button_div">
								<Button
									onClick={() =>
										setRedirect(`/${getUrewardsId(merchantSpotlight)}`)
									}
									type="primary">
									Browse Products
								</Button>
							</div>
						</div>
					</div>

					<div className="top_deals">
						<div>
							<p>
								<b>Trending Deals</b>
							</p>
							<h2 className="title_text">Our Top Ten...</h2>
						</div>

						<div className="products_div">
							{allProducts.map((product) => (
								<Col span={18} key={product.title}>
									<div
										className="flex_center_column"
										style={{
											background: product.image_url
												? `url(${product.image_url})`
												: "#C4C4C4",
											height: "280px",
											margin: "20px 10px",
											justifyContent: "space-between",
										}}>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-end",
												padding: 20,
												width: "100%",
											}}>
											<img src={LoveIcon} alt="favourite" />
										</div>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "space-between",
												height: "120px",
												background: "#6B6A6A",
												width: "100%",
												padding: "20px",
											}}>
											<p style={{ float: "left", color: "#fff" }}>
												<b>{product.title}</b>
											</p>
											<p
												style={{
													float: "right",
													color: "#fff",
													textAlign: "right",
												}}>
												<Button type="link">
													<b>Add To Cart</b>
												</Button>
											</p>
										</div>
									</div>
								</Col>
							))}
						</div>
					</div>
				</div>
			</div>
			<Footer active="rewards" />
		</>
	);
}

export default Rewards;
