import React, { useEffect, useState } from "react";
import { Button, Col, Row, message, Modal, Card } from "antd";



const ConfirmModal = ({open, onCancel, onOk, body, loading}) => {
  return (
    <>
        <Modal
            open={open}
            onCancel={onCancel}
            className="sure_modal" 
            onOk={onOk}
            footer={[
              <div key={"1"}>
                <Button
                  onClick={onCancel}
                >
                  cancel
                </Button>
                <Button 
                  loading={loading}
                  onClick={onOk}
                >
                  ok
                </Button>
              </div>
            ]}
        >
                <h4>
                    {body}
                </h4>
		</Modal>
    </>
  )
}

export default ConfirmModal