import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, InputNumber } from 'antd';
import { saveCustomersBvn } from '../../../redux/actions/credpal';
import "./index.scss";

const FormItem = Form.Item;

const BVNModal = ({ 
    open, 
    onCancel, 
    phone_number, 
    email, 
    userBvn,
    verifyPhone,
    verifyPhoneLoad 
}) => {
    const [ phoneNumber, setPhoneNumber ] = useState(phone_number);
    const [ BVN, setBVN ] = useState(null);
    const [ credPalLoading, setCredPalLoading ] = useState(false);

    useEffect(()=>{
        setPhoneNumber(phone_number);
        userBvn && setBVN(userBvn);
    }, [phone_number]);

    const verifyPhoneNumber = () => {
        const data = {
            bvn: BVN,
            phone_number: phoneNumber
        }
        const newData = {
            email,
            data
        }
        setCredPalLoading(true);
        saveCustomersBvn(newData)
        .then((res)=>{
            Modal.success({
                title: res.data.message,
                onOk: () => {
                    const userDetails = JSON.parse(localStorage.getItem("user_customer_details"));
                    const newUserChange = {...userDetails, BVN: BVN}
                    localStorage.setItem("user_customer_details", JSON.stringify(newUserChange));
                    setCredPalLoading(false);
                    onCancel();
                }
            })
            setCredPalLoading(false);
        })
        .catch((err)=>{
            Modal.error({
                title: err.response.data,
                onOk: () => setCredPalLoading(false)
            })
        })
        
    }
  return (
    <Modal
        title="Apply for Credit"
        open={open}
        onCancel={onCancel}
        footer={null}
    >
        <div className="md_2">
            <div>
                <Form
                    onFinish={verifyPhoneNumber}
                    autoComplete="off"
                >
                    <FormItem
                        label="Phone Number"
                        name="phone_number"
                        rules={[{ required: false, message: 'Please input your phone number!' }]}
                    >
                        <InputNumber
                            inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
                            style={{
                                border: "1px solid #d9d9d9",
                                width: "100%",
                                height: "40px",
                            }}
                            placeholder={phoneNumber}
                            value={phoneNumber}
                            onChange={(value)=>setPhoneNumber(value)}
                            disabled={true}
                        />
                    </FormItem>
                    <FormItem  
                        label="BVN"
                        name="BVN"
                        rules={[{required: true, message: "Please input your BVN"}]}
                    >
                        <InputNumber 
                            value={BVN}
                            onChange={(value)=>setBVN(value)}
                            placeholder={userBvn ? userBvn : "Please Enter your BVN"}
                            style={{
                                width: "100%",
                            }}
                        />
                    </FormItem>
                    <FormItem className='btn-div'>
                        <Button 
                            loading={credPalLoading}
                            type="primary"
                            // onClick={verifyPhoneNumber}
                            htmlType='submit'
                        >
                           Update BVN
                        </Button>
                    </FormItem>
                </Form>
                <div className='btn-div1'>
                    <Button 
                        loading={verifyPhoneLoad}
                        type="primary"
                        onClick={(e) => {
                            e.stopPropagation();
                            verifyPhone();
                        }}
                        htmlType='submit'
                    >
                        Check Approval status
                    </Button>
                </div>
            </div>
        </div>
    </Modal>
  )
}

export default BVNModal;