import * as Types from "../actions/types";
let isBranch = localStorage.getItem("branchdetails");
let branch = isBranch ? JSON.parse(isBranch) : null;

const initialstate = {
    isbranchSet: branch?.address_line1 ? true : false,
    branch_address1: branch ? branch.address_line1 : null,
    branch_address2: branch ? branch.address_line2 : null,
    branch_id: branch ? branch.branch_id : null,
    latitude: branch ? branch.latitude : null,
    longitude: branch ? branch.longitude : null
}

export const branchReducer = ( state = initialstate, { type, payload }) => {
    switch (type){
        case Types.SETBRANCH: {
            state.isbranchSet = payload.isbranchSet;
            state.branch_address1 = payload.address_line1;
            state.branch_address2 = payload.address_line2;
            state.branch_id = payload.branch_id;
            state.latitude = payload.latitude;
            state.longitude = payload.longitude;
            return state;
        }
        default: {
			return {
				...state,
			};
		}
    }
}