import React, { useState, useEffect } from "react";
import Header from "../../reusables/Header";
import { Button, Card, Carousel, Col, message, Row, Spin } from "antd";
import {
	HomePic1,
	HomePic2,
	HomePic3,
	HomePic4,
	HomePic5,
	HomePic6,
	QrCode,
} from "../../assets/icons";
import styled from "styled-components";
import "./home.scss";
import { PlayCircleOutlined } from "@ant-design/icons";
import SignupModal from "./partials/SignupModal";
import Footer from "../../reusables/Footer";
import { useQuery } from "@apollo/client";
import { Link, Redirect, useHistory } from "react-router-dom";
import { LIST_PARTNERS } from "../../constants";
import { Helmet } from "react-helmet";
import { getCountryCode } from "../../redux/actions/general";

const picArr = [HomePic1, HomePic2, HomePic3, HomePic4, HomePic5, HomePic6];

function HomePage() {

	// window.location = "https://loystar.co/shop"

	// return <Spin size="large"/>
	const history = useHistory();
	const [showLoginModal, setShowLoginModal] = useState(false);
	const [ loading, setLoading ] = useState(false);
	const [ redirect, setRedirect ] = useState(null);
	const [ currentArea, setcurrentArea ] = useState(null);
	const baseUrl = process.env.REACT_APP_CUSTOMER_REDIRECT_BASE_URL;
	const redirecTo = process.env.REACT_APP_REDIRCT_TO


	const { data: listPartnersData } = useQuery(LIST_PARTNERS, {
		onError: (error) => {
			message.error(error.message);
		},
	});

	useEffect(()=>{
		window.location = "https://loystar.co/shop";
		// handleGetCurrentLocation();
	}, []);
	const isUrlOrigin = window.location.href === "https://thecualashop.com/home" || window.location.href === "www.thecualashop.com/home" || window.location.href === "https://www.thecualashop.com/home";

	const handleGetCurrentLocation = () => {
		getCountryCode()
		.then(res => {
		  if(res.status === 200){
			const country = res.data;
			console.log(res.data, isUrlOrigin)
			setcurrentArea(country);
			if(isUrlOrigin && country.country_name === "Nigeria"){
				return window.location = "https://thecualashop.com/cuala.ng";
			}
			
			if(isUrlOrigin && country.country_name === "United Kingdom"){
				return window.location = "https://thecualashop.com/cuala.uk";
			}
			
			return window.location = "https://loystar.co/shop";
		  }
		  window.location = "https://loystar.co/shop";
		})
		.catch(err => {
		  console.log("err", err);
		  
		})
	  }

	useEffect(()=>{
        const body = document.querySelector("body");
        body.style.background = "#000";
    }, [])

	const listPartners = listPartnersData?.listPartners || [];

	const homeTitle = (
		<>
			<p style={{ fontWeight: 400, fontSize: "10px" }}>
				{new Date().toDateString()}
			</p>
			<h2 style={{ fontWeight: 600, fontSize: "28px" }}>TODAY</h2>
		</>
	);

	if (redirect) return <Redirect push to={redirect} />;
	return (
		<>
			<>
			<Helmet>
			<title>uRewards</title>
			</Helmet>
				<Header
					openLoginModal={() => setShowLoginModal(true)}
					titleComponent={homeTitle}
				/>
				<Card loading={loading} style={{background: "#000", border:"#000"}}>
					<div className="container_main">

						<Row className="fade">
							<Col md={12} sm={24}>
								<Carousel dots={false}>
									{picArr.map((pic) => (
										<CarouselItem key={Math.random()}>
											<div
												style={{
													backgroundImage: `url(${pic})`,
													height: "100%",
												}}
												className="banner_text img_banner">
												<p
													style={{
														fontWeight: 600,
														fontSize: "28px",
														marginTop: 20,
													}}>
													Say Hello 👋
													<span style={{ fontSize: "38px" }}>uRewards</span>{" "}
													membership <br /> We have rewards for everyone.
												</p>
												<div className="button_div">
													<Button type="primary">See More</Button>
												</div>
											</div>
										</CarouselItem>
									))}
								</Carousel>
							</Col>
							<Col md={12} sm={24}>
								<div className="banner_details flex_center_column">
									<h3 className="title_text">
										Discover local brands and earn rewards when you spend
									</h3>
									<p style={{ color: "#6B6A6A", marginTop: 10 }}>
										uRewards is a membership program where you can collect
										benefits and life changing rewards when you patronize
										businesses in your local area.
									</p>
								</div>
							</Col>
						</Row>

						<div className="whats_trending fade">
							<div className="trending_details">
								<h3 className="title_text"> EXCLUSIVES DEALS</h3>
								<p style={{ color: "#6B6A6A", marginTop: 10 }}>
									Discover exclusive deals handpicked for you today, at the best
									prices!
								</p>
							</div>
							<div className="carousel_cont">
								<Carousel autoplay dots={false}>
								{listPartners.map((partner, index) => (
										<CarouselItem key={index}>
											<div
												style={{
													backgroundImage: `url(${partner?.banner || picArr[index]})`,
													height: "100%",
													marginLeft: "10%",
												}}
												className="banner_text img_banner">
												<p style={{ fontWeight: 600, fontSize: "28px" }}>
													{partner?.company_name}
												</p>
												<div
													className="button_div"
													onClick={() => setRedirect("/discover")}>
													<Button type="primary">Claim Now</Button>
												</div>
											</div>
										</CarouselItem>
									))}
								</Carousel>
							</div>
						</div>

						<Row style={{ marginTop: 60 }} className="fade">
							<Col md={12} sm={24}>
								<div
									className="banner_text img_banner"
									style={{
										backgroundImage: `url(${HomePic3})`,
										marginRight: "10%",
									}}>
									<div>
										<p
											style={{
												fontWeight: 600,
												fontSize: "28px",
												color: "#000",
											}}>
											Fast checkout experience. <br />
											See how it works.
										</p>
										<p style={{ color: "#6B6A6A", marginTop: 10 }}>Click Play</p>
									</div>
									<div className="button_div">
										<Button type="primary">
											<PlayCircleOutlined />
										</Button>
									</div>
								</div>
							</Col>

							<Col span={24} md={12}>
								<div
									className="banner_text counter_div flex_center"
									style={{
										height: "100%",
									}}>
									<div
										style={{
											background: "#6B6A6A",
											padding: "10px",
											borderRadius: 10,
										}}>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
											}}>
											<div>
												<p
													style={{
														fontWeight: 600,
														fontSize: "16px",
														padding: 0,
														marginLeft: 20,
													}}>
													Standing at a Counter?
												</p>
												<p
													style={{
														color: "#fff",
														marginTop: 10,
														fontSize: "10px",
													}}>
													Check-in, send your order and save time
												</p>
											</div>
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Button type="primary" style={{ marginRight: 10 }}>
													<Link to="/check-in">
														<img src={QrCode} alt="qr code" />
														Check In
													</Link>
												</Button>
											</div>
										</div>
									</div>
								</div>
							</Col>

							{/* <Col md={12} sm={24}>
								<div
									className="banner_text img_banner"
									style={{
										backgroundImage: `url(${HomePic4})`,
									}}>
									<div>
										<p
											style={{
												fontWeight: 600,
												fontSize: "28px",
												color: "#000",
												paddingBottom: 0,
											}}>
											All Your Rewards Points in one place. Redeem when you like.
										</p>
										<p style={{ color: "#6B6A6A", paddingBottom: 0 }}>
											Spend your points anytime across our network of business and
											brands.
										</p>
									</div>
									<div className="button_div">
										<Button type="primary">Spend Your Points</Button>
									</div>
								</div>
							</Col> */}
						</Row>

					{/* <Row style={{ marginTop: 60 }} className="fade">
						<Col md={12} sm={24}>
							<div
								className="banner_text img_banner"
								style={{
									backgroundImage: `url(${HomePic5})`,
									marginRight: "10%",
								}}>
								<div>
									<p
										style={{
											fontWeight: 600,
											fontSize: "28px",
											paddingBottom: 0,
										}}>
										Win Life changing Rewards with Cash token
									</p>
									<p style={{ color: "#6B6A6A", paddingBottom: 0 }}>
										Win big at participating merchants, automatically earn
										cashback and enter into a weekly draw to win a life changing
										cash prize with cash token
									</p>
								</div>
								<div className="button_div">
									<Button type="primary">
										<img src={QrCode} alt="qr code" />
										See Vendors
									</Button>
								</div>
							</div>
						</Col>


					</Row> */}
					<div
						style={{ height: 80, borderBottom: "1px solid #C4C4C4" }}
						className="fade"></div>

					<div style={{ margin: 30 }} className="fade">
						<a href="/">Terms and Conditions</a>
					</div>
				</div>
				</Card>
				
			</>

			<Footer active="home" />

			{showLoginModal && (
				<SignupModal
					visible={showLoginModal}
					onCancel={() => setShowLoginModal(false)}
				/>
			)}
		</>
	);
}

export default HomePage;

const CarouselItem = styled.div`
	height: 343px;

	img {
		width: 400px;
		height: 343px;
		top: 0;
		z-index: -1;
		left: 0;
	}

	p {
		text-align: left;
		z-index: 2;
	}
	button {
		float: right;
		z-index: 2;
	}
`;
