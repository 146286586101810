import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Spin, message } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import { Compass, GiftCard1, LogoBlack } from "../../../assets/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  CONFIRM_CUSTOMER,
  CONFIRM_CUSTOMER_AND_CARD,
  CONFIRM_GIFT_CARD_ID
} from "../../../constants";
import { useLazyQuery } from "@apollo/client";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  giftCardAction,
  isOTPVerified,
} from "../../../redux/actions/ussdActions";
import { useParams } from "react-router-dom";

const PopUpModal = ({ organisation, open, onCancel }) => {
  const { card_id, phone_number } = useParams();
  const dispatch = useDispatch();
  const [confirmCustomer, { data, loading, error, refetch }] =
    useLazyQuery(CONFIRM_CUSTOMER);
  const [
    confirmCustomerAndCard,
    {
      data: confirmData,
      loading: confirmLoading,
      error: confitmerror,
      refetch: confirmRefetch,
    },
  ] = useLazyQuery(CONFIRM_CUSTOMER_AND_CARD);
  const [
    confirmGiftCardID,
    {
      data: confirmIdData,
      loading: confirmIdLoading,
      error: confirmIdError,
      refetch: confirmIdRefetch,
    },
  ] = useLazyQuery(CONFIRM_GIFT_CARD_ID);
  const [value, setValue] = useState(null);
  const [showOTP, setShowOTP] = useState(false);
  const [messages, setMessage] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [OTP, setOTP] = useState(null);
  const [loadingProcess, setLoadingProcess] = useState(false);
  const [loadingPro, setLoadingPro] = useState(false);
  const [danger, setDanger] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(false);

  const handleKeyPress = (value) => {
    if (value === "") return message.warn("Please Enter Phone Number");
    if (card_id === "") return;
    setValue("+" + value);
    if (value?.length > 6) {
      const newvalue = "+" + value;
      // console.log(newvalue);
      const phoneNum = newvalue.toString();
      // "44af9b10-c7f0-42e3-904d-16d850467e45"
      if (phoneNum.length > 6) {
        if (data && !loading) {
          confirmRefetch({ phone_number: phoneNum.toString(), id: card_id });
          return refetch({ phone_number: phoneNum.toString() });
        }
        // confirmCustomer( {
        //     variables : { phone_number: phoneNum.toString() },
        //     onCompleted: (data) =>{
        //         if(data?.confirmCustomer?.message === "Success"){

        //         }
        //     }
        // })
        setLoadingProcess(true);
        confirmCustomerAndCard({
          variables: { phone_number: phoneNum.toString(), id: card_id },
          onCompleted: (data) => {
            if (data.confirmCustomerAndGiftCard?.message === "Success") {
              console.log(data.confirmCustomerAndGiftCard);
              setLoadingProcess(false);
              const giftcard = data.confirmCustomerAndGiftCard?.giftCard;
              const userCheck = localStorage.getItem("user_customer_details");
              if (!userCheck) {
                localStorage.setItem(
                  "user_customer_details",
                  JSON.stringify(data.confirmCustomerAndGiftCard?.user)
                );
              } else {
                const theOldUser = JSON.parse(userCheck);
                const userFromCard = data.confirmCustomerAndGiftCard?.user;
                if (theOldUser.phone_number !== userFromCard.phone_number) {
                  localStorage.setItem(
                    "user_customer_details",
                    JSON.stringify(data.confirmCustomerAndGiftCard?.user)
                  );
                } else {
                  localStorage.setItem(
                    "user_customer_details",
                    JSON.stringify(theOldUser)
                  );
                }
              }
              generateOTP(newvalue);
              dispatch(giftCardAction(giftcard));
              setShowMessage(false);
              setDanger(false);
            } else if (data.confirmCustomerAndGiftCard?.message === "failure") {
              setMessage("User with this number does not exist");
              setDanger(true);
              setShowMessage(true);
              setLoadingProcess(false);
            }
          },
          onError: (error) => {
            setMessage(error.message);
            setDanger(true);
            setShowMessage(true);
            setLoadingProcess(false);
          },
        });
      }
    }
  };

  const handleClaimGiftCardClick = async () => {
    if (!phone_number) {
      setLoadingProcess(true);
      await confirmGiftCardID({
        variables: {
          id: card_id
        },
        onCompleted: (data) => {
          if (data.confirmGiftCardID?.message === "Success") {
            console.log(data.confirmGiftCardID);
            setLoadingProcess(false);
            const giftcard = data.confirmGiftCardID?.giftCard;
            const userCheck = localStorage.getItem("user_customer_details");
            if (!userCheck || userCheck === undefined) {
              localStorage.setItem(
                "user_customer_details",
                JSON.stringify(data.confirmGiftCardID?.user)
              );
            } else {
              const theOldUser = JSON.parse(userCheck);
              const userFromCard = data.confirmGiftCardID?.user;
              if (theOldUser.phone_number !== userFromCard.phone_number) {
                localStorage.setItem(
                  "user_customer_details",
                  JSON.stringify(data.confirmGiftCardID?.user)
                );
              } else {
                localStorage.setItem(
                  "user_customer_details",
                  JSON.stringify(theOldUser)
                );
                console.log(3);
              }
            }
            const phone_num = data.confirmGiftCardID?.user?.phone_number;
            setPhoneNumber(phone_num);
            generateOTP(phone_num);
            dispatch(giftCardAction(giftcard));
            setShowMessage(false);
            setDanger(false);
          } else if (data.confirmGiftCardID?.message === "failure") {
            setMessage("User with this number does not exist");
            setDanger(true);
            setShowMessage(true);
            setLoadingProcess(false);
          }
        },
        onError: (error) => {
          setMessage(error.message);
          setDanger(true);
          setShowMessage(true);
          setLoadingProcess(false);
        },
      });
      // Modal.error({
      //   title: "Error",
      //   content: "URL not structured properly! Phone number missing.",
      // });
      // return;
    }else{
      setLoadingProcess(true);
      await confirmCustomerAndCard({
        variables: {
          phone_number: phone_number,
          id: card_id,
        },
        onCompleted: (data) => {
          if (data.confirmCustomerAndGiftCard?.message === "Success") {
            console.log(data.confirmCustomerAndGiftCard);
            setLoadingProcess(false);
            const giftcard = data.confirmCustomerAndGiftCard?.giftCard;
            const userCheck = localStorage.getItem("user_customer_details");
            if (!userCheck || userCheck === undefined) {
              localStorage.setItem(
                "user_customer_details",
                JSON.stringify(data.confirmCustomerAndGiftCard?.user)
              );
            } else {
              const theOldUser = JSON.parse(userCheck);
              const userFromCard = data.confirmCustomerAndGiftCard?.user;
              if (theOldUser.phone_number !== userFromCard.phone_number) {
                localStorage.setItem(
                  "user_customer_details",
                  JSON.stringify(data.confirmCustomerAndGiftCard?.user)
                );
              } else {
                localStorage.setItem(
                  "user_customer_details",
                  JSON.stringify(theOldUser)
                );
              }
            }
            generateOTP(phone_number);
            dispatch(giftCardAction(giftcard));
            setShowMessage(false);
            setDanger(false);
          } else if (data.confirmCustomerAndGiftCard?.message === "failure") {
            setMessage("User with this number does not exist");
            setDanger(true);
            setShowMessage(true);
            setLoadingProcess(false);
          }
        },
        onError: (error) => {
          setMessage(error.message);
          setDanger(true);
          setShowMessage(true);
          setLoadingProcess(false);
        },
      });
    }
  };

  // useEffect(()=>{

  // }, [isO])
  const generateOTP = (value) => {
    setLoadingProcess(true);
    const formData = new FormData();
    formData.append("phone_number", value);

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/v2/generate_phone_verification_token`,
        formData
      )
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setLoadingProcess(false);
          const message = "An one time code as been sent. Pls enter below";
          setShowOTP(true);
          setMessage(message);
          setShowMessage(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleConfirmOTP = () => {
    setLoadingPro(true);
    if (OTP === "") {
      return message.warn("Please Input OTP to verify");
    }
    const formData = new FormData();
    formData.append("phone_number", phone_number || phoneNumber);
    formData.append("code", OTP);
    // https://loystar-api-stage.herokuapp.com/api/v2/sales

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/v2/verify_merchant_phone_number`,
        formData
      )
      .then((res) => {
        if (res.status === 200) {
          setLoadingPro(false);
          setMessage(res.data.message);
          setShowMessage(true);
          message.success(res.data.message);
          setTimeout(() => {
            onCancel();
          }, 3000);
          dispatch(isOTPVerified());
        }
      })
      .catch((err) => {
        setLoadingPro(false);
        setMessage("Invalid OTP");
        // console.log(err);
      });
  };

  return (
    <Modal
      open={open}
      footer={null}
      className="login_modal"
      onCancel={onCancel}
      style={{ marginTop: "-10%" }}
    >
      <div className="popupmodal_">
        <div className="popupcontent">
          <img
            src={organisation?.organisation_logo || LogoBlack}
            alt={organisation?.organisation_name}
            height={60}
            width={60}
          />
        </div>

        <div className="popupcontent">
          <h2 style={{ backgroundColor: "#fff" }}>
            {organisation?.organisation_name}
          </h2>
        </div>

        <div className="popupcontent">
          <h3>👋🏽 Welcome</h3>
        </div>

        <div
          className="popupcontent"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1.5rem",
          }}
        >
          <span>
            <img src={GiftCard1} alt="compass" />
          </span>
          <p style={{ color: "#000" }}>
            Hi, you have been awarded a Gift voucher from{" "}
            {organisation?.organisation_name}. Please confirm your phone number
            to redeem
          </p>
        </div>
        <div>
          <div
            className="popupcontent"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {/* <Input
                        prefix={<PhoneOutlined />}
                        placeholder="Your Mobile Number"
                        type="tel"
                        onKeyPress={(e)=>{
                            console.log(e.target.value)
                        }}
                    /> */}
            {/* <PhoneInput
                        inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
                        inputStyle={{
                            border: "0.5px solid #6B6A6A",
                            height: "40px",
                            width: "100%",
                            borderRadius: 2,
                        }}
                        country="ng"
                        prefix="+"
                        value={value}
                        onChange={(value)=>{
                            handleKeyPress(value);
                        }}
                    /> */}
            {/* {loadingProcess && <Spin/>} */}
            <Button
              type="primary"
              className="popBtn"
              onClick={handleClaimGiftCardClick}
              loading={loadingProcess}
            >
              Claim Gift Card
            </Button>
          </div>
          {showMessage && (
            <span style={{ color: danger ? "red" : "#000" }}>{messages}</span>
          )}
        </div>
        {showOTP && (
          <div className="popupcontent">
            <Input onChange={(e) => setOTP(e.target.value)} />
            <Button
              type="primary"
              className="popBtn"
              onClick={() => handleConfirmOTP()}
              loading={loadingPro}
            >
              Verify
            </Button>
          </div>
        )}
        {/* {showOTP && <div style={{textAlign: "right", marginTop: "-2rem"}}> <span style={{fontSize: "12px"}}>Did not receive OTP, <span style={{color: "red", cursor: "pointer"}}>send mail</span></span></div>} */}
      </div>
    </Modal>
  );
};

export default PopUpModal;
