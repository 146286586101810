import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import "./index.scss";
import Header from '../../reusables/Header';
import Footer from '../../reusables/Footer';
import { 
    BackgroundCredpal, 
    CredpalCard, 
    CredPalLogo, 
    Penumbra, 
    Umbra, 
    Mediumcard, 
    CredPalLogo2,
    Bluerec,
    Layer1,
    Layer2,
    Layer3,
    Layer4,
    Layer5,
    Layer6,
    Layer7,
    Layer8, 
    Lastcard,
    Phonehand, 
} from '../../assets/images';
import { Image, Button, Modal } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { LogoBlack } from '../../assets/icons';
import { initiateTransaction } from '../../redux/actions/credpal';
import OtpInputModal from './otpinput';
import BVNModal from './bvninput';

const CredpalPage = () => {
    const [merchant, setMerchant ] = useState(null);
    const [ phoneNumber, setPhoneNumber ] = useState(null);
    const [ userBvn, setUserBvn ] = useState(null);
    const [ email, setEmail ] = useState(null);
    const [ userDetails, setUserDetails ] = useState(null);
    const [merchantUrl, setMerchantUrl] = useState(null);
    const [ credPalLoading, setCredPalLoading ] = useState(false);
    const [ token, setToken ] = useState(null);
    const [ openOTPModal, setOpenOtpModal ] = useState(false);
    const [ openBvnModal, setOpenBvnModal ] = useState(false);
    const [ OTP, setOTP ] = useState([]);
    const [ paymentSession, setPaymentSession ] = useState({
        available_credit: null,
        sessionId: null,
        token: null
    });


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user_customer_details"));
        const merchant = JSON.parse(localStorage.getItem("urewards_merchant_details"));
        const isMerchantUrl = localStorage.getItem("urewards_merchant_url");

        user && setPhoneNumber(user.phone_number);
        user && setUserBvn(() => {
            return user.BVN ? user.BVN : null
        });
        user && setUserDetails(user);
        merchant && setEmail(merchant.email)
        merchant && setMerchant(merchant);
        isMerchantUrl && setMerchantUrl(JSON.parse(isMerchantUrl));
    }, []);

    const verifyPhoneNumber = () => {
        const phone_number = phoneNumber;
        
        setCredPalLoading(true);
        initiateTransaction({email, phone_number})
        .then((res)=>{
            if(res.status === false ){
                return Modal.error({
                    title: res.data?.errors?.bvn[0] || res.data?.message || "Unable to verify approval status, please try again",
                    onOk: () => {
                        setCredPalLoading(false);
                        window.location.href="#apply_now"
                    }
                });
            }else{
                setPaymentSession({
                    available_credit: res.data.data.available_credit,
                    sessionId: res.data.data.sessionId,
                    token: res.data.data.token
                });

                setToken(res.data.data.token);
                setCredPalLoading(false);
                setOpenOtpModal(true);
            }
        })
        .catch((err)=>{
            Modal.error({
                title: err?.response?.data || "Unable to verify approval status, please try again" ,
                onOk: () => {
                    setCredPalLoading(false);
                    window.location.href="#apply_now"
                }
            });
        })
        
    };

    const verifyOTP = () => {
        const value = OTP.toString().split(",").join("");
        if(value.length !== 6){
            Modal.error({
                title: "OTP must be six letter",
                onOk: () => setCredPalLoading(false)
            })
        }else if(value !== token){
            Modal.error({
                title: "Wrong otp input",
                onOk: () =>setCredPalLoading(false)
            });
        }else{
            if(paymentSession.available_credit <= 0) return Modal.success({
                title: "OTP verification successful, but you don't have enough credit!",
                onOk: () => {
                    setCredPalLoading(false);
                    setOpenOtpModal(false);
                }
            })
            Modal.success({
                title: "OTP verification successful, you can now checkout with credits!",
                onOk: () => {
                    setCredPalLoading(false);
                    setOpenOtpModal(false);
                    window.location.href = merchantUrl
                }
            })
        }
    };

    const handleOTPInput = (value) =>{
        setOTP((prev)=>{
            return [...prev, ...value.filter(val=>val !== undefined)]
        });
    };

  return (
    <>
        <Helmet>
            <meta
                name="description"
                content={
                    merchant?.description ||
                    `Browse products and Services from ${merchant?.business_name || `loystar shop`}, make a purchase and earn rewards 🎁`
                }
                />
                <link rel="shortcut icon" id="favicon" href={merchant?.cover_image || LogoBlack} />
                <title>{merchant?.business_name || "Loystar Shop Cart"}</title>
                <link rel="icon" id="icon-fav" href={merchant?.cover_image || LogoBlack} />
        </Helmet>
        <Header
            merchantee
            // merchantLink={merchantUrl}
        />
        <div className='container_main'>
            <div className='flex_center_column crepal'>
                <div className="layer1" style={{background: `url(${BackgroundCredpal}) center center/cover no-repeat`}}>
                    <div className="textdisplay">
                        <Image  src={CredPalLogo} preview={false} alt='credpal card logo' />
                        <h5>Earn 5% cashback on every purchase</h5>
                        <p>with no annual fee**</p>
                        <p className='check'>Check if you're pre-approved with no risk to your credit score</p>
                        <Button 
                            type='primary'
                            onClick={() =>  setOpenBvnModal(true)}
                            loading={credPalLoading}
                        >
                            Check my approval status
                        </Button>
                    </div>
                    <div className="cardImage">
                        <Image  src={CredpalCard} preview={false} alt='credpal card image'/>
                    </div>
                </div>
                <div className="layer2">
                    <a>Apply in a Snap: No Pre-Approval Needed!</a>
                </div>
                <div className="layer3">
                    <div className="left"></div>
                    <div className="right"></div>
                </div>
                <div className="layer4">
                    <h5>Smart Shopping Rewards: Cash Back on Everything, Every Time!</h5>
                    <div className="percentage">
                        <div className="percentdetails">
                            <div className="top">
                                <p className='number'>5</p>
                                <div className="cashpercent">
                                    <span>%</span>
                                    <span>cash back</span>
                                </div>
                            </div>
                            <hr />
                            <p className='each'>Each time you exceed NGN10,000</p>
                        </div>
                        <div className="percentdetails">
                            <div className="top">
                                <p className='number'>2</p>
                                <div className="cashpercent">
                                    <span>%</span>
                                    <span>cash back</span>
                                </div>
                            </div>
                            <hr />
                            <p className='each'>on our currated fashion picks</p>
                        </div>
                        <div className="percentdetails">
                            <div className="top">
                                <p className='number'>1</p>
                                <div className="cashpercent">
                                    <span>%</span>
                                    <span>cash back</span>
                                </div>
                            </div>
                            <hr />
                            <p className='each'>on all accessories you add to your cart</p>
                        </div>
                        
                    </div>
                </div>
                <div className="layer5">
                    <div className="view">
                        <div className="viewleft">
                            <Image  src={Mediumcard} preview={false}  alt='credpal card image'/>
                        </div>
                        <div className="viewright">
                            <Image  src={CredPalLogo2} preview={false} alt='credpal card image'/>
                            <p className='first'>Start earning cash backs on all your purchases from day one upon approval</p>
                            <p className='sec'>Check your eligibility</p>
                            <h5>Zero impact on your credit score</h5>
                            
                            <Button 
                                type='primary'
                                onClick={() => setOpenBvnModal(true)}
                                loading={credPalLoading}
                            >
                                Check my approval status
                            </Button>

                            <a>Skip preapproval, and Apply Now</a>
                            <h6>Refer to the Terms & Conditions below. By clicking "Apply now", you acknowledge and agree to Credpal's Privacy Policy and Terms of Use.</h6>
                        </div>
                    </div>
                    <div className="cardImage1">
                        <Image  src={Penumbra} preview={false} alt='credpal card image'/>
                    </div>
                    <div className="cardImage2">
                        <Image  src={Umbra} preview={false} alt='credpal card image'/>
                    </div>
                </div>
                <div className="layer6">
                    <div className="left">
                        <div className="blueimgback" style={{background: `url(${Bluerec}) center center/cover no-repeat`}}>
                            <p className="five">5</p>
                            <div className="percbac">
                                <span>%</span>
                                <span><strong>cash back</strong> with CredPal Pay</span>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <h4>Introductory offer</h4>
                        <p>Earn 5% cash back for the first 12 months when you use CredPal Pay  - the quick and secure way to pay with your phone</p>
                    </div>
                </div>
                <div className="layer7">
                    <h3>Get the most from your rewards</h3>
                    <div className="gridlayout">
                        <div className="griditem">
                            <Image  src={Layer1} preview={false}  alt='credpal card image'/>
                            <hr />
                            <h5>Cash Reward</h5>
                            <p>Apply rewards as a credit on your statement for added savings</p>
                        </div>
                        <div className="griditem">
                            <Image  src={Layer2} preview={false}  alt='credpal card image'/>
                            <hr />
                            <h5>Purchase Power</h5>
                            <p>Redeem rewards instantly during checkout</p>
                        </div>
                        <div className="griditem">
                            <Image  src={Layer3} preview={false}  alt='credpal card image'/>
                            <hr />
                            <h5>Redeem for Merchandise</h5>
                            <p>Choose from a variety of products available.</p>
                        </div>
                        <div className="griditem">
                            <Image  src={Layer4} preview={false}  alt='credpal card image'/>
                            <hr />
                            <h5>Enhanced Travel</h5>
                            <p>Enjoy exclusive travel perks, upgrades, and access to airport and lounges.</p>
                        </div>
                        <div className="griditem">
                            <Image  src={Layer5} preview={false}  alt='credpal card image'/>
                            <hr />
                            <h5>Discounts on Partner Services</h5>
                            <p>Unlock special discounts or offers from our partner brands and services.</p>
                        </div>
                        <div className="griditem">
                            <Image  src={Layer6} preview={false}  alt='credpal card image'/>
                            <hr />
                            <h5>Flexible Redemption Options</h5>
                            <p>Tailor your redemption to suit your preferences and lifestyle.</p>
                        </div>
                        <div className="griditem">
                            <Image  src={Layer7} preview={false}  alt='credpal card image'/>
                            <hr />
                            <h5>Exclusive Events</h5>
                            <p>Gain access to members-only events and experiences as a token of appreciation.</p>
                        </div>
                        <div className="griditem">
                            <Image  src={Layer8} preview={false}  alt='credpal card image'/>
                            <hr />
                            <h5>Special Promotions</h5>
                            <p>Take advantage of limited-time promotions and bonus reward opportunities</p>
                        </div>
                    </div>
                </div>
                <div className="layer8">
                    <h3>Discover Our Cardholder Perks</h3>
                    <div className="gridlayout">
                        <div className="griditem">   
                            <CheckOutlined style={{color: "#2565B0", fontSize: "15px"}} />
                            <h4>Security Alerts</h4>
                            <p>Receive instant notifications via text, email, and phone for potential account irregularities</p>
                        </div>
                        <div className="griditem">   
                            <CheckOutlined style={{color: "#2565B0"}} />
                            <h4>CredPal Mobile App</h4>
                            <p>Conveniently manage your account, pay bills, view balances, and track transactions on the go.</p>
                        </div>
                        <div className="griditem">   
                            <CheckOutlined style={{color: "#2565B0"}} />
                            <h4>Zero Fraud Liability</h4>
                            <p>Enjoy peae of mind - no responsibility for unauthorized charges if your card is lost or stolen</p>
                        </div>
                        <div className="griditem">   
                            <CheckOutlined style={{color: "#2565B0"}} />
                            <h4>Card Lock</h4>
                            <p>Easily secure your card with a simple top in case of misplacement, loss, or theft</p>
                        </div>
                    </div>
                </div>
                <div className="layer9">
                    <div className="left">
                        <h2>Prefer an exclusive rewards credit card for purchase?</h2>
                        <p>Secure the  Loystar uRewards Card for exclusive benefits, including 5% cash back for the first 12 months using CredPal Pay.</p>

                        <Button 
                            type='primary' 
                            id='apply_now'
                            onClick={() => setOpenBvnModal(true)}
                        >Apply Now</Button>
                        <h6>Apply via credpal. Refer to the Loystar uRewards Card Terms and Condition below. By clicking "Apply Now" you agree to Credpal's privacy Policy and Terms of Use.</h6>
                    </div>
                    <div className="right">
                        <div className="image1">
                            <Image src={Phonehand} alt='hand holding phone' preview={false} />
                        </div>
                        <div className="image2">
                            <Image src={Lastcard} alt='credpal card' preview={false}  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {openOTPModal 
        && 
        <OtpInputModal 
            open={openOTPModal}
            onCancel={()=>setOpenOtpModal(false)}
            verifyPhoneNumber={verifyPhoneNumber}
            verifyOTP={verifyOTP}
            phoneNumber={phoneNumber}
            credPalLoading={credPalLoading}
            handleOTPInput={handleOTPInput}
        />
        }
        {openBvnModal && 
            <BVNModal 
                open={openBvnModal}
                onCancel={() => setOpenBvnModal(false)}
                phone_number={phoneNumber}
                email={email}
                userBvn={userBvn}
                verifyPhone={verifyPhoneNumber}
                verifyPhoneLoad={credPalLoading}
            />
        }
        <Footer active="home" disable merchanturl={ merchantUrl && merchantUrl} />
    </>
  )
}

export default CredpalPage;