import React, {useEffect, useState} from 'react';
import { Modal, Button, Input, Spin, message } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import { Compass, GiftCard1, LogoBlack } from '../../../../assets/icons';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CONFIRM_CUSTOMER, CONFIRM_CUSTOMER_AND_CARD, CONFIRM_GIFT_CARD_NUMBER } from '../../../../constants';
import { useLazyQuery } from '@apollo/client';
import axios from "axios";
import { useDispatch } from 'react-redux';
import { giftCardAction, isOTPVerified } from '../../../../redux/actions/ussdActions';
import { useParams, useHistory } from 'react-router-dom';

const GiftModal = ({ organisation, open, onCancel, setNewUser, checkInAgain }) => {
    const { card_id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [ confirmCustomer, { data, loading, error, refetch }] = useLazyQuery(CONFIRM_CUSTOMER);
    const [ confirmGiftCard, { data:giftData, loading:giftLoading, error: giftError, refetch: giftRefetch }] = useLazyQuery(CONFIRM_GIFT_CARD_NUMBER);

    const [ confirmCustomerAndCard, { data: confirmData, loading: confirmLoading, error: confitmerror, refetch: confirmRefetch }] = useLazyQuery(CONFIRM_CUSTOMER_AND_CARD);
    const [ value, setValue ] = useState(null);
    const [ showOTP, setShowOTP ] = useState(false);
    const [ messages, setMessage ] = useState(null);
    const [showMessage, setShowMessage ] = useState(false);
    const [ OTP, setOTP ] = useState(null);
    const [ loadingProcess, setLoadingProcess ] = useState(false);
    const [ loadingPro, setLoadingPro ] = useState(false);
    const [ danger, setDanger ] = useState(false);
    const [ giftCardNumber, setGiftCardNumber ] = useState(null)

    const handleKeyPress = ( value ) =>{
        if(value === "") return message.warn("Please Enter Gift Card Number");
        
        if(value?.length > 15){
            const newvalue = value
            // console.log(newvalue);
            const cardNum = newvalue.toString();
            // "44af9b10-c7f0-42e3-904d-16d850467e45"
            if(cardNum.length > 15){
                setLoadingProcess(true);
                if(giftData && !giftLoading){
                    return giftRefetch({card_number: cardNum.toString()});
                }
                confirmGiftCard( {
                    variables: { card_number: cardNum.toString() },
                    onCompleted: ( data ) => {
                        if(data.confirmGiftCardNumber?.message === "Success"){
                            setLoadingProcess(false);
                            const giftcard = data.confirmGiftCardNumber?.giftCard;
                            setNewUser(data.confirmGiftCardNumber?.user);
                            !!checkInAgain && checkInAgain();
                            dispatch( giftCardAction( giftcard ) );
                            setShowMessage(false)
                            setDanger(false)
                            history.push(`/o/${giftcard.organisation_id}/${giftcard.id}`);
                        }
                    },
                    onError: ( error ) => {
                        setMessage(error.message);
                        setDanger(true)
                        setShowMessage(true)
                        setLoadingProcess(false);
                    }
                })
            }
        }
    }
	// useEffect(()=>{

    // }, [isO])		
    const generateOTP = ( value ) =>{
        setLoadingProcess(true);
        const formData = new FormData;
        formData.append("phone_number", value)
        axios.put(`${process.env.REACT_APP_BASE_URL}/api/v2/generate_phone_verification_token`, formData)
        .then((res)=>{
            // console.log(res);
            if(res.status === 200){
                setLoadingProcess(false);
                const message = "An one time code as been sent. Pls enter below";
                setShowOTP(true);
                setMessage(message);
                setShowMessage(true);
            }
        })
        .catch((err)=>{
            console.log(err);
        })
    }
    const handleConfirmOTP = ( ) =>{
        setLoadingPro(true)
        if(OTP === "") {
            return message.warn("Please Input OTP to verify");
        }
        const formData = new FormData;
        formData.append("phone_number", value);
        formData.append("code", OTP);

        axios.put(`${process.env.REACT_APP_BASE_URL}/api/v2/verify_merchant_phone_number`, formData)
        .then((res)=>{
            if(res.status === 200){
                setLoadingPro(false)
                setMessage(res.data.message);
                setShowMessage(true);
                message.success(res.data.message)
                setTimeout(() => {
                    onCancel();
                }, 3000);
                dispatch( isOTPVerified() );
            }
        })
        .catch((err)=>{
            setLoadingPro(false);
            setMessage(err.message)
            // console.log(err);
        })

    }

  return (
    <Modal
        open={open}
        footer={null}
        className="login_modal"
        onCancel={onCancel}
        style={{marginTop: "-10%"}}
        >
            <div className="popupmodal_">
                <div className="popupcontent">
                    <img src={organisation?.banner || LogoBlack} alt={organisation?.organisation_name}/>
                </div>

                <div className="popupcontent">
                    <h2>{organisation?.organisation_name}</h2>
                </div>

                <div className="popupcontent">
                    <h3>👋🏽 Welcome</h3>
                </div>

                <div className="popupcontent" style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1.5rem"
                }}>
                    <span><img src={GiftCard1} alt="compass" /></span>
                    <p style={{color: "#000"}}>HI, you have been awarded a Gift voucher. Please Enter Your GiftCard Number and phone number to redeem</p>
                </div>
                <div>
                <div className="popupcontent" style={{display: "flex", alignItems: "center", flexDirection: "column", gap: "1rem"}}>
                <div style={{display: "flex", gap: "1rem", width: "100%"}}>
                    <Input
                        // prefix={<PhoneOutlined />}
                        placeholder="Your GiftCard Number"
                        type="text"
                        // onChange={(e)=>setGiftCardNumber(e.target.value)}
                        onChange={(e)=>{
                            handleKeyPress(e.target.value);
                        }}
                    />
                    {loadingProcess && <Spin/>}
                    </div>
                </div>
                 {showMessage && <span style={{color: danger ? "red" : "#000"}}>{messages}</span>}
                </div>
                {showOTP &&
                    <div className="popupcontent">
                        <Input 
                            onChange={(e)=>setOTP(e.target.value)}
                        />
                        <Button type="primary" 
                        className="popBtn"
                        onClick={()=>handleConfirmOTP()}
                        loading={loadingPro}
                        >Verify</Button>
                    </div>
                 }
            </div>
    </Modal>
  )
}

export default GiftModal