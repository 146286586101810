import React, { useRef, useState, useEffect } from "react";
import { Modal, Button, Form, Carousel, Input, message } from "antd";
import { Compass, LogoBlack, Fast, GiftCard1 } from "../../../assets/icons";
import { ContactsOutlined } from "@ant-design/icons";
import { GET_USERS_BY_PHONE, } from "../../../constants";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import { LOGIN_USER, SIGNUP_USER } from "../../../constants";
import { useMutation, useLazyQuery } from "@apollo/client";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";

const SigninModal = ({
	visible,
	onCancel,
	merchant_rewards_id,
	setNewUser,
	merchant,
	checkInAgain,
	setConfirmGiftCard,
	setCustomerLoyaltyProgram
}) => {
	const [signInForm] = Form.useForm();
	const [signUpForm] = Form.useForm();
	const [ value, setValue ] = useState("")
	const carouselRef = useRef(null);
	const [ shouldSignIn, setShowSignIn ] = useState(false);
	const [ loading, setLoading ] = useState(false);
	const [ showOTP, setShowOTP ] = useState(false);
    const [ messages, setMessage ] = useState(null);
    const [showMessage, setShowMessage ] = useState(false);
    const [ OTP, setOTP ] = useState(null);
	const [ danger, setDanger ] = useState(false);
	const [ phoneNo, setPhoneNo ] = useState(null);
	const [ firstName, setFirstName ] = useState(null);
	const [ userData, setUserData ] = useState(null);
	const [ isUserFound, setIsUserFound ] = useState(true);
	const [ whatsappOtp, setWhatsappOtp ] = useState(null);
	const [ isWhatapp, setIsWhatsapp ] = useState(false);
	const [timeLeft, setTimeLeft] = useState(60);
	const [ showSendWhatsapp, setShowWhatsapp ] = useState(false);


	const [getUserDetails] = useLazyQuery(GET_USERS_BY_PHONE);

	useEffect(() => {
		const timerId = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

		if(timeLeft <= 0){
			setShowWhatsapp(true);
			clearInterval(timerId);
		}

		if(!showOTP) clearInterval(timerId)
	
	  return () => {
		clearInterval(timerId);
	  }
	}, [timeLeft, showOTP]);

	const formatTime = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		//
		return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
	};
	
	
	// const merchant = storedMerchant ? JSON.parse(storedMerchant) : "";
	const confirmCloseModal = () =>
		Modal.confirm({
			title: "Are you sure you want to cancel sign-in?",
			onOk: onCancel,
		});

	const handleLogin = () => {
		try {
			signInForm.validateFields().then((values) => {
				loginUser({ variables: { ...values } });
			});
		} catch (error) {
			message.error(error.message);
		}
	};

	const handleSignUp = () => {
		setLoading(true);
		try {
			signUpForm.validateFields().then((values) => {
				let { phone_number } = values;
                phone_number = value;
				delete values.OTP;

				signUpUser({
					variables: {
						data: {
							...values,
							phone_number: phone_number,
							merchant_rewards_id: merchant_rewards_id || "",
							sex: "-",
						},
					},
				});
			});
		} catch (error) {
			setLoading(false)
			message.error(error.message);
		}
	};

	const tempSignIn = () => {
		handleSignUp();
	}

	const handleSignIn = () => {
		const { phone_number } = signUpForm.getFieldsValue();
		if(!phone_number) return message.error("Enter phone number to continue");

		setLoading(true);
		getUserDetails({ 
			variables: { 
			  phone_number: "+"+phone_number,
			  merchant_id: merchant.id
			}, 
			onCompleted: (data) => {
				const { message: mess, user } = data.getUserByPhone;
				if(mess === "failed, user does not exist!") {
					setIsUserFound(false);
					return setLoading(false);
					// return handleSignUp();
					// return generateOTP();
				}
				// setIsUserFound(true);
				// setLoading(false);
				// setNewUser(user);
				// setCustomerLoyaltyProgram && setCustomerLoyaltyProgram(user.loyalty_points)
				// localStorage.setItem("user_customer_details", JSON.stringify(user))
				// message.success(`${user?.first_name}, you have been checked in successfully`);
				// onCancel();
				// !!checkInAgain && checkInAgain();
				
				setIsUserFound(true);
				setLoading(false);
				setUserData(user);
				generateOTP();
			}
		});
	}

	const generateOTP = () =>{
		const { phone_number } = signUpForm.getFieldsValue();
		if(!phone_number) return message.error("Enter phone number to continue");
        
        const formData = new FormData;
        formData.append("phone_number", "+"+phone_number);
		setLoading(true);
        axios.put(`${process.env.REACT_APP_BASE_URL}/api/v2/generate_phone_verification_token`, formData)
        .then((res)=>{
            // console.log(res);
            if(res.status === 200){
                setLoading(false);
                const message = `Please enter the code sent to ${phoneNo}`;
                setShowOTP(true);
                setMessage(message);
                setShowMessage(true);
            }
        })
        .catch((err)=>{
			setLoading(false)
            console.log(err);
        })
    }

	const generateOTPViaWhatsapp = () =>{
		const { phone_number } = signUpForm.getFieldsValue();
		if(!phoneNo) return message.error("Enter phone number to continue");
        
        const formData = new FormData;
        formData.append("phone_number", "+"+phone_number);
		setLoading(true);
        // axios.get(`${process.env.REACT_APP_BASE_URL}/api/v2/generate_phone_verification_token_wa?phone_number=${phoneNo}`)
		axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/send_whatsapp_otp`, {
			phoneNo: phoneNo,
			email: merchant.email	
		})
        .then((res)=>{
            if(res.status === 200){
                setLoading(false);
                const message = `Please enter the code sent to ${phoneNo}`;
				setWhatsappOtp(res.data.otp);
				setIsWhatsapp(true);
                setShowOTP(true);
                setMessage(message);
                setShowMessage(true);
            }
        })
        .catch((err)=>{
			setLoading(false)
            console.log(err);
        })
    }//

	const confirmWhatsappOtp = () =>{
		if(!OTP) return message.error("Enter otp to continue");
		if(!phoneNo) return message.error("Enter phone number to continue");
        
		setLoading(true);
        // axios.get(`${process.env.REACT_APP_BASE_URL}/api/v2/generate_phone_verification_token_wa?phone_number=${phoneNo}`)
		axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/verify_whatsapp_otp`, {
			phoneNo: phoneNo,
			email: merchant.email,
			otp: OTP	
		})
        .then((res)=>{
            if(res.status === 200){
                setLoading(false)
                setShowMessage(true);
				if(!isUserFound){
					setDanger(false);
					setMessage(null);
					return handleSignUp();
				}
                setNewUser(userData);
				localStorage.setItem("user_customer_details", JSON.stringify(userData))
				message.success(
					`${userData?.first_name}, you have been checked in successfully`
				);
				!!checkInAgain && checkInAgain();
				return onCancel();
            }
        })
        .catch((err)=>{
			setLoading(false)
            console.log(err);
        })
    }

	const handleConfirmOTP = ( ) =>{
		// const { phone_number } = signUpForm.getFieldsValue();
        if(!OTP) return message.error("Enter otp to continue");
		if(!phoneNo) return message.error("Enter phone number to continue");
        const formData = new FormData;
        formData.append("phone_number", phoneNo);
        formData.append("code", OTP);
		if(isWhatapp){
			return confirmWhatsappOtp();
		}
		setLoading(true);
        axios.put(`${process.env.REACT_APP_BASE_URL}/api/v2/verify_merchant_phone_number`, formData)
        .then((res)=>{
            if(res.status === 200){
                setLoading(false)
                setMessage(res.data.message);
                setShowMessage(true);
				if(!isUserFound){
					setDanger(false);
					setMessage(null);
					return handleSignUp();
				}
                setNewUser(userData);
				localStorage.setItem("user_customer_details", JSON.stringify(userData))
				message.success(
					`${userData?.first_name}, you have been checked in successfully`
				);
				!!checkInAgain && checkInAgain();
				onCancel();
            }
        })
        .catch((err)=>{
			setDanger(true);
            setLoading(false);
            setMessage(err?.response?.data?.message || err.message)
            // console.log(err);
        })
    }

	const [loginUser, { loading: isLoginLoading }] = useMutation(LOGIN_USER, {
		onCompleted: (data) => {
			if (!!data.loginUser) {
				localStorage.setItem(
					"user_customer_details",
					JSON.stringify(data.loginUser)
				);
				setNewUser(data.loginUser);
				onCancel();
				Modal.success({
					title: "Login Successful",
					onOk: onCancel,
				});
			}
		},
		onError: (error) => {
			message.error(error.message, 5);
		},
	});

	const [signUpUser, { loading: isSignUpLoading }] = useMutation(SIGNUP_USER, {
		onCompleted: (data) => {
			if (data.signUpUser) {
				setLoading(false);
				setIsUserFound(true);
				setUserData(data.signUpUser);
				generateOTP();
				// setNewUser(data.signUpUser);
				// message.success(
				// 	`${data.signUpUser?.first_name}, you have been checked in successfully`
				// );
				// !!checkInAgain && checkInAgain();
				// onCancel();
			}
		},
		onError: (error) => {
			setLoading(false);
			message.error(error.message, 5);
		},
	});

	// const goNext = () => carouselRef.current.next();
	// const goPrev = () => carouselRef.current.prev();

	return (
		<Modal
			open={visible}
			footer={[]}
			className="login_modal"
			onCancel={confirmCloseModal}
			style={{marginTop: "-10%"}}
			>
			<Carousel ref={carouselRef} dots={false}>
				<div className="sign_up_content">
					<div className="flex_center">
						<img
							style={{ width: 80 }}
							src={merchant?.image || LogoBlack}
							alt="merchant logo"
						/>
					</div>
					<h2
						className="title_text"
						style={{
							color: "#6B6A6A",
							marginTop: 0,
							fontWeight: 600,
							fontSize: "24px",
							textAlign: "center",
						}}>
						{merchant?.business_name || ""}
					</h2>
					{!userData &&<h2
						className="title_text"
						style={{
							color: "#000",
							fontWeight: 600,
							fontSize: "32px",
							textAlign: "center",
						}}>
						👋🏽 Welcome
					</h2>}
					{userData &&<h2
						className="title_text"
						style={{
							color: "#000",
							fontWeight: 600,
							fontSize: "32px",
							textAlign: "center",
						}}>
						👋🏽 Welcome back, {userData ? userData?.first_name : ""}
					</h2>}

					{/* <div className="discover_details">
						<img src={Compass} alt="compass" />
						<div className="discover_text">
							<p>Check-in...</p>
							<p>
								to view the menu, send your orders, claim loyalty rewards and
								more...
							</p>
						</div>
					</div> */}
					<div className="discover_details">
						<img src={Fast} alt="compass" />
						<div className="discover_text">
							{/* <p>Check-in...</p> */}
							<p>
							Spend less time on the queue. Send order and get notified to pick up.
							</p>
						</div>
					</div>
					<div className="discover_details">
						<img src={GiftCard1} alt="compass" />
						<div className="discover_text">
							{/* <p>Check-in...</p> */}
							<p>
							Collect loyalty points and rewards as you spend with us.
							</p>
						</div>
					</div>
					{message && <span style={{color: danger ? "red" : "#000"}}>{messages}</span>}
					<Form form={signUpForm}>{/*showOTP &&*/} 
						{!isUserFound && <Form.Item
							name="first_name"
							rules={[
								{
									required: true,
									message: "Enter your first name to continue",
								},
							]}
							style={{ margin: "20px 0" }}>
							<Input
								prefix={<ContactsOutlined />}
								size="large"
								type="text"
								placeholder="First Name"
								onChange={(e) => setFirstName(e.target.value)}
								value={firstName}
							/>
						</Form.Item>}
						{!showOTP &&<Form.Item
							name="phone_number"
							rules={[
								{
									required: true,
									message: "Enter your phone number to continue",
								},
							]}>
							<PhoneInput
								inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
								inputStyle={{
									border: "0.5px solid #6B6A6A",
									height: "40px",
									width: "100%",
									borderRadius: 2,
								}}
								country="us"
								value={value}
								onChange={(value)=>{
                                    setValue("+"+value)
									setPhoneNo(`+${value}`)
                                }}

							/>
						</Form.Item>}
						{showOTP && <Form.Item
							name="OTP"
							rules={[
								{
									required: true,
									message: "Enter the OTP sent to your phone number",
								},
							]}>
							<Input 
								value={OTP}
								size="large"
								style={{ margin: "0" }}
								onChange={(e) => setOTP(e.target.value)}
							/>
						</Form.Item>}
					</Form>
					
					{/* {value} */}
					{/* <p style={{ marginTop: 20, color: "#6B6A6A" }}>
						Our{" "}
						<Link style={{ color: "#000" }} to="/privacy">
							Privacy Policy
						</Link>
					</p> */}
					<div style={{
						display: "flex",
						alignItems: "center",
						gap: "0.5rem",
						margin: "-1rem 0",
					}}>
						{/* <Checkbox 
							onChange={(e)=>{
								setConfirmGiftCard(e)
							}}
						/> */}

						<p style={{color: "#000", textDecoration: "underline", cursor: "pointer"}} onClick={()=>setConfirmGiftCard(true)}>Received GiftCard, click to confirm Gift Voucher</p>

						
					</div>
					
					<div className="action_buttons" style={{ marginTop: "3rem" }}>
						{/* {!showOTP && !shouldSignIn && <Button
							type="primary"
							style={{ width: "277px", height: "50px" }}
							loading={isSignUpLoading}
							onClick={handleSignUp}>
							<span style={{ fontWeight: 600, fontSize: "20px" }}>
								Sign Up
							</span>
						</Button>} */}
						{!showOTP && isUserFound && <Button
							type="primary"
							style={{ width: "277px", height: "50px" }}
							loading={loading}
							// onClick={handleSignIn}
							onClick={handleSignIn}
						>
							<span style={{ fontWeight: 600, fontSize: "20px" }}>
								Sign In
							</span>
						</Button>}
						{!isUserFound && <Button
							type="primary"
							style={{ width: "277px", height: "50px" }}
							loading={loading}
							// onClick={handleSignIn}
							onClick={tempSignIn}
						>
							<span style={{ fontWeight: 600, fontSize: "20px" }}>
								Continue
							</span>
						</Button>}
						{showOTP && <Button
							type="primary"
							style={{ width: "277px", height: "50px" }}
							loading={loading}
							onClick={handleConfirmOTP}>
							<span style={{ fontWeight: 600, fontSize: "20px" }}>
								Verify OTP
							</span>
						</Button>}
						{!showSendWhatsapp && showOTP && <p style={{display: "flex", justifyContent: "space-between", color: "#000"}}>{formatTime(timeLeft)}</p>}
						
						{showOTP && showSendWhatsapp &&<p style={{color: "#000", cursor: "pointer"}} onClick={generateOTPViaWhatsapp}>Get OTP via Whatsapp</p>}
					</div>
					{/* {!shouldSignIn && <p style={{color: "#000", textAlign:"center"}}>Already have an account, <span style={{cursor: "pointer"}} onClick={() => setShowSignIn(true)}>sign in</span> </p>}
					{shouldSignIn && <p style={{color: "#000", textAlign:"center"}}>Don't have an account, <span style={{cursor: "pointer"}} onClick={() => setShowSignIn(false)}>sign up</span> </p>} */}
					{/* <p style={{ marginTop: 20, color: "#6B6A6A", textAlign: "center" }}>
						Already have an account?
						<Button type="link" style={{ color: "#000" }} onClick={goNext}>
							Sign In
						</Button>
					</p> */}
				</div>

				<div className="sign_up_content">
					<div className="flex_center">
						<img
							style={{ width: 80 }}
							src={merchant?.image || LogoBlack}
							alt="merchant logo"
						/>
					</div>
					<h2
						className="title_text"
						style={{
							color: "#6B6A6A",
							marginTop: 20,
							fontWeight: 600,
							fontSize: "24px",
							textAlign: "center",
						}}>
						{merchant?.business_name || ""}
					</h2>
					<h2
						className="title_text"
						style={{
							color: "#000",
							fontWeight: 600,
							fontSize: "32px",
							textAlign: "center",
						}}>
						👋🏽 Welcome Back!
					</h2>

					<div className="discover_details">
						<img src={Compass} alt="compass" />
						<div className="discover_text">
							<p>Sign-in...</p>
							<p>
								to view the menu, send your orders, claim loyalty rewards and
								more...
							</p>
						</div>
					</div>

					<Form form={signInForm}>
						<Form.Item
							style={{ marginTop: 20 }}
							name="phone_number"
							rules={[
								{
									required: true,
									message: "Enter your phone number to continue",
								},
							]}>
							<PhoneInput
								inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
								inputStyle={{
									border: "0.5px solid #6B6A6A",
									height: "40px",
									width: "100%",
									borderRadius: 2,
								}}
								country="us"
							/>
						</Form.Item>
						<Form.Item
							style={{ marginTop: 20 }}
							name="password"
							rules={[
								{
									required: true,
									message: "Enter your password to continue",
								},
							]}>
							<Input.Password
								size="large"
								placeholder="Enter password"
								iconRender={(visible) =>
									visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
								}
							/>
						</Form.Item>
					</Form>

					{/* <p style={{ marginTop: 20, color: "#6B6A6A" }}>
						Our{" "}
						<Link style={{ color: "#000" }} to="/privacy">
							Privacy Policy
						</Link>
					</p>
					<div className="action_buttons" style={{ marginTop: "3rem" }}>
						<Button
							type="primary"
							style={{ width: "277px", height: "50px" }}
							loading={isLoginLoading}
							onClick={handleLogin}>
							Continue
						</Button>
					</div>
					<p style={{ marginTop: 20, color: "#6B6A6A", textAlign: "center" }}>
						Don't have an account?
						<Button type="link" onClick={goPrev} style={{ color: "#000" }}>
							Sign Up
						</Button>
					</p> */}
				</div>
			</Carousel>
		</Modal>
	);
};

export default SigninModal;
