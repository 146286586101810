import React, { useState } from "react";
import Footer from "../../reusables/Footer";
import Header from "../../reusables/Header";
import { Button } from "antd";
import { QRCode } from "react-qrcode-logo";
import { QrCode as QrCodeIcon } from "../../assets/icons";
import Scanner from "react-webcam-qr-scanner";
import { Redirect } from "react-router-dom";

const CheckIn = () => {
	const [qrLink, setQrLink] = useState(null);
	const [showQr, setShowQr] = useState(false);
	const user = JSON.parse(localStorage.getItem("user_details"))

	if (qrLink) return <Redirect push to={`${qrLink}`} />;
	return (
		<>
			<Header
				titleComponent={
					<h2 className="title_text" style={{ margin: 0 }}>
						Check In
					</h2>
				}
			/>
			<div className="container_main">
				{!showQr && (
					<div className="flex_center_column">
						<Scanner
							className="some-classname"
							onDecode={(val) => setQrLink(val.data?.split(".com")[1])}
							constraints={{
								audio: false,
								video: {
									facingMode: "environment",
								},
							}}
							captureSize={{ width: 300, height: 300 }}
						/>
						<p>
							<b>Scan QR Code</b>
						</p>
					</div>
				)}
				{showQr && (
					<div className="flex_center_column">
						<QRCode
							size={200}
							quietZone={50}
							value={user?.phone_number}
							logoWidth={100}
							logoHeight={100}
							bgColor="#000"
							fgColor="#fff"
							logoOpacity={0.6}
							qrStyle="dots"
							ecLevel="H"
							eyeRadius={[
								{
									outer: [10, 10, 0, 10],
									inner: [0, 10, 10, 10],
								},
								[10, 10, 10, 0],
								[10, 0, 10, 10],
							]}
						/>
						<div style={{ height: 40 }}></div>
						<Button
							type="primary"
							style={{
								marginRight: 10,
								background: "#6B6A6A",
								width: "128px",
								height: "50px",
							}}>
							<img src={QrCodeIcon} alt="qr code" />
							Check In
						</Button>
					</div>
				)}
				<div>
					<Button
						type="primary"
						style={{
							margin: 10,
							background: "#6B6A6A",
							width: "128px",
							float: "right",
							height: "50px",
						}}
						onClick={() => setShowQr((prev) => !prev)}>
						{!showQr ? "Show" : "Hide"} My QR
					</Button>
				</div>
			</div>
			<div style={{ height: 80 }}></div>
			<Footer active="cart" />
		</>
	);
};

export default CheckIn;
