import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import { Modal, Input } from 'antd';

const containerStyle = {
  width: '100%',
  height: '400px'
};

function GoogleMapComponent({
  open,
  onCancel,
  setCustomerLongAndLat,
  setAddress_line1,
  setAddress_line2,
  setState,
  setCountry,
  setPostal_code,
  getMerchantLatLong
}) {
  const [currentPosition, setCurrentPosition] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentPosition({
          lat: latitude,
          lng: longitude
        });
      },
      (error) => {
        console.error('Error getting the user location', error);
      }
    );
  }, []);

  const onLoad = (autocomplete) => {
    // console.log("c", autocomplete);
    setAutocomplete(autocomplete);
  };
  // phone_number

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const location = place.geometry.location;
      setSearchResult({
        lat: location.lat(),
        lng: location.lng()
      });
      const splitAddress = place.formatted_address.split(",");
      const length = splitAddress.length;
      const postalLine2 = length > 3 ? splitAddress[length - 3].split(" ") : null;
      const address_line1 = length > 3 ? splitAddress.splice(0,length - 3) : null;
      const newSplitAddress = place.formatted_address.split(",");
      
      length >= 1 ? setCountry(newSplitAddress[length - 1].trim().toString()) : setCountry("");
      length >= 2 ? setState(newSplitAddress[length - 2].trim().toString()) : setState("");
      postalLine2 && postalLine2.length > 2 ? setPostal_code(postalLine2[2].toString()) : setPostal_code("");
      postalLine2 && postalLine2.length > 2 ? setAddress_line2(postalLine2[1].toString()) : setAddress_line2("");
      address_line1 ? setAddress_line1(address_line1.toString()) : setAddress_line1("");
      setCustomerLongAndLat({
        longitude: location.lng(),
        latitude: location.lat()
      })
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title="Select Location"
      onOk={() => getMerchantLatLong()}
    >
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY} libraries={['places']}>
      <div>
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <input
            type="text"
            placeholder="Search for a place"
            style={{ width: '300px', height: '40px' }}
            ref={inputRef}
          />
        </Autocomplete>
      </div>
      {/* <GoogleMap
        mapContainerStyle={containerStyle}
        center={searchResult || currentPosition || { lat: 0, lng: 0 }}
        zoom={searchResult || currentPosition ? 15 : 2}
      >
        {currentPosition && <Marker position={currentPosition} />}
        {searchResult && <Marker position={searchResult} />}
      </GoogleMap> */}
    </LoadScript>
    </Modal>
  );
}

export default GoogleMapComponent;