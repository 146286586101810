import React, { useEffect, useState } from "react";
import { Button, Col, Row, message, Modal, Spin } from "antd";
import { loadingPNG } from "../../../assets/icons";


const SuccessModal = ({open, onCancel, onOk, body}) => {
  return (
    <>
        <Modal
            open={open}
            onCancel={onCancel}
            className="success_modal" 
            onOk={onOk}
            footer={null}
        >       
        {/* <img src={loadingPNG} alt="loading.."
            style={{
                width: "100px",
                height: "100px"
            }}
        /> */}
        <Spin 
            size="large"
        />
                <h4>
                    {body}
                </h4>
		</Modal>
    </>
  )
}

export default SuccessModal;