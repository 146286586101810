import axios from "axios";

export const getLeaderBoard = async (payload) => {
    const url = `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/referral_details_code`

    const response = await axios.get(url, {
        headers: {event_code: payload}
    });

    return Promise.resolve(response.data);
}