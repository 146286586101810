import React, { useState } from "react";
import { Radio, Button } from "antd";

function Step1({
  setStep,
  selectedServices, // Update the prop name
  setSelectedServices, // Update the prop name
  servicesData,
}) {
  const handleServiceChange = (service) => {
    const existingService = selectedServices.find(
      (s) => s.name === service.name
    );

    if (existingService) {
      // If the service is already selected, update its quantity
      const updatedServices = selectedServices.map((s) =>
        s.name === service.name ? { ...s, quantity: s.quantity + 1 } : s
      );
      setSelectedServices(updatedServices);
    } else {
      // If the service is not selected, add it with quantity 1
      setSelectedServices([...selectedServices, { ...service, quantity: 1 }]);
    }
  };

  const handleServiceRemove = (serviceName) => {
    const updatedServices = selectedServices
      .map((s) =>
        s.name === serviceName ? { ...s, quantity: s.quantity - 1 } : s
      )
      .filter((s) => s.quantity > 0);

    setSelectedServices(updatedServices);
  };

  const handleNext = () => {
    if (selectedServices.length > 0) {
      setStep(1); // Assuming step 2 is the next step
    } else {
      // Display an error message or toast indicating that at least one service must be selected.
    }
  };

  const [selectedCategory, setSelectedCategory] = useState(
    servicesData[0].name
  );

  const handleCategoryChange = (categoryName) => {
    setSelectedCategory(categoryName);
  };

  const categoryData = servicesData.find(
    (service) => service.name === selectedCategory
  ).services;

  return (
    <div style={{ color: "black" }}>
      <h2>Select a Service</h2>

      <div
        style={{
          display: "flex",
          flexWrap: "no-wrap",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        {servicesData.map((service, index) => (
          <p
            className={selectedCategory === service.name ? "active" : ""}
            key={index}
            onClick={() => handleCategoryChange(service.name)}
          >
            {service.name}
          </p>
        ))}
      </div>

      {categoryData.map((service, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
            border: "1px solid #ccc",
            padding: 10,
            borderRadius: 6,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 2,
            }}
          >
            <h4 style={{ marginBottom: 0 }}>{service.name}</h4>
            <p style={{ margin: 0 }}>{service.price}</p>
            <p style={{ margin: 0 }}>{service.time}</p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {selectedServices.find((s) => s.name === service.name) ? (
              <>
                <button
                  onClick={() => handleServiceChange(service)}
                  style={{ marginRight: 8, padding: "5px 10px" }}
                >
                  +
                </button>
                <span>
                  {
                    selectedServices.find((s) => s.name === service.name)
                      .quantity
                  }
                </span>
                <button
                  onClick={() => handleServiceRemove(service.name)}
                  disabled={
                    !selectedServices.find((s) => s.name === service.name)
                  }
                  style={{ marginLeft: 8, padding: "5px 10px" }}
                >
                  -
                </button>
              </>
            ) : (
              <button
                onClick={() => handleServiceChange(service)}
                style={{
                  padding: "4px 10px",
                  borderRadius: 6,
                  border: "none",
                }}
              >
                +
              </button>
            )}
          </div>
        </div>
      ))}

      <Button type="primary" onClick={handleNext}>
        Next
      </Button>
    </div>
  );
}

export default Step1;
