import React, { useEffect, useState } from 'react';
import { Button, Modal, message } from 'antd';
import USSDModal from '../../cart/modal/USSDModal';
import { usePaystackPayment } from "react-paystack";
import { useSelector, useDispatch, connect } from 'react-redux';
import Axios from 'axios';
import UssdService from './ussdModal';
import Pusher from "pusher-js";

const PaymentOptions = ({ 
    open, 
    onCancel, 
    merchant, 
    total,
    payAtCollection,
    setIsPaymentSuccessful,
    isCreateBookingLoading 
}) => {

    const [ openBankModal, setOpenBankModal ] = useState(false);
    const [ PayStackLoading, setPayStackLoading] = useState(false);
    const [payStackKey, setPayStackKey] = useState(null);
    const [ gettingPayStack, setgettingPayStack ] = useState(false);
    const [ isPayStack, setIsPaystack ] = useState(false);
    

    useEffect(()=>{
        getMerchantPayStackDetails();
    }, []);

    useEffect(()=>{
      const merchantApp = localStorage.getItem("merchant_app");
      if(merchantApp){
        const apps = JSON.parse(merchantApp);
        
        // console.log(apps);
        const paystack = apps.filter((app)=>{
          return app.app.app_name.toLowerCase().includes("paystack")
        });
  
          paystack.length > 0 
          ? setIsPaystack(true)
            : setIsPaystack(false)
      }
    }, []);

    const paystackConfig = {
    reference: new Date().getTime().toString(),
    email: merchant.email,
    currency: merchant.currency,
    amount: Number(total) * 100,
    publicKey:
        payStackKey !== null
        ? payStackKey
        : process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    };

   

    const handlePayStack = usePaystackPayment(paystackConfig);

    const paymentCallback = (resp) => {
        if (resp.message === "Approved") {
          setPayStackLoading(false);
          setIsPaymentSuccessful(true);
          Modal.success({
            title: "Payment Successful!",
            onOk: () => {
                payAtCollection();
                onCancel();
            }
          })
        } else {
          Modal.error({
            title: `payment not successful, please try again!`,
          });
        }
    };

    const getMerchantPayStackDetails = async () => {
        const merchant = localStorage.getItem("urewards_merchant_details");
        if (merchant) {
          setgettingPayStack(true);
          const merch = JSON.parse(merchant);
          const merchant_email = merch.email;
          await Axios
            .post(
              `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/urewards_merchant_paystack_details`,
              { merchant_email },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              if (response.status === 200) {
                setgettingPayStack(false);
                setPayStackKey(response.data.data.data);
              } else {
                setgettingPayStack(false);
                setPayStackKey(null);
              }
            })
            .catch((err) => {
              setgettingPayStack(false);
              setPayStackKey(null);
              message.error(`${err.response.statusText}`);
            });
        }
    };

  return (
    <><Modal 
        open={open}
        onCancel={onCancel}
        title="Select Payment Options"
        className="paymentoptions"
        footer={null}
    >
        <h2>How would you like to pay</h2>
        <div>
            <p>Total due</p>
            <h1>{merchant.currency} {total}</h1>
        </div>
        <div 
            style={{
                display: "flex",
                flexDirection: "column",
                gap:"5px",
                alignItems: "center"
            }}
        >
            <Button
                type="primary"
                onClick={()=>payAtCollection()}
                className="cart_buttons"
                loading={isCreateBookingLoading}
            >
                Book without payment
            </Button>
            {isPayStack && <Button
                type="primary"
                loading={gettingPayStack || PayStackLoading}
                onClick={()=>handlePayStack(paymentCallback, () => {})}
                className="cart_buttons"
            >
                Pay with Paystack
            </Button>}
            {merchant?.currency === "NGN" 
                &&  <Button
                type="primary"
                style={{
                    display: `${merchant?.payout_active ? "block" : "none"}`,
                }}
                className="cart_buttons"
                onClick={()=>{
                  setOpenBankModal(true)
                  // console.log("true");
                }}
            >
                Pay with USSD
            </Button>}
        </div>
    </Modal>
    {<UssdService
        open={openBankModal}
        onCancel={() => setOpenBankModal(false)}
        total={total}
        merchant={merchant}
    />}
    </>
  )
}


const mapStateToProps = (state) => ({
	state: state,
	carts: state.carts,
	merchantDetails: state.merchantDetails,
	orderdetails: state.orderdetails,
    urewardsAuth: state.urewardsAuth
});
export default connect(mapStateToProps)(PaymentOptions);