import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, message, Modal, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import Header from "../../reusables/Header";
import "./discover.scss";
import {
	DiscoverPic,
	QrCode,
	HomePic2,
	LoveIcon,
	MerchantSpotlight,
} from "../../assets/icons";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import Footer from "../../reusables/Footer";
import SigninModal from "../homepage/partials/SigninModal";
import { useDispatch } from "react-redux";
import { getMerchantByDispatch } from "../../redux/actions/orders";

const Discover = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [proMerchants, setProMerchants] = useState([]);
	const [allDeals, setAllDeals] = useState([]);
	const [categories, setCategories] = useState(allCategories.slice(0, 3));
	const [allProducts, setAllProducts] = useState([]);
	const [merchantSpotlight, setMerchantSpotlight] = useState(null);
	const [redirect, setRedirect] = useState(null);
	const [categoryMerchants, setCategoryMerchants] = useState([]);
	const [merchantCategory, setMerchantCategory] = useState(null);
	const [showLoginModal, setShowLoginModal] = useState(false);
	const [getCategoryMerchantsLoading, setGetCategoryMerchantsLoading] =
		useState(false);

	const todayString = moment(Date.now()).format("YYYY-MM-DD");

	useEffect(() => {
		const spotlightMerchant = localStorage.getItem(todayString);
		if (spotlightMerchant) {
			setMerchantSpotlight(JSON.parse(spotlightMerchant));
			getProducts(JSON.parse(spotlightMerchant));
		} else {
			if (proMerchants.length > 0) {
				const newSpotlight =
					proMerchants[Math.floor(Math.random() * proMerchants.length)];
				getProducts(newSpotlight);
				setMerchantSpotlight(newSpotlight);
				localStorage.setItem(todayString, JSON.stringify(newSpotlight));
			}
		}
	}, [proMerchants, todayString]);

	const getAllProMerchants = () => {
		setLoading(true);
		Axios.get(
			`${process.env.REACT_APP_BASE_URL}/api/v2/merchants/get_pro_merchants`
		)
			.then((res) => {
				setLoading(false);
				if (res.status === 200) {
					setProMerchants(res.data);
					// console.log("promerchant", res.data);
				}
			})
			.catch((err) => {
				setLoading(false);
				message.error(err.response?.data.message || err.message);
			});
	};

	const getMerchantByCategory = (category) => {
		setGetCategoryMerchantsLoading(true);
		Axios.get(
			`${process.env.REACT_APP_BASE_URL}/api/v2/merchants/find_merchant_by_business_type?type=${category}&page[number]=1&page[size]=100`
		)
			.then((res) => {
				setGetCategoryMerchantsLoading(false);
				if (res.status === 200) {
					setCategoryMerchants(res.data);
					// console.log("category", category);
					// console.log("merchant category", res.data);
				}
			})
			.catch((err) => {
				setGetCategoryMerchantsLoading(false);
				message.error(err.response?.data.message || err.message);
			});
	}; 

	const getAllDeals = () => {
		setLoading(true);
		Axios.get(`${process.env.REACT_APP_BASE_URL}/api/v2/get_urewards_offers`)
			.then((res) => {
				setLoading(false);
				if (res.status === 200) {
					setAllDeals(res.data);
				}
			})
			.catch((err) => {
				setLoading(false);
				message.error(err.response?.data.message || err.message);
			});
	};

	const getProducts = (merchant) => {
		setLoading(true);
		Axios.get(
			`${process.env.REACT_APP_BASE_URL}/api/v2/products_by_merchants_chatfuel?business_type=${merchant?.business_type}&currency_code=${merchant?.currency}&merchant_id=${merchant?.id}`
		)
			.then((res) => {
				setLoading(false);
				if (res.status === 200) {
					setAllProducts(res.data.messages[0].attachment?.payload.elements);
				}
			})
			.catch((err) => {
				setLoading(false);
				message.error(err.response?.data.message || err.message);
			});
	};

	const getUrewardsId = (merchant) => {
		let string = "";
		for (let letter of merchant.contact_number) {
			string = isNaN(Number(letter)) ? string : (string += letter);
		}
		dispatch( getMerchantByDispatch(merchant) )
		return string;
	};

	const spotlightProduct =
		allProducts[Math.floor(Math.random() * allProducts.length)];

	useEffect(() => {
		getAllProMerchants();
		getAllDeals();
	}, []);

	useEffect(() => {
		if (merchantCategory === null) {
			setCategoryMerchants([]);
		} else getMerchantByCategory(merchantCategory);
	}, [merchantCategory]);

	const columns = [
		{
			title: <b>Merchants</b>,
			key: "business_name",
			render: (data, record) => (
				<Card
					hoverable
					key={record.id}
					bordered={false}
					style={{ padding: 0, margin: "10px 0" }}
					onClick={() =>
						setRedirect({
							pathname: `/${getUrewardsId(record)}`,
							state: { merchant_id: record.id },
						})
					}>
					{record.business_name}
				</Card>
			)

		}
	]

	if (redirect) return <Redirect push to={redirect} />;
	return (
		<>
			<div className="container_main">
				<Header
					titleComponent={
						<h2 className="title_text" style={{ margin: 0 }}>
							Discover
						</h2>
					}
					openLoginModal={() => setShowLoginModal(true)}
				/>
				{showLoginModal && (
					<SigninModal
						visible={showLoginModal}
						onCancel={() => setShowLoginModal(false)}
					/>
				)}
				{merchantCategory && (
					<Modal
						open={!!merchantCategory}
						title={merchantCategory}
						className="login_modal"
						bodyStyle={{ padding: 0 }}
						onCancel={() => setMerchantCategory(null)}
						footer={[
							<Button type="primary" onClick={() => setMerchantCategory(null)} key={Math.random()}>
								Cancel
							</Button>,
						]}>
						<Card bordered={false} loading={getCategoryMerchantsLoading}>
							<Table dataSource={categoryMerchants} columns={columns} bordered={false} style={{padding: 0}} />
							{categoryMerchants.length === 0 && (
								<div>
									<p style={{ color: "#000", textAlign: "center" }}>
										<b>Sorry, no merchants in this category yet</b>
									</p>
								</div>
							)}
						</Card>
					</Modal>
				)}

				<div style={{ width: "95%", maxWidth: "450px", margin: "10px auto" }}>
					<Input.Search
						placeholder="Discover Deals, Dishes, Outfits and More"
						style={{ borderRadius: 10 }}
						prefix={
							<SearchOutlined style={{ marginRight: 10, opacity: "0.5" }} />
						}
					/>
				</div>

				<div>
					<p className="category_title">Explore Categories</p>

					<div className="categories_list">
						{categories.map((category, index) => (
							<div
								className="category_item"
								key={index}
								onClick={() => setMerchantCategory(category)}>
								<span className="checklist"></span>
								<span>{category}</span>
							</div>
						))}
						<div>
							<span
								onClick={() =>
									categories.length < 4
										? setCategories(allCategories)
										: setCategories(allCategories.slice(0, 3))
								}
								style={{ marginLeft: 10, color: "#fff", cursor: "pointer" }}>
								See {categories.length < 4 ? "more" : "less"}...
							</span>
						</div>
					</div>
				</div>

				<div>
					<Row
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							margin: "20px 0",
						}}>
						<Col span={24} lg={12}>
							<div
								className="banner_text img_banner"
								style={{
									width: "95%",
									maxWidth: "450px",
									margin: "auto",
									marginTop: 30,
									background: `center / cover no-repeat  url(${DiscoverPic})`,
								}}>
								<div>
									<p
										style={{
											fontWeight: 600,
											fontSize: "28px",
											color: "#fff",
											paddingBottom: 0,
										}}>
										Discover Vendors!
									</p>
									<p style={{ color: "#6B6A6A", paddingBottom: 0 }}>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor
									</p>
								</div>
							</div>
						</Col>
						<Col span={24} lg={12}>
							<div
								style={{
									width: "95%",
									maxWidth: "450px",
									margin: "auto",
									marginTop: 30,
								}}>
								<div
									style={{
										background: "#6B6A6A",
										padding: "10px",
										borderRadius: 5,
									}}>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}>
										<div>
											<p
												style={{
													fontWeight: 600,
													fontSize: "16px",
													padding: 0,
												}}>
												Scan to Discover
											</p>
											<p
												style={{
													color: "#fff",
													marginTop: 10,
													fontSize: "10px",
												}}>
												Check-in, send your order and save time
											</p>
										</div>
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Button type="primary" style={{ marginRight: 10 }}>
												<Link to="/check-in">
													<img src={QrCode} alt="qr code" />
													Check In
												</Link>
											</Button>
										</div>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</div>

				<Row
					style={{
						display: "flex",
					}}>
					<Col span={24} lg={12}>
						<div
							className="banner_text img_banner"
							style={{
								width: "95%",
								maxWidth: "450px",
								margin: "auto",
								marginTop: 30,
								background: `center / cover no-repeat  url(${
									spotlightProduct?.image_url || HomePic2
								})`,
							}}>
							<div>
								<p
									style={{
										fontWeight: 600,
										fontSize: "28px",
										color: "#fff",
										paddingBottom: 0,
									}}>
									Deal Of The Day!
								</p>
								<p style={{ color: "#6B6A6A", paddingBottom: 0 }}>
									{spotlightProduct?.title ||
										"Check back to see best deal today!"}
								</p>
							</div>
							<div className="button_div">
								<Button type="primary">Buy Now</Button>
							</div>
						</div>
					</Col>
					{merchantSpotlight && (
						<Col span={24} lg={12}>
							<div
								className="banner_text img_banner"
								style={{
									width: "95%",
									maxWidth: "450px",
									margin: "auto",
									marginTop: 30,
									background: `center / cover no-repeat  url(${MerchantSpotlight})`,
								}}>
								<div>
									<p
										style={{
											fontWeight: 600,
											fontSize: "28px",
											color: "#fff",
											paddingBottom: 0,
										}}>
										Merchant Spotlight
									</p>
									<p style={{ color: "#fff", paddingBottom: 0 }}>
										Our Merchant for today is{" "}
										<b>{merchantSpotlight.business_name}</b>
									</p>
								</div>
								<div className="button_div">
									<Button
										onClick={() =>
											setRedirect(`/${getUrewardsId(merchantSpotlight)}`)
										}
										type="primary">
										Browse Products
									</Button>
								</div>
							</div>
						</Col>
					)}
				</Row>

				<div style={{ marginTop: 40 }}>
					<div>
						<p>
							<b>Trending Deals</b>
						</p>
						<h2 className="title_text">Our Top Ten...</h2>
					</div>

					<div className="products_div">
						{allDeals.map((deal, index) => (
							<Col span={24} md={12} lg={8} key={deal.id}>
								<div
									className="flex_center_column"
									style={{
										background:
											"linear-gradient(180deg, #C4C4C4 0%, rgba(196, 196, 196, 0) 100%)",
										height: "280px",
										margin: "20px 10px",
										justifyContent: "space-between",
									}}>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-end",
											padding: 20,
											width: "100%",
										}}>
										<img src={LoveIcon} alt="favourite" />
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "space-between",
											height: "150px",
											width: "100%",
											padding: "20px",
										}}>
										<p style={{ float: "left", color: "#fff" }}>
											<b>{deal.name}</b>
										</p>
										<p style={{ float: "left", color: "#fff" }}>
											{deal.reward}
										</p>
										<p
											style={{
												float: "right",
												color: "#fff",
												textAlign: "right",
											}}>
											<Button type="primary">
												<b>Get</b>
											</Button>
										</p>
									</div>
								</div>
							</Col>
						))}
					</div>
				</div>

				<div>
					<h3 className="title_text" style={{ margin: 40 }}>
						Other Products
					</h3>
				</div>
				<div>
					<Row>
						{allProducts.map((product) => (
							<Col span={24} md={12} lg={8} key={product.title}>
								<div
									className="flex_center_column"
									style={{
										background: product.image_url
											? `url(${product.image_url})`
											: "#C4C4C4",
										height: "280px",
										margin: "20px 10px",
										justifyContent: "space-between",
									}}>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-end",
											padding: 20,
											width: "100%",
										}}>
										<img src={LoveIcon} alt="favourite" />
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "space-between",
											height: "120px",
											background: "#6B6A6A",
											width: "100%",
											padding: "20px",
										}}>
										<p style={{ float: "left", color: "#fff" }}>
											<b>{product.title}</b>
										</p>
										<p
											style={{
												float: "right",
												color: "#fff",
												textAlign: "right",
											}}>
										 	<Button type="link">
												<b>Add To Cart</b>
											</Button>
										</p>
									</div>
								</div>
							</Col>
						))}
					</Row>
				</div>
			</div>
			<Footer active="discover" />
		</>
	);
};

export default Discover;

const allCategories = [
	"Food and Drinks",
	"Fashion and Accessories",
	"Gym and Fitness",
	"Beverages and Desserts",
	"Bakery and Pastry",
	"Travel and Hotel",
	"Hair and Beauty",
	"Home and Gifts",
	"Others",
	"Washing and Cleaning",
	"Groceries",
	"Salon and Beauty",
	"Gadgets and Electronics",
	"Herbs and Spices",
	"Raw Foods",
	"Restaurants, Bars and Cafes",
	"Agriculture",
];
