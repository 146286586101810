import React from 'react';
import { Checked, CartItem1 } from "../../../assets/icons";
import { Col, Row, Modal, Card } from 'antd';

const BookingSuccess = ({ 
    open,
    onCancel,
    bookedService,
    user,
    word1,
    service,
    total,
    merchant,
    isbranchSet,
    branch_address1,
    branch_address2,
    merchantDetails,
    cancelAll
}) => {

    const activeLoyalty = localStorage.getItem("active_loyalty");
    const loyaltyActive = activeLoyalty ? JSON.parse(activeLoyalty) : null;

  return (
    <Modal
        open={open}
        // open={true}
        onCancel={()=>{
            cancelAll();
            onCancel();
        }}
        className="checkout_modal" 
        // onOk={()=>{
        //     dispatch(clearCart());
        //     setShowInStoreOrders(false);
        // }}
        footer={null}
    >
        <div style={{textAlign: "center", marginTop: "5px", lineHeight: "24.2px"}}>
            <p
                style={{fontSize: "20px",
                fontWeight: "600"}}
            >THANKS {user?.first_name ? user?.first_name.toUpperCase() : "New User"}, {word1}</p>
        </div>
        <div 
        style={{
                textAlign: "center", 
                marginTop: "5px", 
                display: "flex", 
                flexDirection: "column"
            }}>
            {/* <span>
                    ORDER ID
            </span>
            <span
                style={{fontSize: "32px",
                fontWeight: "600"}}
            >
                {/* {service.service_id} *
            </span> */}
        </div>
        <div style={{textAlign: "center", marginTop: "5px"}}>
            <img src={Checked} alt="checked"/>
        </div>
        <div style={{textAlign: "center", margin: "10px 0"}}>
            <span
                style={{fontSize: "13px",
                fontWeight: "500",
                lineHeight: "150%"
                }}
            >
                Order Completed. Please visit the address for your service:  {merchant?.role?.id === 1 ? " " : " "} {`${isbranchSet ? branch_address1 : merchant?.address_line1}, ${isbranchSet ? branch_address2 : merchant?.address_line2}`}
            </span>
        </div>
        <Card 
            className="instore_card"
        >
            <div>
            <h2 style={{textAlign: "center", color: "#fff", fontSize: "14px", marginBottom: "-0.5rem"}}>ORDER SUMMARY</h2>
            </div>
            <Row>
                {bookedService && bookedService.map((cart, index)=>{
                    return <Col
                        key={index}
                        span={24}
                        lg={24}
                        md={24}
                        sm={24}
                        style={{
                            margin: "0",
                            padding: "15px 5px 0 0",
                            borderBottom: "1px solid #fff",
                        }}
                        >
                        <Row
                        
                        >
                            <Col span={8}>
                                <img style={{ height: "64px", width: "64px", borderRadius: "50%"}} src={cart.image_url ? cart.image_url : CartItem1} alt="cart Image"/>
                                <p>
                                    
                                </p>
                            </Col>
                            <Col
                                span={8}
                                style={{display: "flex", flexDirection: "column"}}
                            >
                                
                                    <span>{cart.service_name}</span>
                                    <span
                                    style={{fontSize: "11px", opacity: "0.3"}}>
                                        {Number(cart.amount_charged).toFixed(2)}
                                        </span>
                            </Col>
                            <Col 
                                span={8}
                                style={{textAlign: "right", fontSize: "13px", fontWeight: "light"}}
                            >
                                <span>
                                {Number(cart.amount_charged)}
                                    </span>
                            </Col>
                        </Row>
                        
                    </Col>
                    
                    
                })}
                
            </Row>
            <div 
                style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "1rem"
                }}
            >
                <span 
                style={{ 
                    color: "#fff", 
                    fontSize: "14px"
                }}
                >TOTAL: {merchant?.currency}{" "} {total && total} </span>

                {/* {activeLoyalty && <span style={{fontSize: "11px", opacity: "0.3"}}> */}
                    {/* You will earn {" "} */}
                    {/* {(total / (Number(loyaltyActive?.currency_value) / Number(loyaltyActive?.points))).toFixed(2) + "pts"} */}
                    {/* {totalCustomerPoints + "pts"|| 0 + "pts"} */}
                {/* </span>} */}
            </div>
        </Card>
        <div style={{textAlign: "center", paddingTop: "1rem"}}>
        {/* <Link to={merchantUrl}> */}
        {/* <p
            style={{fontSize: "12px", fontWeight: "600", margin: "0"}}
            onClick={onClick}
            className="cart_action"
            >See Your Loyalty Rewards Balance</p> */}
        {/* </Link> */}
        </div>
    </Modal>
  )
}

export default BookingSuccess