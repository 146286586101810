import React, { useState } from "react";
import { DatePicker, TimePicker, Button } from "antd";

function Step2({ selectedDate, setSelectedDate, setStep }) {
  const [selectedTime, setSelectedTime] = useState(null);

  const handleNext = () => {
    if (selectedTime) {
      setStep(2);
    } else {
      console.log("Time must be selected");
    }
  };

  // Format selectedDate as a date range
  const formattedDateRange = selectedDate
    ? `${selectedDate[0]?.format("DD-MM-YYYY")} - ${selectedDate[1]?.format(
        "DD-MM-YYYY"
      )}`
    : "Not selected";

  // Format selectedTime as a time string
  const formattedTime = selectedTime
    ? selectedTime.format("HH:mm A")
    : "Not selected";

  const disabledTime = (current) => {
    if (!current || !selectedDate || !selectedDate[0] || !selectedDate[1]) {
      return false; // If current or selectedDate is not set, don't disable any time
    }

    const startOfDay = selectedDate[0].clone().startOf("day").hour(9); // Set the start time to 9 AM
    const endOfDay = selectedDate[1].clone().endOf("day").hour(16); // Set the end time to 4 PM

    // Disable times that are not within the selected time frame or not in 30-minute intervals
    return (
      current.isBefore(startOfDay) ||
      current.isAfter(endOfDay) ||
      current.minutes() % 30 !== 0
    );
  };

  return (
    <div>
      <h2>Step 2: Booking Details</h2>

      <DatePicker
        size="large"
        onChange={(date) => setSelectedDate(date)}
        style={{ marginBottom: 16 }}
      />
      <TimePicker
        size="large"
        defaultValue={selectedTime}
        onChange={(time) => setSelectedTime(time)}
        format="HH:mm A"
        disabledTime={disabledTime} // Use disabledTime to handle time restrictions
        style={{ marginBottom: 16 }}
      />

      <h4>Selected Date Range</h4>
      <p>{formattedDateRange}</p>

      <h4>Selected Time</h4>
      <p>{formattedTime}</p>

      <Button
        type="primary"
        onClick={() => setStep(0)}
        style={{
          marginRight: 10,
        }}
      >
        Back
      </Button>
      <Button type="primary" onClick={handleNext}>
        Next
      </Button>
    </div>
  );
}

export default Step2;
