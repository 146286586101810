import * as Types from "../actions/types";


const initialState = {
    status: "",
    uRewardsAuth: {},
    invoice: {},
    invoiceFail: null,
    merchantloyaltySuccess: false,
    invoiceNoficationMessage: "",
    invoiceSuccess: false,
    merchantLoyalty: [],
    ussdOrderSuccess: false,
    isFromCart: false,
    giftcard: null,
    isOTPverified: false,
}
export const uRewardsAuthReducer = ( state=initialState, {type, payload} ) =>{
    switch (type){
        case Types.UREWARDS_AUTH: {
            state.status = payload.status;
            state.uRewardsAuth = payload.data;
            return state;
        }
        case Types.INVOICE: {
            state.invoice = payload;
            state.invoiceFail = null;
            state.invoiceSuccess = true;
            return state;
        }
        case Types.INVOICEFAIL: {
            state.invoiceFail = payload;
            state.invoiceSuccess = false;
            state.invoice = {};
            return state;
        }
        case Types.INVOICENOTIFICATION: {
            state.invoiceNoficationMessage = payload;
            state.invoiceSuccess = true;
            return state;
        }
        case Types.MERCHANTLOYALTY: {
            state.merchantloyaltySuccess = true;
            state.merchantLoyalty = payload;
            return state;
        }
        case Types.USSDORDERSUCCESS: {
            state.ussdOrderSuccess = true;
            return state;
        }
        case Types.CLEAR_AUTH: {
            state.status = "";
            state.uRewardsAuth = {};
            state.ussdOrderSuccess = false;
            state.merchantloyaltySuccess = false;
            return state;
        }
        case Types.CART_AUTH: {
            state.isFromCart = true;
            return state;
        }
        case Types.NOT_CART_AUTH: {
            state.isFromCart = false;
            return state;
        }
        case Types.SAVEGIFTCARDTOSTATE: {
            state.giftcard = payload;
            return state
        }
        case Types.ISOTPVERIFIED : {
            state.isOTPverified = true;
            return state;
        }
        case Types.CLEARCARD : {
            state.giftcard = null;
            return state;
        }
        case Types.EXPIREOTP: {
            state.isOTPverified = false;
            return state;
        }
        default: {
            return state;
        }
    }
}