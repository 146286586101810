import * as Types from "./types";
import Axios from "axios";
import { message } from "antd";

// ${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}

export const USSDOrderSuccess = ( ) => ( dispatch ) =>{

    dispatch( { type: Types.USSDORDERSUCCESS } );

}

export const clearUSSDState = ( ) => ( dispatch ) =>{

    dispatch({type: Types.CLEAR_AUTH});

}

export const getUrewardsAuth = ( {email, location} ) => ( dispatch ) => {

    const merchantDetails = JSON.parse(localStorage.getItem("merchant_details"));

    Axios.post(
        `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/auth/get_uRewards_auth`, {
            email,
        })
    .then((res) => {
        if(res.status === 200){
            dispatch({ type: Types.UREWARDS_AUTH, payload: res.data});
            // dispatch(getMerchatLoyalty(res.data.data));
            if(location === "/cart"){
                dispatch(isFromCart())
            }else{
                dispatch(isNotFromcart())
            }
        }
    })
    .catch((err) => {
        message.error(err.response?.data.message || err.message);
    });
    
    dispatch({ type: Types.MERCHANT_ORDER_DETAILS, payload: merchantDetails});
    // baseURL: process.env.REACT_APP_API_BASE_URL
    // REACT_APP_API_BASE_URL = https://api-stage.loystar.co/api/v2
}
export const getMerchatLoyalty = ( header ) => ( dispatch) => {

    const time_stamp = 0;
    // console.log(header);
    
    // const headers = {
    //     client,
    //     "access-token": access_token,
    //     uid
    // }
    Axios.post(`${process.env.REACT_APP_BASE_URL}/api/v2/get_merchant_loyalty_programs`, {data: {time_stamp}}, {
        headers: header
    })
    .then((res) => {
        if(res.status === 200){
            dispatch({type: Types.MERCHANTLOYALTY, payload: res.data});
            // console.log(res)
        }
    })
    .catch((err) => {
        message.error(err.response?.data.message || err.message);
    });
}

export const saveInvoice = ( datae ) => ( dispatch, getState ) =>{

    const header = getState().urewardsAuth.uRewardsAuth;
    const loyaltyArr = getState().urewardsAuth.merchantLoyalty;

    const { 
        user_id,
        customer_id,
        due_amount,
        cartList,
        payment_method,
        status,
        payment_message,
        due_date,
        send_notification,
    } = datae

    
    const transactions = cartList.map((cart)=>{
        const product_id = cart.id
        let loyaltyInfo = loyaltyArr.filter(
            (loyaltyProgram) =>
              cart.merchant_loyalty_program_id === loyaltyProgram.id
          );
        const program_type = loyaltyInfo.length
        ? loyaltyInfo[0].program_type
        : null;
        const points = computeLoyaltyPoints(cart, loyaltyArr);
        const stamps = computeLoyaltyStamps(cart, loyaltyArr);
        return {...cart, product_id, program_type, points, stamps}
    });

    const data = {
        user_id,
        customer_id,
        due_amount,
        transactions,
        payment_method,
        status,
        payment_message,
        due_date,
        send_notification,
    }
    const { client, access_token, uid } = header;
    const headers = {
        client,
        "access-token": access_token,
        uid
    }
    Axios.post(`${process.env.REACT_APP_BASE_URL}/api/v2/invoices`, {data}, {
        headers: headers
    })
    .then(async (res) => {
        if(res.status === 200){
            const { data } = res;

            // const notificationData = {
            //     customer_id: data.customer.id,
            //     invoice_id: data.id,
            // };

            dispatch({ type: Types.INVOICE, payload: data});
    }
    })
    .catch((err) => {
        message.error(err.response?.data.message || err.message);
        dispatch({ type: Types.INVOICEFAIL, payload: "error"});
    });
    
}

export const sendNotification = ( data, header )=> ( dispatch ) => {
    
    Axios.post(`${process.env.REACT_APP_BASE_URL}/api/v2/ussd/send_due_payment_notification`, {data}, {
        headers: header
    })
    .then((res) => {
        if(res.status === 200){
            dispatch({type: Types.INVOICENOTIFICATION, payload: res.data.message});

        }
    })
    .catch((err) => {
        message.error(err.response?.data.message || err.message);
    });
}
//get_merchant_loyalty_programs simplePoint, stampsProgram, instantRewards
//#10 - 10pooints, stamp quantity, rewardsvalue, depends on rewards types


export const computeLoyaltyPoints = function (product, loyaltyPrograms) {
    const loyaltyArr = loyaltyPrograms.filter(
      (loyalty) => loyalty.id === product.merchant_loyalty_program_id
    );
  
    if (loyaltyArr.length > 0) {
      const points =
        loyaltyArr[0].program_type.toLowerCase() === "simplepoints"
          ? Number(product.quantity) * Number(product.price)
          : 0;
      return points;
    }
  
    return 0;
};
  
export const computeLoyaltyStamps = function (product, loyaltyPrograms) {
const loyaltyArr = loyaltyPrograms.filter(
    (loyalty) => loyalty.id === product.merchant_loyalty_program_id
);

if (loyaltyArr.length > 0) {
    const stamps =
    loyaltyArr[0].program_type.toLowerCase() === "stampsprogram"
        ? product.quantity
        : 0;
    return stamps;
}

return 0;
};
export const sendReceiptToEmail = ( data, header ) => ( ) =>{
    
    Axios.post(`${process.env.REACT_APP_BASE_URL}/api/v2/transaction/send_receipt`, {data}, {
        headers: header
    })
    .then((res) => {
        if(res.status === 200){
           console.log(res);
        }
    })
    .catch((err) => {
        // message.error(err.response?.data.message || err.message);
    });
}

export const isFromCart = ( ) => ( dispatch, getState ) =>{
    const isSuccess = getState().urewardsAuth.status;
    const header = getState().urewardsAuth.uRewardsAuth;
    if(header === undefined) return;
    const { access_token, client, uid } = header;
    const headers = {
        "access-token": access_token,
        client, uid
    }
    if(isSuccess === "success"){
        dispatch({type: Types.CART_AUTH})
        dispatch(getMerchatLoyalty(headers))
    }   
}

export const isNotFromcart = ( ) => ( dispatch ) =>{
    // const isSuccess = getState().urewardsAuth.status;

    dispatch({type: Types.NOT_CART_AUTH})
}

export const giftCardAction = ( giftCard ) => ( dispatch ) => {
    dispatch({ type: Types.SAVEGIFTCARDTOSTATE, payload: giftCard});

    localStorage.setItem("gift_card", JSON.stringify(giftCard));
}

export const clearGiftCard = ( ) => ( dispatch ) => {
    dispatch({ type: Types.CLEARCARD});

    localStorage.removeItem("gift_card");
}

export const isOTPVerified = ( ) => ( dispatch ) => {
    dispatch({ type: Types.ISOTPVERIFIED });
    const very = localStorage.getItem("otp_verify");
    if(very){
        const verify = JSON.parse(very);
        const item = {
            value: true,
            expiry: verify.expiry,
        }
        localStorage.setItem("otp_verify", JSON.stringify(item));
    }else{
        const item = {
            value: true,
            expiry: new Date().getTime() + 86400000,
        }
        localStorage.setItem("otp_verify", JSON.stringify(item));
    }
}
export const expireOTP = ( ) => ( dispatch ) => {
    dispatch({ type: Types.EXPIREOTP });
    localStorage.removeItem("otp_verify");
}