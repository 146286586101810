import React, { useRef } from 'react';
import { InputOTP } from 'antd-input-otp';
import "./index.scss";
import { Modal, Button } from 'antd';

const OtpInputModal = ({ 
    open, 
    onCancel, 
    phoneNumber, 
    verifyOTP, 
    handleOTPInput, 
    credPalLoading,
    verifyPhoneNumber 
}) => {
    const otpRef = useRef();
  return (
    <Modal
        open={open}
        onCancel={onCancel}
        footer={null}
    >
        {<div className="modal_2">
            <div className="md_1">
                <div>
                    <p>A 4-digit code was sent to your number ending with {phoneNumber.substr(phoneNumber.length - 4, phoneNumber.length)}</p>
                    <InputOTP 
                        inputType='numeric'
                        length={6}
                        autoSubmit={verifyOTP}
                        autoFocus={true}
                        onChange={handleOTPInput}
                        ref={otpRef}
                    />
                </div>

                <div className="btn-div">
                    <Button 
                        loading={credPalLoading}
                        type="primary"
                        onClick={verifyOTP}
                    >
                        Verify
                    </Button>
                    <p>Didn't get the code? <span onClick={verifyPhoneNumber}>Resend code</span></p>
                </div>
            </div>
        </div>}
    </Modal>
  )
}

export default OtpInputModal;