import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import "./index.scss";
import Header from '../../reusables/Header';
import Footer from '../../reusables/Footer';
import { LogoBlack, JayAutos } from '../../assets/icons';
import { getLeaderBoard } from '../../redux/actions/leaderboard';
import { Table, message } from "antd";
import { useLocation } from 'react-router-dom';
import moment from 'moment';

const LeaderBoard = () => {
    const location = useLocation();
    const [merchant, setMerchant ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ customers, setCustomers ] = useState([]);
    const [ email, setEmail ] = useState(null);
    const [ userDetails, setUserDetails ] = useState(null);
    const [merchantUrl, setMerchantUrl] = useState(null);
    const [ leaderBoardDetails, setLeaderBoardDetails ] = useState(null);
    const [ tableData, setTableData ] = useState([
        {
            "S/N": 1,
            name: "user 1",
            point: 300
        },
        {
            "S/N": 2,
            name: "user 2",
            point: 400
        },
        {
            "S/N": 3,
            name: "user 3",
            point: 3000
        }
    ]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user_customer_details"));
        const merchant = JSON.parse(localStorage.getItem("urewards_merchant_details"));
        const isMerchantUrl = localStorage.getItem("urewards_merchant_url");

        user && setUserDetails(user);
        merchant && setEmail(merchant.email)
        merchant && setMerchant(merchant);
        isMerchantUrl && setMerchantUrl(JSON.parse(isMerchantUrl));
    }, []);

    useEffect(() => {
        const payload = location.search.split("=")[1];

        setLoading(true);
        getLeaderBoard(payload)
        .then((res) => {
            setLeaderBoardDetails(res.data);
            setCustomers(res.data.customer)
            setLoading(false);
        })
        .catch((err) => {
            const mess = err.response?.data?.message || "Unable to get referral program";
            message.error(mess);
            setLoading(false);
        })
    }, [location]);

    const tableColumn = [
        {
            title: "Name",
            dataIndex: "name",
            key: 1
        },
        {
            title: "Phone Number",
            dataIndex: "phone_num",
            key: 2
        },
        {
            title: "Number of Referral",
            dataIndex: "no_of_referrals",
            key: 3
        },
        // {
        //     title: "Created Date",
        //     dataIndex: "createdDate",
        //     key: 4
        // },
        // {
        //     title: "Created Time",
        //     dataIndex: "createdTime",
        //     key: 5
        // },
        {
            title: "Created",
            dataIndex: "dayCreated",
            key: 5
        }
    ]

    const handleDateDifference = (creationDate) => {
        const dateOfCreation = new Date(creationDate).getTime();
        // var update = setInterval(function () {

            // get the today's date and time
            var now = new Date().getTime();
        
            //find the difference b/w countDown and now
            var diff = now - dateOfCreation;
        
            //now we are calculating time in days, hrs, minutes, and seconds.
            var days = Math.floor(diff / (1000 * 60 * 60 * 24));
            var hrs = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((diff % (1000 * 60)) / 1000);
        
            const checkSeconds = days === 0 && hrs === 0 && minutes === 0 && seconds < 60;
            const checkMinutes = days === 0 && hrs === 0 && minutes < 60;
            const checkHours = days === 0 && hrs < 24;
            const checkDays = days >= 1;
            if(checkSeconds){
                if(seconds === 1){
                    return `${seconds} sec ago`
                }else{
                    return `${seconds} secs ago`
                }
            }else if(checkMinutes){
                if(minutes === 1){
                    return `${minutes} min ago`
                }else{
                    return `${minutes} mins ago`
                }
            }else if(checkHours){
                if(hrs === 1){
                    return `${hrs} hour ago`
                }else{
                    return `${hrs} hours ago`
                }
            }else if(checkDays){
                if(days === 1){
                    return `${days} day ago`
                }else{
                    return `${days} days ago`
                }
            }
        // }, 300000);

        // return update;
    }

    const formatData = (data) => {
        return data.length > 0 && data.map((item) => {
            item.createdDate = item.createdAt.split("T")[0];
            item.createdTime = moment(item.createdAt).format("h:mm:ss A");
            item.phone_num  = "xxxxxxxx"+item.phone_no.slice(item.phone_no.length - 4, item.phone_no.length);
            item.dayCreated = handleDateDifference(item.createdAt)
            return item;
        })
    }

  return (
    <>
        <Helmet>
            <meta
                name="description"
                content={
                    merchant?.description ||
                    `Browse products and Services from ${merchant?.business_name || `loystar shop`}, make a purchase and earn rewards 🎁`
                }
                />
                <link rel="shortcut icon" id="favicon" href={merchant?.image || LogoBlack} />
                <title>{merchant?.name || "LeaderBoard"}</title>
                <link rel="icon" id="icon-fav" href={merchant?.image || LogoBlack} />
        </Helmet>
        {/* <Header
            merchantee
            // merchantLink={merchantUrl}
        /> */}
        
        <div className="container_main leaderboard"
            style={{
                background: `url(${leaderBoardDetails && leaderBoardDetails?.image || "#fff"}) center center/cover no-repeat`,
                width: "100%",
                height: "100vh",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }}
        >
            <div 
                // style={{height: 100, backgroundColor: "#131313"}}
                className="flex_center_column"
                >
            </div>
            {/* <div className="flex_center_column"> */}
                <div className='top'>
                    <h2>LeaderBoard - {leaderBoardDetails && leaderBoardDetails?.name || "Loading..."}</h2>   
                    <p>Total: {customers.length}</p> 
                </div>
                
                <Table 
                    loading={loading}
                    columns={tableColumn}
                    // data={data}
                    dataSource={formatData(customers)}
                />
            {/* </div> */}
        </div>
        {/* <Footer active="home" disable merchanturl={ merchantUrl && merchantUrl} /> */}
    </>
  )
}

export default LeaderBoard;